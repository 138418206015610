import { db } from './firebase'

//
// TODO: We can make calls for both 'games' and 'game' (and 'rosters' and 'roster'),
// and wind up storing both in cache separately, even though the single item is
// part of the larger collection. When we get a call for a single item that's already
// in the collection, we should probably just hook into that instead of making a
// new DB fetch.
//
const cache = { }

//
// Collects multiple things
//
export const fetchDataSet = (props, callback) => {

	// console.log('fetchDataSet', props)

	const arrayProps = Array.isArray(props) ? props : [ props ]

	const unsubscribeFunctions = [ ]
	const fullDataSet = { }

	arrayProps.forEach(propSet => {
		const unsubscribe = fetchData(propSet, (data, callbackType) => {
			fullDataSet[propSet.name] = data
			if (Object.keys(fullDataSet).length === arrayProps.length) {
				// console.log('callback', callback)
				callback(fullDataSet, callbackType || 'fetch')
			}
		})
		unsubscribeFunctions.push(unsubscribe)
	})

	return () => {
		// console.log('unsubscribe from fetchDataSet')
		unsubscribeFunctions.forEach(unsubscribeFunction => unsubscribeFunction())
	}
}

//
// E.g. const unsubscribe = fetchItems({
//			name: 'teams',
//			section,
//		}, data => console.log('yay', data))
//
export const fetchData = (props, callback) => {

	const { id, name, section, where } = props

	const { isCollection, cid } = metadata({
		name,
		section,
	})

	if (!isCollection && !id) {
		console.error("Request for document with no ID")
		return null
	}

	const cacheId = makeCacheId({
		isCollection,
		cid,
		id,
		where,
	})

	if (cache[name] && cache[name].id === cacheId) {
		//
		// Cache hit!
		//

		// console.log('item exists in cache; adding listener & invoking callback with data immediately', name, cache[name].data)

		const unsubscribeFunction = addCacheListener({
			id: cacheId,
			name,
			callback,
		})

		//
		// Maybe only call this if there's something there, since we seem to
		// often get a couple of near-simultaneous calls for the same data,
		// and otherwise the second one will get its callback() called with
		// undef data.
		//
		// *** REMOVED by Max 2020-02-17 because it fails to return data
		// when you click to return to a game with no roster - the roster is
		// undef so this never returns anything and the page stays permanently
		// in loading state
		//
		// if (cache[name].data !== undefined) {
		//		callback(cache[name].data)
		// }
		//
		// *** Instead we will ALWAYS invoke the callback:

		callback(cache[name].data)

		return unsubscribeFunction
	}

	//
	// We don't have what we need in the cache; let's look it up.
	//

	function errorFunction(error) {
		console.error('Database error!', error)
		window.alert("Error communicating with database: " + error)
	}

	let unsubscribe

	if (isCollection) {
		const collectionRef = db.collection(cid)
		const filtereredCollectionRef = where && collectionRef.where(...where)

		const cRef = filtereredCollectionRef || collectionRef

		unsubscribe = cRef.onSnapshot(snapshot => {
			const data = { }
			snapshot.docs.forEach(doc =>
				data[doc.id] = doc.data()
			)

			// console.log('Collection data update', cid)

			setCacheItemContent({
				name,
				id: cacheId,
				data,
			})

		}, errorFunction)

	} else {
		unsubscribe = db.collection(cid).doc(id).onSnapshot(doc => {
			const data = doc.data()

			// console.log('Document data update', cid, id)

			setCacheItemContent({
				id: cacheId,
				name,
				data,
			})
		}, errorFunction)
	}

	createCacheItem({
		id: cacheId,
		name,
		unsubscribe,
	})

	const unsubscribeFunction = addCacheListener({
		id: cacheId,
		name,
		callback,
	})

	return unsubscribeFunction
}

export const saveData = props => {
	const { name, section, id, data } = props

	console.log("saveData", props)

	if (!name || !section || !id || !data) {
		console.error('Missing parameter(s) in call to saveData.', props)
	}

	const { cid } = metadata({
		name,
		section,
	})

	db.collection(cid).doc(id).set(data).then(() => {
		console.log('Successful write.', cid, id, data)
	}).catch(error => {
		console.error('Write failed.', error)
		window.alert("Saving to database failed: " + error)
	})
}

const makeCacheId = props => {
	const { isCollection, cid, id, where } = props

	let cacheId = (isCollection ? cid : id)

	if (where) {
		cacheId += where.join('-')
	}

	return cacheId
}

const metadata = props => {

	const { section, name } = props

	if (!section || !name) {
		console.error("Required parameter missing", section, name)
	}

	//
	// Collections: 'teams', 'seasons', 'games', 'players', 'rosters'
	//
	const types = {
		'teams': {
			isCollection: true,
			cid: `/teams`,
		},
		'seasons': {
			isCollection: true,
			cid: `/teams/${section.team}/seasons`,
		},
		'games': {
			isCollection: true,
			cid: `/teams/${section.team}/seasons/${section.season}/games`,
		},
		'players': {
			isCollection: true,
			cid: `/teams/${section.team}/seasons/${section.season}/players`,
		},
		'rosters': {
			isCollection: true,
			cid: `/teams/${section.team}/seasons/${section.season}/rosters`,
		},

		'team': {
			cid: `/teams`,
		},
		'season': {
			cid: `/teams/${section.team}/seasons`,
		},
		'game': {
			cid: `/teams/${section.team}/seasons/${section.season}/games`,
		},
		'player': {
			cid: `/teams/${section.team}/seasons/${section.season}/players`,
		},
		'roster': {
			cid: `/teams/${section.team}/seasons/${section.season}/rosters`,
		},

	}

	if (!types[name]) {
		console.error('what you want?')
	}

	return types[name]
}

//
// The cache contains a hash of pages. It can only store one of each type, e.g. only one 'team',
// one 'season', etc.
//
// Each page has an id (e.g. 'teams/1241241241'), associated data (e.g. 'name: Cassowaries'),
// an unsubscribe function, and an array of listeners.
//
// 1. When we get a request for data, we check the cache. If this type & ID already exist, we
// add the new request's listener function to it, then return the cached data.
//
// 2. If the type exists but the ID doesn't, we call 'unsubscribe' on the existing item, then
// delete it from the cache, then proceed as per #3.
//
// 3. If the type doesn't exist, we create a new empty cache item this ID with a new unsubscribe function,
// then proceed as per #1.
//

const createCacheItem = ({ name, id, unsubscribe }) => {

	if (cache[name]) {
		//
		// Cache item of this type already exists, but we want to create a new one, so first
		// unsubscribe the old one.
		//
		// console.log("item already exists; unsubscribing from this:", cache[name])
		cache[name].unsubscribe()
	}

	cache[name] = {
		callbacks: { },
		name,
		id,
		unsubscribe,
	}

}

const setCacheItemContent = ({ name, id, data }) => {

	const cacheItem = cache[name]

	if (cacheItem && cacheItem.id === id) {
		cache[name].data = data

		// console.log('set cache item', cache[name], data)

		Object.keys(cache[name].callbacks).forEach(key => {
			const callback = cache[name].callbacks[key]
			// console.log('Invoking cache callback', key, name, id, data)
			callback(data, 'cache')
		})
	} else {
		console.error('Got stale info? Wanted to set content for cache item', name, id, 'but that cache slot is now:', cacheItem)
	}

}

const addCacheListener = ({ name, id, callback }) => {
	const listenerId = Math.floor(Math.random() * 10000000)

	const cacheItem = cache[name]

	cacheItem.callbacks[listenerId] = callback

	// console.log("Added cache callback listener with id", listenerId, 'to cache ', name)

	return () => {
		// console.log('Request to unsubscribe component from cache item', name, id, listenerId)

		const cacheItem = cache[name]
		if (cacheItem && cacheItem.id === id) {
			delete cacheItem.callbacks[listenerId]
			// console.log('Deleted callback for cacheItem. Remaining listeners:', Object.keys(cacheItem.callbacks).length)
			//
			// Leave the cache object intact even if there are no more listeners, because
			// we are likely to navigate back to a component that does want this data.
			// We will unsubscribe() from the remote DB only if we're overwriting that
			// cache type with a different ID.
			//
		} else {
			console.log("Cache item seems to have disappeared in the meantime")
		}
	}
}
