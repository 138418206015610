import React from 'react'
import DeleteButton from './DeleteButton'
import { MdPersonAdd, MdPersonOutline } from 'react-icons/md'
import { parseRoster } from './util'
import { BENCHED, numPeriods } from './definitions'

const AddPlayerButton = props => {

	const { show, ready, onAddPlayer, onRemoveUnusedPlayers, onEraseRoster, players, roster, positions } = props

	if (!show)
		return null

	//
	// We'll show the button if we have an excess of players. The button will be disabled
	// until at least one of those players is benched for the whole game.
	//
	let removeUnusedPlayersButton
	if (positions.length && positions[positions.length - 1] === BENCHED) {

		const data = parseRoster({
			roster,
			positions,
			players,
		})

		const unusedPlayers = Object.keys(data).filter(playerName => {
			const benches = data[playerName].filter(pos => pos.position === BENCHED).length
			return benches === numPeriods
		})

		removeUnusedPlayersButton = (
			<button
				className="button-icon button-remove-players"
				onClick={() => onRemoveUnusedPlayers(unusedPlayers)}
				title="Remove Unused Players"
				disabled={!ready || (unusedPlayers.length ? false : true)}
			>
				<MdPersonOutline />
				<span className="roster-button-text">
					Remove Unused
				</span>
			</button>
		)
	}

	return (
		<div className="add-player-buttons">
			<button
				className="button-icon add-player-button"
				onClick={onAddPlayer}
				title="Add Player"
				disabled={!ready}
			>
				<MdPersonAdd />
				<span className="roster-button-text">
					Add Player
				</span>
			</button>
			{removeUnusedPlayersButton}
			<DeleteButton
				show={true}
				onClick={onEraseRoster}
			/>
		</div>
	)
}

export default AddPlayerButton
