import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Header from './Header'
import { fetchDataSet } from './Data'
import LoadingBar from './LoadingBar'
import MultiPageTitle from './MultiPageTitle'
import FadeText from './FadeText'
import { Link } from 'react-router-dom'
import { fieldPositions, fieldPositionColors, BENCHED, NORMALIZE_MIN_QTRS, NULL_NETSTAT_VALUE } from './definitions'
import { userCanViewNetStats, parseData, generateSearchString, labelRound, deepCopy } from './util'
import { FaCalendarTimes } from 'react-icons/fa'
import { BiStats } from 'react-icons/bi'
import orange from './orange.svg'
import "./stats.css"

const Stats = ({ section, season, team, user }) => {

	const [ loading, setLoading ] = useState(true)
	const [ data, setData ] = useState({ })
	const [ stats, setStats ] = useState({ })
	const [ netStats, setNetStats ] = useState({ })

	const showNetStats = userCanViewNetStats({ user, team })

	const { showSeasonOutput, includeFuture } = section || { }

	useEffect(() => {

		const requests = [ 'games', 'players', 'rosters' ].map(key => (
			{
				name: key,
				section,
			})
		)

		const unsubscribe = fetchDataSet(requests, items => {
			setData(items)
			setLoading(false)
			const newStats = parseData(items, includeFuture)
			setStats(newStats)
			setNetStats(generateAllNetStats(items, newStats))
		})

		return unsubscribe
	}, [ section, includeFuture ])

	let options = null
	let headerText = null
	let header = null
	let content = null
	let footerText = null

	const mode = section?.mode || 'default'
	const tab = section?.tab || ''

	const history = useHistory()

	if (Object.keys(stats).length) {
		const sortedPlayers = Object.keys(data.players).sort((a, b) => data.players[a].name && data.players[a].name.localeCompare(data.players[b].name))

		let flatArray
		if (mode === 'netStats') {
			flatArray = Object.values(netStats).map(obj => {
				if (showSeasonOutput) {
					return obj.normalized.map((n, index) => {
						if (obj.posCount[index]) {
							return n * obj.posCount[index]
						}
						return n
					})
				}
				return obj.normalized.slice(0, 6)
			}).flat()
		} else {
			flatArray = Object.values(stats).map(row => row.positions).flat()
		}
		const max = Math.max(...flatArray)

		options = (
			<div className="stats-options">
				<StatsButton
					type="default"
					section={section}
				>
					Positions
				</StatsButton>
				<StatsButton
					type="netStats"
					section={section}
				>
					<BiStats />
				</StatsButton>
				<StatsButton
					type="orangeman"
					section={section}
				>
					<img
						src={orange}
						alt="oranges"
						title="Oranges"
					/>
				</StatsButton>
				<StatsButton
					type="absent"
					section={section}
				>
					<FaCalendarTimes
						className="roster-no"
						title="absent"
					/>
				</StatsButton>
				{
					mode === 'netStats' ? (
						<>
							<label
								className={tab ? 'disabled' : ''}
							>
								<input
									type="checkbox"
									name="normalizeNetStats"
									checked={!!showSeasonOutput}
									onChange={() => {
										const extra = { mode: section.mode }
										if (!showSeasonOutput) {
											extra.showSeasonOutput = 'true'
										}
										const search = generateSearchString({
											section,
											extra,
										})
										history.replace({
											pathname: '/stats',
											search,
										})
									}}
									disabled={!!tab}
								/>
								<BiStats /> Total season output
							</label>
							<span className="bestqtr-buttons">
								<NetStatsBestButton
									type="qtrs"
									label="Best Quarters"
									section={section}
								/>
								<NetStatsBestButton
									type="attack"
									label="Best Attack"
									section={section}
								/>
								<NetStatsBestButton
									type="defence"
									label="Best Defence"
									section={section}
								/>
							</span>
						</>
					) : (
						<label>
							<input
								type="checkbox"
								name="includeFutureRosters"
								checked={!!includeFuture}
								onChange={() => {
									const extra = { }
									extra.mode = section.mode || 'default'
									if (!includeFuture) {
										extra.includeFuture = 'true'
									}
									const search = generateSearchString({
										section,
										extra,
									})
									history.replace({
										pathname: '/stats',
										search,
									})
								}}
							/>
							Include future rosters
						</label>
					)
				}
			</div>
		)

		const headerDivs = [ ]

		if (mode === 'orangeman') {
			headerDivs.push(
				<div
					key="orangeman"
					className="cell cell-position summaryline-header-text"
				>
					<img
						src={orange}
						alt="Oranges"
						title="Oranges"
					/>
				</div>
			)
			headerDivs.push(
				<div
					key="orangemanGames"
					className="cell cell-position summaryline-header-text"
				/>
			)
		} else if (mode === 'absent') {
			headerDivs.push(
				<div
					key="absent"
					className="cell cell-position summaryline-header-text"
				>
					<FaCalendarTimes
						className="roster-no"
						title="Absent"
					/>
				</div>
			)
			headerDivs.push(
				<div
					key="absentGames"
					className="cell cell-position summaryline-header-text"
				/>
			)
		} else {
			fieldPositions.forEach((positionName, index) =>
				{
					headerDivs.push(
						<div
							key={index}
							className={`cell cell-position cell-position-${positionName}`}
						>
							{positionName}
						</div>
					)
				})
			if (mode === 'netStats') {
				if (tab) {
					headerDivs.push(
						<div
							key="total"
							className="cell cell-position summaryline-header-text"
						/>
					)
				} else {
					headerDivs.push(
						<div
							key="bench"
							className="cell cell-position cell-position--"
						>
							-
						</div>
					)
				}
				if (!netStats || !Object.keys(netStats).length) {
					headerText = (
						<>
							<p className="stats-header-text">
								<b>Note:</b> <BiStats /> NetStats are available once you've entered some quarter-by-quarter scores, which you can do via <b>Games</b> → <b>Edit</b>.
							</p>
							<p>
								NetStats provide feedback and analysis on the effectiveness of players in different positions, and
								allow you to optimize rosters for maximum team performance. For more, see
								{' '}
								<Link
									to={{
										pathname: "/help",
										hash: 'managers',
									}}
								>
									Help for Team Managers
								</Link>
								{' '}
								→ <b>Use <BiStats />NetStats</b>.
							</p>
						</>
					)
				} else if (!showNetStats) {
					headerText = (
						<p className="stats-header-text">
							<b>Note:</b> <BiStats /> NetStats can only be viewed by logged-in team managers and coaches.
						</p>
					)
				}
			} else {
				headerDivs.push(
					<div
						key="total"
						className="cell cell-position summaryline-header-text"
					>
						Total
					</div>
				)
			}
		}

		if (mode === 'netStats' && !showNetStats) {
			// no content
		} else {
			header = (
				<div className="summaryline summaryline-header">
					<div />
					{
						headerDivs.map(div => div)
					}
				</div>
			)

			if (mode === 'netStats' && tab) {
				content =
					<BestQtrs
						type={tab}
						section={section}
						games={data.games}
						rosters={data.rosters}
						players={data.players}
					/>
				footerText = (
					<p className="info">
						Quarters are scored on relative goal differential, which measures how much better (or worse) this
						quarter was compared to other quarters in the same game. Higher numbers are better.
					</p>
				)
			} else {
				content = (
					<div>
						{
							sortedPlayers.map((playerId, index) =>
								<PlayerSummary
									key={playerId}
									playerId={playerId}
									name={data.players[playerId].name}
									stats={stats[playerId]}
									netStats={netStats[playerId]}
									games={data.games}
									mode={mode}
									max={max}
									season={season}
									section={section}
									showSeasonOutput={showSeasonOutput}
								/>
							)
						}
					</div>
				)
			}
		}
	}

	const altTitles = [
		{
			title: 'Players',
			pathname: '/players',
		},
		{
			title: 'Games',
			pathname: '/games',
		},
	]

	return (
		<section className="stats">
			<Header
				team={team}
				title="Stats"
			/>
			<MultiPageTitle
				title="Stats"
				altTitles={altTitles}
				section={section}
			/>
			<LoadingBar
				loading={loading}
			/>
			{options}
			{headerText}
			{header}
			{content}
			{footerText}
		</section>
	)
}

const StatsButton = props => {
	const { type, section } = props

	const search = generateSearchString({
		section,
		extra: {
			mode: type,
		},
	})

	return (
		<Link
			to={{
				pathname: '/stats',
				search,
			}}
			>
			<button
				className={(section?.mode === type || (!section?.mode && type === 'default')) ? 'enabled' : ''}
			>
				{props.children}
			</button>
		</Link>
	)
}

const NetStatsBestButton = props => {

	const { type, label, section } = props

	const extra = {
		mode: 'netStats'
	}

	if (section?.tab !== type) {
		extra.tab = type
	}

	const search = generateSearchString({
		section,
		extra,
	})

	return (
		<Link
			to={{
				pathname: '/stats',
				search,
			}}
		>
			<button
				className={`netstats-best-button ${section?.tab === type ? 'enabled' : ''}`}
			>
				{label}
			</button>
		</Link>
	)
}

const PlayerSummary = ({ playerId, name, stats, games, netStats, mode, max, section, season, showSeasonOutput }) => {

	let total = 0
	for (let i = 0; i < fieldPositions.length; i++) {
		total += stats.positions[i]
	}

	const divs = [ ]

	if (mode === 'orangeman') {
		divs.push(
			<div key="orangeman">
				<FadeText>
					{stats.orangemanGames.length || '-'}
				</FadeText>
			</div>,
			<div key="orangeman-games" className="orangeman-games">
				<FadeText>
					{
						stats.orangemanGames.map(gameId => labelRound(games[gameId].round)).join(', ')
					}
				</FadeText>
			</div>
		)
	} else if (mode === 'absent') {
		divs.push(
			<div key="absent">
				<FadeText>
					{stats.absentGames.length || '-'}
				</FadeText>
			</div>,
			<div key="absent-games" className="absent-games">
				<FadeText>
					{
						stats.absentGames.map(gameId => labelRound(games[gameId].round)).join(', ')
					}
				</FadeText>
			</div>
		)
	} else if (mode === 'netStats') {
		// console.log('netStats', netStats)
		if (netStats) {
			fieldPositions.concat(BENCHED).forEach((position, index) =>
				{
					const n = showSeasonOutput ? netStats.raw[index] : netStats.normalized[index]
					const opacity = Math.max(0, Math.min(1, n / max))
					const hexOpacity = Math.floor(255 * opacity).toString(16).padStart(2, '0')

					// Display '-' if the player has never played this position, otherwise
					// "x.xx", even if it's 0.00.
					const str = n === null ? '-' : n.toFixed(2)

					divs.push(
						<div
							key={index}
							className="netStat-cell"
							style={{
								backgroundColor: `${fieldPositionColors[index]}${hexOpacity}`,
							}}
						>
							<FadeText>
								{str}
							</FadeText>
						</div>
					)
				}
			)
		}
	} else {
		fieldPositions.forEach((position, index) =>
			{
				const n = stats.positions[index]
				const opacity = n / max
				const hexOpacity = Math.floor(255 * opacity).toString(16).padStart(2, '0')

				const str = n || '-'

				divs.push(
					<div
						key={index}
						style={{
							backgroundColor: `${fieldPositionColors[index]}${hexOpacity}`,
						}}
					>
						<FadeText>
							{str}
						</FadeText>
					</div>
				)
			}
		)
		divs.push(
			<div key="total">
				<FadeText>
					{total}
				</FadeText>
			</div>
		)
	}

	return (
		<div className="summaryline">
			<Link
				to={{
					pathname: "/player",
					search: generateSearchString({
						section,
						type: {
							unit: 'player',
						},
						item: {
							id: playerId,
						},
					})
				}}
			>
				{name}
			</Link>
			{
				divs.map(div => div)
			}
		</div>
	)
}

//
// Given 'stats' and 'games', generate NetStats for each player in each position.
//
// 'stats': {
//
export function generateAllNetStats(items, data) {

	// console.log('generateAllNetStats()', items)

	const { games, players } = items

	const qtrGoalDiffs = calculateQtrGoalDiffs({ games })

	const allNetStats = { }

	if (players && games && Object.keys(qtrGoalDiffs).length) {

		const stats = data || parseData(items)
		// console.log('My parseData result is', stats)

		Object.keys(players).forEach(playerId => {

			const netStats = new Array(8).fill(null)
			const posCount = new Array(8).fill(0)

			const { games: myGames } = stats[playerId]

			// console.log(players[playerId], 'played', myGames)

			Object.keys(qtrGoalDiffs).forEach(gameId => {
				if (myGames[gameId]) {
					const myPositions = myGames[gameId]
					myPositions.forEach((pos, qtr) => {
						const posIndex = pos ?? 7
						const currentNetStat = netStats[posIndex] || 0
						netStats[posIndex] = currentNetStat + qtrGoalDiffs[gameId][qtr]
						posCount[posIndex] += 1
						// console.log(players[playerId].name, "game", gameId, "qtr", qtr, "posIndex", posIndex, "goaldiff", qtrGoalDiffs[gameId][qtr])
					})
				}
			})

			allNetStats[playerId] = {
				raw: netStats,
				posCount,
			}

			allNetStats[playerId].normalized = netStats.map((v, index) => {
				let newValue = v
				if (v !== null) {
					newValue /= (posCount[index] || 1)
					if (posCount[index] < NORMALIZE_MIN_QTRS) {
						//
						// We scale netStat scores way down when there's a very small sample size.
						//
						newValue *= 0.80 / Math.pow(NORMALIZE_MIN_QTRS - posCount[index], 1.5)
					}
				}
				return newValue
			})

			//
			// 'adjusted' is what generateRoster uses -- players who have never played a position have
			// a value of NULL_NETSTAT_VALUE (e.g. -1.25), and players with very few quarters share
			// some of that penalty.
			//
			// Because the thing is you don't want to get carried away with a player who has decent stats from 2 qtrs.
			// She might be say -0.3 but that's a lot better than NULL_NETSTAT_VALUE.
			//
			const allNetStatAbsValues = [].concat(Object.values(allNetStats).map(obj => obj.normalized.map(n => Math.abs(n)))).flat()
			const max = Math.max(...allNetStatAbsValues)
			const scale = 1.50 / max
			// console.log('allNetStatAbsValues', max, scale, allNetStatAbsValues)

			allNetStats[playerId].adjusted = allNetStats[playerId].normalized.map((v, index) => {
				let newValue = v
				if (v === null) {
					newValue = NULL_NETSTAT_VALUE
				} else if (posCount[index] * 1.5 < NORMALIZE_MIN_QTRS) {
					//
					// We should try to avoid rostering players in roles they've never played before, but we also
					// don't want a player to gain +NULL_NETSTAT_VALUE just from playing a single qtr. So let's
					// remove the NULL_NETSTAT_VALUE at 1.5X, so that you no longer have any penalty after
					// playing 4 qtrs (assuming NORMALIZE_MIN_QTRS == 6).
					//
					const adj = NULL_NETSTAT_VALUE * ((NORMALIZE_MIN_QTRS - 1.5 * posCount[index]) / NORMALIZE_MIN_QTRS)
					newValue += adj
				}
				newValue *= scale
				return newValue
			})

			// console.log('final netstats for', players[playerId].name, playerId, allNetStats[playerId], 'from raw stats', netStats, 'with posCount', posCount)

		})
	}

	// console.log('allNetStats', allNetStats)

	return allNetStats
}

function calculateQtrGoalDiffs({ games }) {
	const qtrGoalDiffs = { }

	Object.keys(games).forEach(gameId => {
		const game = games[gameId]
		const { result } = game
		if (result && (result.me[2] || result.them[2])) {
			// This game appears to have qtr-by-qtr scores
			const qtrGoalsMe = result.me.map((goals, qtr) => {
				if (!qtr)
					return goals
				return goals - result.me[qtr - 1]
			})
			const qtrGoalsThem = result.them.map((goals, qtr) => {
				if (!qtr)
					return goals
				return goals - result.them[qtr - 1]
			})
			qtrGoalDiffs[gameId] = [ 0, 1, 2, 3 ].map(qtr => {
				let diff = Number(qtrGoalsMe[qtr]) - Number(qtrGoalsThem[qtr])

				// Normalize!
				const avgDiffPerQtr = (result.me[3] - result.them[3]) / 4
				// console.log('game', game, 'avgDiffPerQtr', avgDiffPerQtr, 'so therefore', diff, '=>', diff - avgDiffPerQtr)
				diff -= avgDiffPerQtr

				return diff
			})
		}
	})

	// console.log('qtrGoalDiffs', qtrGoalDiffs)

	return qtrGoalDiffs
}

const BestQtrs = props => {

	const { type, section, games, rosters, players } = props

	if (!type || !games || !rosters || !players)
		return null

	let gamesData
	if (type === 'best') {
		gamesData = games
	} else {
		gamesData = deepCopy(games)
		Object.keys(gamesData).forEach(gameId => {
			const game = gamesData[gameId]
			const { result } = game
			if (result) {
				if (type === 'attack') {
					// ignore conceded goals
					game.result.them = [ 0, 0, 0, 0 ]
				} else if (type === 'defence') {
					// ignore scored goals
					game.result.me = [ 0, 0, 0, 0 ]
				}
			}
		})
	}

	const qtrGoalDiffs = calculateQtrGoalDiffs({ games: gamesData })

	// console.log('qtrGoalDiffs', qtrGoalDiffs, 'using gamesData', gamesData)

	const qtrs = [ ]
	Object.keys(qtrGoalDiffs).forEach(gameId => {
		qtrGoalDiffs[gameId].forEach((diff, qtr) => {
			qtrs.push({
				gameId,
				qtr,
				diff,
			})
		})
	})

	const sortedQtrs = qtrs.sort((a, b) => b.diff - a.diff)

	// console.log('sortedQtrs', sortedQtrs)

	return (
			<div className="stats-best-qtrs">
				<div className="stats-best-qtrs-list">
					{
						sortedQtrs.map(item =>
							<BestQtrRow
								key={`${item.gameId}-${item.qtr}`}
								gameId={item.gameId}
								game={games[item.gameId]}
								roster={rosters[item.gameId]?.roster}
								players={players}
								diff={item.diff}
								qtr={item.qtr}
								section={section}
							/>
						)
					}
				</div>
			</div>
	)
}

const BestQtrRow = props => {
	const { gameId, game, diff, qtr, roster: rosterData, players, section } = props

	const { round, opponent } = game

	// If there's scores but no roster, we want to show an empty row
	const roster = rosterData || new Array(7 * 4).fill(null)

	const playersPerQtr = roster.length / 4

	const qtrRoster = roster.slice(qtr * playersPerQtr, qtr * playersPerQtr + 7)

	// console.log('roster', roster, '=> qtrRoster', qtrRoster, qtr, playersPerQtr)

	return (
		<Link
			className="summaryline"
			to={{
				pathname: "/roster",
				search: generateSearchString({
					section,
					type: {
						unit: 'game',
					},
					item: {
						id: gameId,
					},
				})
			}}
		>
			<div className="bestqtr">
				<div className="bestqtr-round">
					<div className="bestqtr-round-round">
						Round {round}
					</div>
					<div className="bestqtr-round-qtr">
						Q{qtr + 1}
					</div>
				</div>
				<div className="bestqtr-opponent">
					vs {opponent}
				</div>
			</div>
			{
				qtrRoster.map((playerId, index) =>
					<div
						key={playerId || index}
						className={`cell cell-position cell-position-${fieldPositions[index]}`}
					>
						{players[playerId]?.name}
					</div>
				)
			}
			<div>
				{diff >=0 ? '+' : ''}{diff}
			</div>


		</Link>
	)
}

export default Stats
