import React, { useState, useEffect } from 'react'
import ProgressBar from './ProgressBar'

const loadingProgressUpdateDelay = 150

const LoadingBar = ({ loading }) => {

	const [ loadingProgress, setLoadingProgress ] = useState(0)
	const [ mounted ] = useState(Date.now())

	const calculateLoadingProgress = () => {
		if (loading) {
			return 100 - (10000 / (Date.now() + 10 - mounted))
		} else {
			return 100
		}
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoadingProgress(calculateLoadingProgress)
		}, loadingProgressUpdateDelay)
		return () => {
			clearTimeout(timer)
		}
	})

	return (
		<ProgressBar
			variant="determinate"
			value={loading ? loadingProgress : 100}
		/>
	)
}

export default LoadingBar
