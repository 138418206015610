import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import posed, { PoseGroup } from 'react-pose'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { db } from './firebase'
import Header from './Header'
import MultiPageTitle from './MultiPageTitle'
import AddItem from './AddItem'
import EditItem, { validateResultField } from './EditItem'
import EditButton from './EditButton'
import LoadingBar from './LoadingBar'
import Share from './Share'
import { fetchDataSet } from './Data'
import { ucFirst, generateSearchString, userCanEditItem, userCanAddItem } from './util'
import { FaPlus, FaEdit, FaTrashAlt } from 'react-icons/fa'
import { HiDotsHorizontal } from 'react-icons/hi'
import { GiConfirmed } from 'react-icons/gi'
import "./list.css"

const staggerDuration = 40

const AnimatedBox = posed.div({
    enter: {
        y: 0,
        opacity: 1,
		delay: ({ i }) => (i || 0) * staggerDuration,
    },
    exit: {
        y: 50,
        opacity: 0,
    }
})

const List = props => {

	const { type, team, season, section, user } = props

	const [ items, setItems ] = useState([ ])
	const [ extraItems, setExtraItems ] = useState({ })
	const [ mode, setMode ] = useState(null)
	const [ loading, setLoading ] = useState(true)
	const [ filtered, setFiltered ] = useState([ ])

	const getLocalStorageKey = useCallback(() => 'filter-' + (type?.name || 'default'), [ type.name ])
	const [ filterMode, setFilterMode ] = useState(() => localStorage.getItem(getLocalStorageKey()) || 'default')

	const getFilter = useCallback(() => {
		if (type.filter) {
			for (let i = 0; i < type.filter.length; i++) {
				const filter = type.filter[i]
				if (filterMode === filter.id) {
					return filter
				}
			}
		}
		return null
	}, [ filterMode, type.filter ])

	const filterItems = useCallback(myItems => {
		if (filterMode && type.filter && myItems.length) {
			const filter = getFilter()
			if (filter && filter.filterFunction) {
				const newFiltered = myItems.map(item => filter.filterFunction(item))
				setFiltered(newFiltered)
			}
		}
	}, [ filterMode, type.filter, getFilter ])

	//
	// Load data on component mount.
	//
	useEffect(() => {

		//
		// Some types of items (like teams) need to check something before
		// loading any data (like whether you are a logged-in user). So
		// if this precheck exists and fails, abort any attempt to load data.
		//
		if (type.precheck && !type.precheck()) {
			setLoading(false)
			return
		}

		const requests = [{
				name: type.name,
				where: type.where,
				section,
			}]

		if (type.extraItems) {
			type.extraItems.forEach(key =>
				requests.push({
					name: key,
					section,
				})
			)
		}

		const unsubscribe = fetchDataSet(requests, (data) => {

			const myData = data[type.name]
			if (type.extraItems) {
				const myExtraItems = Object.assign({ }, data)
				delete myExtraItems[type.name]
				setExtraItems(myExtraItems)
			}

			if (myData) {
				//
				// Add 'id' field to each object, since we're about to turn
				// them into an array.
				//

				Object.keys(myData).forEach(key =>
					myData[key].id = key
				)

				//
				// Convert to array, sorting if necessary.
				//
				const sortedItems = Object.values(myData).sort(type.sortFunction || defaultSortFunction)

				setItems(sortedItems)
				filterItems(sortedItems)
				// console.log('set items', data, sortedItems)
			} else {
				// console.error('no data?', props)
			}

			setLoading(false)
			// console.log('set loading false')
		})

		return unsubscribe

	}, [ section, type, filterItems ])

	//
	// Manage filter mode
	//
	// Save filter changes in localStorage, because it's annoying to be in "All Games"
	// mode, look at a game, come back, and be returned to "Future Games" mode only.
	//
	useEffect(() => {
		filterItems(items)
		localStorage.setItem(getLocalStorageKey(), filterMode)
	}, [ filterItems, items, filterMode, getLocalStorageKey ])

	const userCanEdit = userCanEditItem({
		type,
		team,
		user,
	})

	const addButton = {
		show: userCanAddItem({
			type,
			user,
			team,
		}),
		className: "button-icon button-animated button-mixed",
		onClick: e => setMode('add'),
	}

	let filterButtons
	if (items.length && type.filter) {
		filterButtons = (
			<div className="filter-buttons">
				{
					type.filter.map(filter =>
						<button
							key={filter.id}
							className={filter.id === filterMode ? 'enabled' : ''}
							onClick={e => setFilterMode(filter.id)}
						>
							{filter.name}
						</button>
					)
				}
			</div>
		)
	}

	//
	// What to display if we're not showing any items.
	//
	let noItemsMessage
	if (!loading) {
		if (!items.length) {

			//
			// We've loaded but there are no items at all.
			//
			noItemsMessage = (type.noItemsMessage && type.noItemsMessage(props)) || (
				<p>
					No {type.name}.
					{' '}
					{
						team ? (
							season ? null : (
								<Link
									className="linkish"
									to="/seasons"
								>
									Select a season.
								</Link>
							)) : (
								<Link
									className="linkish"
									to="/teams"
								>
									Select a team.
								</Link>
							)
					}
				</p>
			)

		} else if (filterMode !== 'all') {

			const numHiddenItems = filtered.filter(n => n).length

			if (numHiddenItems === items.length) {
				//
				// We have some items but they're all filtered out.
				//
				const filterName = getFilter().name.toLowerCase()
				noItemsMessage = (
					<p className="no-items">
						No {filterName} {type.name}.
						<Link
							className="linkish"
							to={{}}
							onClick={e => setFilterMode('all')}
						>
							Show all {type.name}.
						</Link>
					</p>
				)
			}
		}
	}

	//
	// Share Button
	//
	let ShareButton
	if (type.share) {
		ShareButton = (
			<Share section={section} />
		)
	}

	const niceName = type.niceName || ucFirst(type.name)

	return (
		<section className="list">
			<Header team={team} title={niceName} season={season} />
			<MultiPageTitle
				title={niceName}
				altTitles={type.altTitles}
				section={section}
			/>
			<LoadingBar
				loading={loading}
			/>
			{filterButtons}
			{noItemsMessage}
			<div className={`list-items list-${type.name}`}>
				<PoseGroup>
					{
						items.map((item, index) =>
							!filtered[index] &&
							<AnimatedBox
								key={item.id}
								i={index}
								className="list-item"
							>
								<Item
									item={item}
                                    items={items}
									team={team}
									season={season}
									section={section}
									extraItems={extraItems}
									type={type}
									userCanEdit={userCanEdit}
								/>
							</AnimatedBox>
						)
					}
				</PoseGroup>
			</div>
			{
				mode === 'add' ? (
					<AddItem
						dbid={type.dbid}
						type={type}
						team={team}
						season={season}
						user={user}
                        items={items}
						onCancel={e => setMode(null)}
						quickAdding={type.quickAdding}
						lastItem={items[items.length - 1]}
					/>
				) : (
					<div className="underneath-list-add-edit">
						<EditButton
							text="Add"
							icon={<FaPlus />}
							show={addButton.show}
							ready={true}
							className={addButton.className}
							onClick={addButton.onClick}
						/>
					</div>
				)
			}
			{ShareButton}
		</section>
	)
}

const Item = (props) => {

	const { item, items, type, section, extraItems, userCanEdit } = props

	const [ fieldData, setFieldData ] = useState(item)
	const [ dialogOpen, setDialogOpen ] = useState(false)
	const [ dialog, setDialog ] = useState()
	const [ editMode, setEditMode ] = useState(false)

	const toggleEditMode = () => setEditMode(!editMode)

	const handleChange = e => {
		const newData = Object.assign({ }, fieldData)
		delete newData.id

		if (newData.result !== undefined && newData.result !== null) {
			if (!validateResultField(newData.result)) {
				console.log('invalid result', newData.result)
				newData.result = null
			}
		}

        // Prevent assigning multiple players the exact same name, because that
        // throws errors in rosters.
        if (type.unit === 'player' && items?.filter(obj => obj.name === newData.name && obj.id !== item.id)?.length) {
            console.log("Name dupe!")
            window.alert("Error: Multiple players may not have the same name.")
        } else {
            db.collection(type.dbid).doc(item.id).update(newData)
            toggleEditMode()
        }
	}

	const onClickDelete = () => {
		setDialogOpen(true)

		const title = `Delete ${ucFirst(type.unit)}` + (item.name ? `: ${item.name}` : '')

		if (type.unit === 'season') {
			setDialog({
				title,
				text: "Deleting a season will permanently remove all associated games, rosters, and players. You cannot undo this action. Are you sure?",
				buttons: (
					<DialogActions>
						<button className="button-cancel" onClick={handleDialogClose}>
							CANCEL
						</button>
						<button className="button-delete-item button-delete-item-caps" onClick={() => {
							//
							// Recursively delete everything
							//
							[ 'rosters', 'games', 'players' ].forEach(collectionName => {
								const id = type.dbid + '/' + item.id + '/' + collectionName
								// console.log("ID is", id)
								const collectionRef = db.collection(id)
								collectionRef.get().then(query => {
									// console.log("collectionName", collectionName, query.size)
									if (query.size) {
										query.forEach(doc => {
											// console.log('delete doc', doc.id)
											collectionRef.doc(doc.id).delete()
										})
									}
								})
							})
							// console.log("Delete season", type.dbid, item.id)
							deleteDocument({ colId: type.dbid, docId: item.id })
						}}>
							DELETE SEASON
						</button>
					</DialogActions>
				),
			})
		} else if (type.unit === 'team') {
			// check whether this team has any seasons. If it does, say you have to delete the seasons first.
			db.collection(`/teams/${item.id}/seasons`).get().then(query => {
				// console.log("query", query.size)
				if (query.size > 0) {
					// window.alert("Please delete all the team's seasons first.")
					setDialog({
						title: 'This Team Has Remaining Seasons',
						text: "Please delete all of this team's seasons first.",
						buttons: (
							<DialogActions>
								<button className="button-cancel" onClick={handleDialogClose}>
									OK
								</button>
							</DialogActions>
						),
					})
				} else {
					console.log("Deleting team.")
					deleteDocument({ colId: type.dbid, docId: item.id })
				}
			})
		} else {
			setDialog({
				title,
				text: `Are you sure you want to permanently delete this ${type.unit}?`,
				buttons: (
					<DialogActions>
						<button className="button-cancel" onClick={handleDialogClose}>
							CANCEL
						</button>
						<button className="button-delete-item button-delete-item-caps" onClick={() => deleteDocument({ colId: type.dbid, docId: item.id })}>
							DELETE {item.name}
						</button>
					</DialogActions>
				),
			})
		}
	}

	const deleteDocument = ({ colId, docId }) => {
		console.log("Deleting.")
		db.collection(colId).doc(docId).delete()
		setDialogOpen(false)
	}

	const handleDialogClose = () => {
		setDialogOpen(false)
	}

	const allFields = type.fields.concat(type.editOnlyFields || [ ])

	const dialogContent = dialogOpen && (
		<MyDialog
			dialog={dialog}
			dialogOpen={dialogOpen}
			handleDialogClose={handleDialogClose}
		/>
	)

	const domId = `${type.unit}-${item.id}`

	const myClasses = [ 'item', type.unit ]

	let content

	if (editMode) {
		myClasses.push('editable-item')

		content =(
			<div className={myClasses.join(' ')}>
				{
					allFields.map(field =>
						<EditItem
							key={field}
							field={field}
							type={type}
							setFieldData={setFieldData}
							fieldData={fieldData}
							onEnter={handleChange}
						/>
					)
				}
				<div className="edit-buttons">
					<button
						className="button-update-item button-icon"
						onClick={handleChange}
					>
						<GiConfirmed /> Update
					</button>
					<button
						className="button-cancel"
						onClick={toggleEditMode}
					>
						Cancel
					</button>
				</div>

			</div>
		)
	} else {

		const toObj = type.pathname && {
			pathname: type.pathname,
		}

		toObj.search = generateSearchString({
			section,
			type,
			item,
		})


		content = (
			<Link
				className={myClasses.join(' ')}
				to={toObj}
			>
				<ItemMenu
					userCanEdit={userCanEdit}
					domId={domId}
					unit={type.unit}
					onClickDelete={onClickDelete}
					onClickEdit={toggleEditMode}
				/>
				{
					allFields.map(field =>
						<ItemField
							key={field}
							field={field}
							value={item[field]}
							type={type}
						/>
					)
				}
				{
					type.extraContent && extraItems && type.extraContent({
						item,
						extraItems,
					})
				}
			</Link>
		)
	}

	return (
		<>
			{content}
			{dialogContent}
		</>
	)
}

const ItemField = props => {
	const { field, value, type } = props

	const content = type.formatField ? type.formatField(props) : value

	return (
		<div className={`field-${field}`}>
			{content}
		</div>
	)
}

const ItemMenu = props => {

	const { userCanEdit, domId, unit, onClickEdit, onClickDelete } = props

	const [ anchorEl, setAnchorEl ] = useState(null)

	if (!userCanEdit) {
		return null
	}

	const menuId = `item-menu-${domId}`

	const handleClick = (event) => {
		event.stopPropagation()
		event.preventDefault()
		setAnchorEl(document.getElementById(menuId))
	}

	const handleClose = (event) => {
		event.stopPropagation()
		event.preventDefault()
		setAnchorEl(null)
	}

	return (
		<div className="item-menu">
			<button
				aria-controls="item-menu"
				aria-haspopup="true"
				className="button-item-menu button-icon"
				id={menuId}
				title="Item Menu"
				type="button"
				onClick={handleClick}
			>
				<HiDotsHorizontal />
			</button>
			<Menu
				className="item-menu-overlay"
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				transformOrigin={{ vertical: "bottom", horizontal: "right" }}
				// keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem
					className="item-menu-item item-menu-edit"
					onClick={(event) => { onClickEdit(); handleClose(event); }}
				>
					<FaEdit />
					Edit {unit}
				</MenuItem>
				<MenuItem
					className="item-menu-item item-menu-delete"
					onClick={(event) => { onClickDelete(); handleClose(event); }}
				>
					<FaTrashAlt />
					Delete {unit}
				</MenuItem>
			</Menu>
		</div>
	)
}

const MyDialog = props => {
	const { dialog, dialogOpen, handleDialogClose } = props

	if (!dialog)
		return null

	const { title, text, buttons } = dialog

	return (
		<Dialog onClose={handleDialogClose} aria-labelledby="my-dialog" open={dialogOpen} onClick={e => e.stopPropagation()}>
			<DialogTitle id="my-dialog">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{text}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				{buttons}
			</DialogActions>
		</Dialog>
	)
}

//
// Sorts from oldest to newest, then alphabetically.
//
function defaultSortFunction(a, b) {
	return (
		((a.created || 0) - (b.created || 0))
		||
		String(a.name).localeCompare(b.name)
	)
}

export default List
