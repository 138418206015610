import React from 'react'
import GridCell from './GridCell'
import { TiPin } from 'react-icons/ti'
import { FaCloudShowersHeavy } from 'react-icons/fa'

const CellPosition = props => {

	const { content, richContent, isAvailable, i, isLocked, isNullPosition, onClick, positionName, children } = props

	const icons = [ ]

	if (isLocked) {
		icons.push(<TiPin className="cell-locked" />)
	}

	if (isNullPosition) {
		icons.push(<FaCloudShowersHeavy />)
	}

	return (
		<GridCell
			type="position"
			content={content}
			richContent={richContent}
			icons={icons}
			isAvailable={isAvailable}
			isNullPosition={isNullPosition}
			i={i}
			positionName={positionName}
			onClick={onClick}
			children={children}
		/>
	)
}

export default CellPosition
