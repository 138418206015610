import React, { Suspense, lazy } from 'react'
import Modal from '@material-ui/core/Modal'
import ProgressBar from './ProgressBar'

//
// The 'xlsx' module is enormous, do don't load it unless we
// need it.
//
const TrialExporter = lazy(() => import('./TrialExporter'))

const TrialExport = props => {

	const { onComplete } = props

	return (
		<div className="trials-export">
			<Modal
				open={true}
				onClose={onComplete}
				aria-labelledby="export-roster"
				aria-describedby="Export your data to a spreadsheet"
				className="trials-modal trials-modal-export"
			>
				<div className="trials-modal-content">
					<h2>
						Export
					</h2>

					<Suspense
						fallback={
							<div>
								<p>Loading export module...</p>
								<ProgressBar />
							</div>
						}
					>
						<TrialExporter {...props} />
					</Suspense>
				</div>
			</Modal>
		</div>
	)
}

export default TrialExport
