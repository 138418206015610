import React, { useState, useEffect, useMemo, useReducer, useCallback } from 'react'
import GridPlayers from './GridPlayers'
import GridPreferences from './GridPreferences'
import GridCell from './GridCell'
import GridCellPosition from './GridCellPosition'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { TabsMain, TabsRoster, TabPanel } from './TrialTabs'
import TrialControls from './TrialControls'
import Modal from '@material-ui/core/Modal'
import ProgressBar from './ProgressBar'
import FadeText from './FadeText'
import TrialExport from './TrialExport'
import { analytics } from './firebase'
import { generateNewPlayerName, parseRosterTrials, shuffle, joinListStrings, getGameInfo, loadFromLocalStorage, saveToLocalStorage } from './util'
import { fieldPositions, BENCHED, playersPerTeam, playersPerGame, maxTrialsPlayers } from './definitions'
import { GrowInBox } from './definitionsPoses'
import { FixedItem } from './definitionsPoses'
import { FaFileUpload, FaCat, FaCrow, FaGrinBeamSweat, FaStreetView, FaHandsHelping } from 'react-icons/fa'
import { TiLightbulb } from 'react-icons/ti'
import './trials.css'

//
// With Trials, we may have hundreds of players (and thousands of position cells). This
// means it's too slow to allow React to re-render every cell whenever the user clicks
// something. Instead, we have to:
// (1) Ensure that relevant child components are defined with React.memo(), so that they
//     only re-render if their props change; and
// (2) Pass Reducers rather than functions (because the latter get recreated all the time,
//     which is unavoidable if they require knowledge of current state).
//
// Instead of passing reducers to children manually, I could use Context, but I'll stick
// with the same approach as the rest of the app.
//

const localStorageKey = 'trials'

const Trials = props => {

	const initialProgress = {
		value: 0,
	}

	const [ numCourts, setNumCourts ] = useState(1)
	const [ numMinGamesPerPlayer, setNumMinGamesPerPlayer ] = useState(1)
	const [ ready, setReady ] = useState(true)
	const [ view, setView ] = useState(0)
	const [ roster, setRoster ] = useState([ ])
	const [ rounds, setRounds ] = useState()
	const [ progress, setProgress ] = useState(initialProgress)
	const [ numConsecutiveGames, setNumConsecutiveGames ] = useState()
	const [ amBusy, setAmBusy ] = useState(false)
	const [ lastComputedRoster, setLastComputedRoster ] = useState()
	const [ showExport, setShowExport ] = useState(false)
	const [ options, setOptions ] = useState({
		noFillIns: false,
		noEmptySlots: false,
		noRepeatPositions: false,
		noConsecutiveRounds: true,
	})

	//
	// On mount, load any saved data.
	//
	useEffect(() => {
		const saveData = loadFromLocalStorage(localStorageKey)
		if (saveData) {
			try {
				if (verifyData(saveData)) {
					setNumCourts(saveData.numCourts)
					setNumMinGamesPerPlayer(saveData.numMinGamesPerPlayer)
					modifyPlayers({ newPlayers: saveData.players })
					modifyPreferences({ newPreferences: saveData.preferences })
					if (saveData.options)
						setOptions(saveData.options)
					// setRoster(saveData.roster)
					console.log('restored from saved data', saveData)
				}
			} catch(error) {
				console.error('Failed to load from localStorage', error)
			}
		}
	}, [ ])

	//
	// Reducer for 'players'
	//
	// Restore saved data:
	//
	//   { newPlayers, }
	//
	// Rename player:
	//
	//   { index: 1, newName: 'Max' }
	//
	// Add players:
	//
	//   { addPlayers: [ 'Max', 'Jen', ... ] }
	//
	// Remove players:
	//
	//   { removePlayers: [ 'Max', 'Jen', ... ] }
	//
	const playersReducer = (state, action) => {

		// console.log('playersReducer', action)

		if (action.newPlayers) {
			return action.newPlayers
		}

		if (action.addPlayers) {
			return state.concat(action.addPlayers)
		}

		if (action.removePlayers) {
			return state.filter(player => action.removePlayers.indexOf(player) === -1)
		}

		//
		// Rename player
		//
		const { index, newName } = action

		if (state.indexOf(newName) !== -1) {
			console.log("Refusing to rename: name already exists.")
			return state
		}

		const newPlayers = state.slice()
		newPlayers[index] = newName
		return newPlayers
	}

	const [ players, modifyPlayers ] = useReducer(playersReducer, [ ])

	//
	// 'action':
	//
	// Restore saved data:
	//
	//   { newPreferences: newPreferences }
	//
	// Toggle a preference:
	//
	//   { player: 'Max', target: 'WA', positionIndex: 4, value: 1 }
	//
	// Rename a player:
	//
	//   { player: 'Max', newName: 'MaxyBoy' }
	//
	// Add players:
	//
	//   { addPlayers: [ 'Max', 'Jen', ... ] }
	//
	// Remove players:
	//
	//   { removePlayers: [ 'Max', 'Jen', ... ] }
	//
	const preferencesReducer = (state, action) => {

		// console.log('preferencesReducer', state, action)

		if (action.newPreferences) {
			return action.newPreferences
		}

		if (action.addPlayers) {
			return Object.assign(action.addPlayers.reduce((map, player) => {
				map[player] = new Array(fieldPositions.length).fill(0)
				return map
			}, { }), state)
		}

		if (action.removePlayers) {
			const newPreferences = Object.assign({ }, state)
			action.removePlayers.forEach(player => delete newPreferences[player])
			return newPreferences
		}

		const { oldName, newName, player, positionIndex, value } = action

		if (newName) {
			if (state[newName]) {
				console.log("Ignoring rename request to", newName, "because there is already a player named", newName)
				return state
			}
			const newPreferences = Object.assign({ [newName]: state[oldName] }, state)
			delete newPreferences[oldName]
			return newPreferences
		}

		const newPreferences = Object.assign({ }, state)

		newPreferences[player][positionIndex] = value
		return newPreferences
	}

	const [ preferences, modifyPreferences ] = useReducer(preferencesReducer, { })

	//
	// Create an array of the number of preferences for each position,
	// from GS to GK, like [ 10, 7, 11, 8, 12, 9 ]
	//
	const cumulativePreferences = useMemo(() =>
		Object.values(preferences).reduce((total, row) => total.map((n, index) => n + row[index]), new Array(fieldPositions.length).fill(0))
		, [ preferences ])

	//
	// We don't actually use 'available' in Trials, but some child components
	// expect it, so we'll generate an object that says everyone is available.
	//
	const available = useMemo(() =>
		players.reduce((map, player) => {
			map[player] = true
			return map
		}, { })
		, [ players ] )

	//
	// Recalculate this every time we change a user-controlled setting.
	//
	const lastMadeChanges = useMemo(() =>
		new Date()
		//
		// Deliberately include some things we want to trigger a recalc
		//
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [ players, preferences, numCourts, numMinGamesPerPlayer, options ])

	const haveMadeChanges = (lastComputedRoster && lastComputedRoster < lastMadeChanges)

	//
	// Renaming a player.
	//
	const renamePlayer = useCallback(props => {
		if (props.newName) {
			modifyPreferences(props)
			modifyPlayers(props)
		}
	}, [ ])

	//
	// Set the number of available courts.
	//
	const changeNumCourts = newNumCourts => {
		setNumCourts(newNumCourts)
	}

	//
	// Whenever we change anything, schedule a save in the near
	// future.
	//
	useEffect(() => {
		const timer = setTimeout(() => {
			console.log("Saving.")
			saveToLocalStorage({
				players,
				preferences,
				numCourts,
				numMinGamesPerPlayer,
				options,
				// roster,
			}, localStorageKey)
		}, 2500)

		return () => clearTimeout(timer)
	}, [ players, preferences, numCourts, numMinGamesPerPlayer, options ])

	const onAutoRoster = opt => {
		setReady(false)

		console.log("Planning", opt && opt.options, options)

		setProgress(initialProgress)

		setLastComputedRoster(new Date())

		if (window.scrollY > 420) {
			window.scrollTo(0, 0)
		}

		analytics.logEvent('autoroster-trials', {
			numPlayers: Object.keys(preferences).length,
		});

		const delay = roster ? 300 : 20

		setTimeout(() =>
			multiPlanRoster({
				onProgress,
				preferences,
				cumulativePreferences,
				numCourts,
				numMinGamesPerPlayer,
				options: (opt && opt.options) || options,
			})
			, delay)
	}

	const onProgress = newState => {
		if (newState.best) {
			setRoster(newState.best.roster)
			setRounds(newState.rounds)
		}
		if (newState.progress !== undefined) {
			setProgress(newState.progress)
		}
		if (newState.numConsecutiveGames !== undefined) {
			setNumConsecutiveGames(newState.numConsecutiveGames)
		}
		console.log("I have received new state", newState)
		if (newState.ready) {
			setReady(true)
		}
	}

	const setNumPlayers = n => {
		const numExistingPlayers = players.length
		setAmBusy(n >= 50 && n - numExistingPlayers >= 50)
		setTimeout(() => {
			if (n > numExistingPlayers) {
				const addPlayers = generateNewPlayerName(players, n - numExistingPlayers)
				modifyPlayers({ addPlayers })
				modifyPreferences({ addPlayers })
			} else if (n < numExistingPlayers) {
				const removePlayers = players.slice(n - numExistingPlayers)
				modifyPlayers({ removePlayers })
				modifyPreferences({ removePlayers })
			} else {
				console.error('what?')
			}
			setAmBusy(false)
		})
	}

	const importPlayers = data => {
		console.log("importing", data)

		const newPlayers = Object.keys(data)
		modifyPlayers({ newPlayers })

		const newPreferences = newPlayers.reduce((map, player) => {
			map[player] = fieldPositions.map(position => data[player].indexOf(position) === -1 ? 0 : 1)
			return map
		}, { })

		modifyPreferences({ newPreferences })
		console.log('newPreferences', newPreferences)

		analytics.logEvent('trials-import', {
			numPlayers: newPlayers.length,
		})
	}

	const onExport = () => {
		console.log('export time!')
		setShowExport(true)

		analytics.logEvent('trials-export', {
			numPlayers: players.length,
			numGames: roster.length / playersPerGame,
		})
	}

	//
	// Not a function to avoid re-rendering players every time
	// the user makes a preference change.
	//
	const removePlayer = useCallback(player => {
		const obj = {
			removePlayers: [ player ],
		}
		modifyPlayers(obj)
		modifyPreferences(obj)
	}, [ modifyPlayers, modifyPreferences ])

	const onFillSlots = props => {
		console.log('Fill slots!')
		const newOptions = Object.assign({ }, options)
		newOptions.noEmptySlots = true
		newOptions.noFillIns = false
		setOptions(newOptions)
		setTimeout(() => onAutoRoster({ options: newOptions }), 100)
	}

	const onEliminateDuplicatePositions = props => {
		console.log("Kill dupes")
		const newOptions = Object.assign({ }, options)
		newOptions.noRepeatPositions = true
		setOptions(newOptions)
		setTimeout(() => onAutoRoster({ options: newOptions }), 100)
	}

	const onEliminateFillIns = props => {
		console.log("No fill-ins")
		const newOptions = Object.assign({ }, options)
		newOptions.noFillIns = true
		newOptions.noEmptySlots = false
		newOptions.noRepeatPositions = false
		setOptions(newOptions)
		setTimeout(() => onAutoRoster({ options: newOptions }), 100)
	}

	return (
		<section className="section-trials">
			<p className="trials-intro-text">
				Generate rosters for many players on multiple courts.
			</p>
			{
				amBusy ? (
					<div className="trials-am-busy">
						Please wait...
					</div>
				) : null
			}
			<TabsMain
				ready={ready && players && players.length}
				view={view}
				setView={setView}
			/>
			<TabPanel value={view} index={0} className="trials-players">
				<EnterPlayers
					players={players}
					setNumPlayers={setNumPlayers}
					importPlayers={importPlayers}
				/>
				<div id="board" className="grid roster-unlocked trials-grid">
					<GridPlayers
						players={players}
						editMode={true}
						isSingleRoster={true}
						isTrials={true}
						onDrop={null}
						setAmDragging={null}
						available={available}
						toggleAvailable={removePlayer}
						renamePlayer={renamePlayer}
					/>
					{
						fieldPositions.map((position, index) =>
							<GridPreferences
								key={index}
								isTrials={true}
								position={position}
								positionIndex={index}
								players={players}
								preferences={preferences}
								setPreference={modifyPreferences}
								totalNumber={cumulativePreferences[index]}
							/>
						)
					}
				</div>
			</TabPanel>

			<TabPanel value={view} index={1} className="trials-games">
				<EnterCourts
					numCourts={numCourts}
					numMinGamesPerPlayer={numMinGamesPerPlayer}
					options={options}
					setNumCourts={changeNumCourts}
					setNumMinGamesPerPlayer={v => setNumMinGamesPerPlayer(Number(v))}
					setOptions={setOptions}
				/>
				<TrialControls
					ready={ready && players && players.length}
					haveRoster={roster && roster.length}
					haveMadeChanges={haveMadeChanges}
					onAutoRoster={onAutoRoster}
					onExport={onExport}
				/>
				{
					showExport ? (
						<TrialExport
							players={players}
							roster={roster}
							rounds={rounds}
							onComplete={e => setShowExport(false)}
						/>
					) : null
				}
				<ExplainConstraints
					players={players}
					preferences={preferences}
					cumulativePreferences={cumulativePreferences}
					numCourts={numCourts}
					numMinGamesPerPlayer={numMinGamesPerPlayer}
					options={options}
				/>
				<ProgressBar
					variant="determinate"
					value={progress.value}
				/>
				<ProgressText
					progress={progress}
				/>
				<RosterFreshness
					haveMadeChanges={haveMadeChanges}
				/>
				<RosterStats
					ready={ready}
					players={players}
					roster={roster}
					preferences={preferences}
					rounds={rounds}
					lastComputedRoster={lastComputedRoster}
					numConsecutiveGames={numConsecutiveGames}
					available={available}
					onFillSlots={onFillSlots}
					onEliminateDuplicatePositions={onEliminateDuplicatePositions}
					onEliminateFillIns={onEliminateFillIns}
				/>
			</TabPanel>
		</section>
	)
}

const ProgressText = props => {
	const { progress } = props
	const { value, noneChosen, numGames, numRounds, numConsecutiveGames } = progress

	const show = value && value >= 20 && (value < 100 || (value === 100 && noneChosen))

	const text = !noneChosen ? (
		<span>
			AutoRoster has computed a solution with {numGames} games in {numRounds} rounds with {numConsecutiveGames} back-to-back games. Trying to improve
		</span>
	) : value < 100 ? (
		<span>
			Searching for a solution with {numGames} games
		</span>
	) : (
		<span>
			Oh no! AutoRoster failed to find a solution. Please try again
		</span>
	)

	return (
		<GrowInBox className="GrowInBox" pose={show ? 'visible' : 'hidden'}>
			<p>
				{text}... {Number(value).toFixed(1)}%
			</p>
		</GrowInBox>
	)
}

const RosterFreshness = props => {
	const { haveMadeChanges } = props

	return (
		<GrowInBox className="GrowInBox" pose={haveMadeChanges ? 'visible' : 'hidden'}>
			<div className="info-box trials-outdated-roster">
				<FaCat />
				You have made changes since this roster was computed.
			</div>
		</GrowInBox>
	)
}

const RosterStats = props => {
	const { ready, players, roster, preferences, rounds, lastComputedRoster, available, numConsecutiveGames, onFillSlots, onEliminateDuplicatePositions, onEliminateFillIns } = props

	const [ view, setView ] = useState(0)
	const [ previousComputedRoster, setPreviousComputedRoster ] = useState(lastComputedRoster)

	const playerRoster = useMemo(() => parseRosterTrials({
		players,
		roster,
	}), [ players, roster ])

	if (!roster || !roster.length || !rounds)
		return null

	const numPlayers = roster && [...new Set(roster.filter(v => v))].length
	const numGames = roster && roster.length / playersPerGame
	const numRounds = rounds && rounds.length
	const numCourts = rounds && rounds.reduce((max, round) => Math.max(max, round.length), 0)

	const stats = {
		players: numPlayers,
		games: numGames,
		courts: numCourts,
		rounds: numRounds,
	}

	if (lastComputedRoster !== previousComputedRoster) {
		;[ 'players', 'games', 'courts', 'rounds' ].forEach(stat => stats[stat] = 0)
		setTimeout(() => setPreviousComputedRoster(lastComputedRoster), 200)
	}

	return (
		<div className={`trials-roster-output trials-roster-output-${ready ? 'ready' : 'unready'}`}>
			<div className="trials-roster-stats">
				<div>
					Players
					<SensorNumberBox
						n={stats.players}
					/>
				</div>
				<div>
					Games
					<SensorNumberBox
						n={stats.games}
					/>
				</div>
				<div>
					Courts
					<SensorNumberBox n={stats.courts} />
				</div>
				{
					numCourts !== 1 ? (
						<div>
							Rounds
							<SensorNumberBox n={stats.rounds} />
						</div>
					) : null
				}
			</div>

			<TabsRoster
				view={view}
				setView={setView}
			/>

			<TabPanel value={view} index={0} className="">
				<DisplayTrialsRoster
					roster={roster}
					numCourts={numCourts}
					rounds={rounds}
				/>
				<ExtraRosterInfo
					players={players}
					roster={roster}
					preferences={preferences}
					numConsecutiveGames={numConsecutiveGames}
					onFillSlots={onFillSlots}
					onEliminateDuplicatePositions={onEliminateDuplicatePositions}
					onEliminateFillIns={onEliminateFillIns}
				/>
			</TabPanel>

			<TabPanel value={view} index={1} className="">
				<DisplayTrialsPlayerSchedule
					roster={roster}
					rounds={rounds}
					players={players}
					playerRoster={playerRoster}
				/>
			</TabPanel>

			<TabPanel value={view} index={2} className="">
				<DisplayTrialsPlayerRoster
					roster={roster}
					rounds={rounds}
					players={players}
					available={available}
					playerRoster={playerRoster}
				/>
			</TabPanel>

		</div>
	)
}

const SensorNumberBox = props => {

	const { n } = props

	const [ displayedNumber, setDisplayedNumber ] = useState(n)

	useEffect(() => {

		let timer

		if (n !== displayedNumber) {

			timer = setInterval(() => {

				if (n !== displayedNumber) {

					if (n < displayedNumber) {
						setDisplayedNumber(0)
					} else {
						const amt = Math.max(1, Math.floor((n - displayedNumber) / 10))
						setDisplayedNumber(displayedNumber + amt)
					}
				}
			}, 30)
		}

		return () => clearInterval(timer)
	}, [ n, displayedNumber ])

	return (
		<span className="sensors-number-box-num">
			{displayedNumber}
		</span>
	)
}

const EnterPlayers = props => {
	const { players, setNumPlayers, importPlayers } = props

	const numPlayers = players && players.length

	const [ showModal, setShowModal ] = useState(false)
	const [ newValue, setNewValue ] = useState(null)
	const [ timer, setTimer ] = useState()

	const onClick = e => {
		setShowModal(true)
	}

	const closeModal = () => setShowModal(false)

	const isValid = v => v >= 0 && v <= maxTrialsPlayers

	const value = newValue === null ? numPlayers : newValue
	const errorText = isValid(value) ?
		null
		:
		value < 0 ? 'Too few players' : 'Too many players'

	const onChange = e => {
		clearTimeout(timer)
		const v = e.target.value
		setNewValue(v)
		if (isValid(v)) {
			setTimer(setTimeout(() => {
				console.log("Transmitting!", v)
				setNumPlayers(v)
				setTimeout(() => setNewValue(null))
			}, 1000))
		}
	}

	return (
		<>
			<form className="trials-form trials-form-players" noValidate autoComplete="off">
				<TextField
					label="Number of Players"
					type="number"
					value={value}
					onChange={onChange}
					error={errorText ? true : false}
					helperText={errorText}
					InputLabelProps={{
						shrink: true,
					}}
				/>
				<div>
					<button
						className="button-icon button-import linkish"
						type="button"
						onClick={onClick}
					>
						<FaFileUpload />
						{' '}
						Import Players
					</button>
				</div>
			</form>
			<ImportPlayersModal
				showModal={showModal}
				showWarningText={players && players.length}
				closeModal={closeModal}
				importPlayers={importPlayers}
			/>
		</>
	)
}

const ImportPlayersModal = props => {
	const { showModal, showWarningText, closeModal, importPlayers } = props

	const startImport = event => {
		event.preventDefault()

		const data = { }

		const lines = event.target[0].value.split(/\n/)

		lines.forEach(line => {
			console.log("Parse", line)
			const words = line.split(/[,\s]+/)
			// let nameStart = false
			let nameEnd = false
			const name = [ ]
			const positions = { }
			for (let i = 0; i < words.length; i += 1) {
				const word = words[i].trim()
				if (fieldPositions.indexOf(word) !== -1) {
					console.log("Looks like a position", word)
					positions[word] = true
					if (name.length) {
						nameEnd = true
					}
				} else if (!nameEnd) {
					/* Stop trying to match words, count anything */
					const isWordLike = word.length >= 1
					// const isWordLike = word.length > 1 && !word.match(/[\d[\]/?!@#$%^&*()+={}:;<>~]/)
					// console.log('is wordlike?', word, isWordLike)
					if (isWordLike) {
						name.push(word)
					//	nameStart = true
					// } else if (nameStart) {
					//	nameEnd = true
					}
				} else {
					// We don't know what this is.
					if (name.length) {
						nameEnd = true
					}
				}
			}

			if (!name.length) {
				return null
			}

			const niceName = name.join(' ')

			//
			// Handle the situation where people enter the same name multiple times.
			// We should include every position mentioned.
			//
			if (data[niceName]) {
				data[niceName].forEach(position => positions[position] = true)
			}

			data[niceName] = Object.keys(positions)
		})

		console.log('parsed', data)

		closeModal()

		if (Object.keys(data).length) {
			importPlayers(data)
		}
	}

	const warningText = showWarningText ? (
		<div className="info-box trials-outdated-roster">
			<FaCat />
			<div>
				Importing will overwrite your current player list.
			</div>
		</div>
	) : null

	return (
		<Modal
			open={showModal}
			onClose={closeModal}
			aria-labelledby="import-players"
			aria-describedby="Import players by pasting them"
			className="trials-modal trials-modal-import-players"
		>
			<div className="trials-modal-content">
				<h2>
					Import Players
				</h2>
				<p>
					Instead of manually entering players and selecting positions, you can copy & paste from a spreadsheet or list into the box below. AutoRoster will try to figure it out!
				</p>
				{warningText}
				<form
					className="trials-import-players-form"
					noValidate
					autoComplete="off"
					onSubmit={startImport}
				>
					<TextField
						name="import-players-data"
						className="trials-import-players-data"
						label="Paste your player list here."
						variant="outlined"
						placeholder="Matilda Barry GA GD"
						minRows={3}
						multiline
						fullWidth
					/>
					<p>
						<button
							className=""
							type="submit"
						>
							Import
						</button>
						<button
							className="linkish"
							type="button"
							onClick={closeModal}
						>
							Cancel
						</button>
					</p>
				</form>
			</div>
		</Modal>
	)
}

const EnterCourts = props => {
	const { numCourts, numMinGamesPerPlayer, options, setNumCourts, setNumMinGamesPerPlayer, setOptions } = props

	const handleChange = e => {
		const newOptions = {
			...options,
			[e.target.name]: e.target.checked,
		}

		if (e.target.checked) {
			if (e.target.name === 'noEmptySlots') {
				newOptions.noFillIns = false
			} else if (e.target.name === 'noFillIns') {
				newOptions.noEmptySlots = false
				newOptions.noRepeatPositions = false
			}
		}

		setOptions(newOptions)
	}

	return (
		<form className="trials-form trials-form-courts" noValidate autoComplete="off">
			<TextField
				label="Number of Courts"
				type="number"
				value={numCourts}
				onChange={e => setNumCourts(e.target.value)}
				InputLabelProps={{
					shrink: true,
				}}
			/>
			<TextField
				label="Min. Games per Player"
				type="number"
				value={numMinGamesPerPlayer}
				onChange={e => setNumMinGamesPerPlayer(e.target.value)}
				InputLabelProps={{
					shrink: true,
				}}
			/>

			<FormControl component="div" className="trials-form-switches">
				<FormControlLabel
					control={
						<Switch
							checked={options.noFillIns}
							onChange={handleChange}
							name="noFillIns"
							color="primary"
						/>
					}
					label="No Fill-ins"
					labelPlacement="top"
				/>
				<FormControlLabel
					control={
						<Switch
							checked={options.noEmptySlots}
							onChange={handleChange}
							name="noEmptySlots"
							color="primary"
						/>
					}
					label="No Empty Slots"
					labelPlacement="top"
				/>
				<FormControlLabel
					control={
						<Switch
							checked={options.noRepeatPositions}
							onChange={handleChange}
							name="noRepeatPositions"
							color="primary"
						/>
					}
					label="No Repeat Positions"
					labelPlacement="top"
				/>
			</FormControl>

		</form>
	)
}

const DisplayTrialsRoster = props => {
	const { roster, rounds } = props

	if (!roster)
		return null

	const numGames = roster.length / playersPerGame

	const containsSimultaneousGames = rounds && rounds.reduce((maxGames, round) => Math.max(maxGames, round.length), 0) > 1

	if (containsSimultaneousGames) {

		return (
			<div className="trials-roster-rounds">
				{
					rounds.map((games, index) => (
						<div className="trials-roster-round" key={index}>
							<h2 className="trials-roster-round-title">
								ROUND {index + 1}
							</h2>
							<div className="trials-roster-games" key={index}>
								{
									games.map((game, index) =>
										<DisplayTrialsGame
											key={game}
											roster={roster}
											gameIndex={game}
											court={index}
										/>
									)
								}
							</div>
						</div>
					))
				}
			</div>
		)
	}

	return (
		<div className="trials-roster-games">
			{
				new Array(numGames).fill(0).map((zero, index) =>
					<DisplayTrialsGame
						key={index}
						roster={roster}
						gameIndex={index}
					/>
				)
			}
		</div>
	)
}

const DisplayTrialsGame = props => {
	const { roster, gameIndex, court } = props

	if (!roster)
		return null

	const offset = gameIndex * playersPerGame

	return (
		<div className="trials-roster-game fixed-element">
			{
				court !== undefined && (
					<div className="trials-roster-court-number">
						COURT {court + 1}
					</div>
				)
			}
			<div className="trials-roster-game-title">
				Game # {gameIndex + 1}
			</div>
			{
				fieldPositions.map((position, index) => (
					<div className="trials-roster-game-line" key={index}>
						<div className="cell cell-player trials-roster-game-player">
							{roster[offset + index]}
						</div>
						<div className={`cell cell-position cell-position-${position}`}>
							{position}
						</div>
						<div className="cell cell-player trials-roster-game-player">
							{roster[offset + playersPerTeam + index]}
						</div>
					</div>
				))
			}
		</div>
	)
}

const ExtraRosterInfo = props => {
	const { players, roster, preferences, numConsecutiveGames, onFillSlots, onEliminateDuplicatePositions, onEliminateFillIns } = props

	if (!roster)
		return null

	const emptySlots = roster.length - roster.filter(v => v).length

	let emptySlotsContent
	if (emptySlots) {
		emptySlotsContent = (
			<FadeText className="info-box trials-empty-slots">
				<FaCrow />
				<div>
					<div>
						<b>Empty Slots</b>: {emptySlots} roster positions are not filled.
					</div>
					{
						players.length >= playersPerGame ? (
							<p>
								<button
									type="button"
									onClick={onFillSlots}
								>
									Assign Players to Empty Slots
								</button>
							</p>
						) : null
					}
				</div>
			</FadeText>
		)
	}

	let fillIns = { }
	roster.forEach((playerName, index) => {
		if (playerName) {
			const positionIndex = index % fieldPositions.length
			if (!preferences[playerName]?.[positionIndex]) {
				fillIns[playerName] = true
			}
		}
	})

	let fillInsContent
	if (Object.keys(fillIns).length) {
		fillInsContent = (
			<div className="info-box trials-fill-ins">
				<FaHandsHelping />
				<div>
					<div>
						<b>Fill-ins</b>:
						{' '}
						{
							joinListStrings(Object.keys(fillIns).map(name => (
								<span className="cell cell-player" key={name}>
									{name}
								</span>
							)))
						}
						{' '}
						play positions they didn't nominate.
					</div>
					<p>
						<button
							type="button"
							onClick={onEliminateFillIns}
						>
							Eliminate Fill-ins
						</button>
					</p>
				</div>
			</div>
		)
	}

	const data = parseRosterTrials({ players, roster })
	const repeatingPlayers = Object.keys(data).filter(player => {
		const myPositions = data[player].filter(v => v)
		const myUniquePositions = [...new Set(myPositions)]
		return myPositions.length !== myUniquePositions.length
	})

	let repeatingPlayersContent
	if (repeatingPlayers.length) {
		repeatingPlayersContent = (
			<div className="info-box trials-repeating-players">
				<FaStreetView />
				<div>
					<div>
						<b>Repeat Positions</b>:
						{' '}
						{
							joinListStrings(repeatingPlayers.map(name => (
								<span className="cell cell-player" key={name}>
									{name}
								</span>
							)))
						}
						{' '}
						play the same position more than once.
					</div>
					<p>
						<button
							type="button"
							onClick={onEliminateDuplicatePositions}
						>
							Eliminate Repeat Positions
						</button>
					</p>
				</div>
			</div>
		)
	}

	let consecutiveGamesContent
	if (numConsecutiveGames) {
		consecutiveGamesContent = (
			<FadeText className="info-box trials-consecutive-games">
				<FaGrinBeamSweat />
				<div>
					<b>Back-to-Back Games</b>: Players are rostered in consecutive rounds {numConsecutiveGames} times.
				</div>
			</FadeText>
		)
	}

	return (
		<div className="trials-extra-roster-info">
			{repeatingPlayersContent}
			{emptySlotsContent}
			{fillInsContent}
			{consecutiveGamesContent}
		</div>
	)
}

const DisplayTrialsPlayerRoster = props => {
	const { roster, rounds, players, available, playerRoster } = props

	if (!roster)
		return null

	const numGames = roster.length / playersPerGame

	if (numGames > 12) {
		return (
			<p>
				This view is only available when you have 12 or fewer games.
			</p>
		)
	}

	const haveRounds = rounds.length !== numGames

	return (
		<div id="board" className="grid trials-player-roster">
			<GridPlayers
				players={players}
				editMode={false}
				isSingleRoster={true}
				isTrials={false}
				extraHeader={haveRounds}
				available={available}
			/>
			{
				new Array(numGames).fill(0).map((zero, index) =>
					<DisplayTrialsPlayerRosterGame
						key={index}
						gameIndex={index}
						players={players}
						playerRoster={playerRoster}
						rounds={haveRounds ? rounds : null}
					/>
				)
			}
		</div>
	)
}

const DisplayTrialsPlayerRosterGame = props => {
	const { gameIndex, players, playerRoster, rounds } = props

	let round
	let amFirstGame
	if (rounds) {
		for (round = 0; round < rounds.length; round += 1) {
			const i = rounds[round].indexOf(gameIndex)
			if (i !== -1) {
				amFirstGame = i === 0
				break
			}
		}
	}

	return (
		<div className="column column-positions column-preferences">
			{
				rounds ? (
					<GridCell type={'header-round' + (amFirstGame ? ' header-round-first-game' : '')} content={amFirstGame ? `R${round+1}` : ''} />
				) : null
			}
			<GridCell type="header" content={gameIndex + 1} />
			{
				players.map((player, index) =>
					<FixedItem
						key={index}
						isAvailable={true}
					>
						<GridCellPosition
							i={index}
							content={playerRoster[player][gameIndex] || BENCHED}
							richContent={playerRoster[player][gameIndex]}
							player={player}
							isAvailable={true}
						/>
					</FixedItem>
				)
			}
		</div>
	)
}

const DisplayTrialsPlayerSchedule = props => {
	const { rounds, players, playerRoster } = props

	if (!rounds || !players || !playerRoster)
		return null

	const gameInfo = getGameInfo(rounds)

	const haveRounds = rounds.length !== Object.keys(gameInfo).length

	const grid = new Array(players.length * 2)

	players.forEach((player, index) => {
		const offset = index * 2
		grid[offset] = (
			<div key={offset} className="trials-player-schedule-name">
				{player}
			</div>
		)
		grid[offset + 1] = (
			<div key={offset + 1} className="trials-player-schedule-games">
				{
					playerRoster[player].map((position, gameIndex) => (
						<div key={gameIndex}>
							<span className={`cell-position cell-position-${position}`}>
								{position}
							</span>
							{
								haveRounds ? (
									<span className="trials-player-schedule-round">
										Round {gameInfo[gameIndex].round}
									</span>
								) : null
							}
							{
								haveRounds ? (
									<span className="trials-player-schedule-court">
										Court {gameInfo[gameIndex].court}
									</span>
								) : null
							}
							<span className="trials-player-schedule-game">
								Game # {gameIndex + 1}
							</span>
						</div>
					))
				}
			</div>
		)
	})

	return (
		<div className="trials-player-schedule">
			{grid}
		</div>
	)
}

//
// If we have multiple courts, generate trials rosters many times until we find one
// that permits the fewest number of rounds.
//
const multiPlanRoster = props => {
	const { onProgress, preferences, cumulativePreferences, numCourts, numMinGamesPerPlayer } = props

	const maxAttempts = 1100
	const reportProgressEvery = 2

	const idealNumbers = calculateIdealNumbers({
		cumulativePreferences,
		preferences,
		numCourts,
		numMinGamesPerPlayer,
	})

	const { numberOfGames, numberOfRounds } = idealNumbers

	// We will stop generating rosters if:
	// - we have at least one roster and only 1 numCourts
	// - we have a roster where numRounds === maxPlayerGames
	// - we have tried a bunch of times

	const myProps = {
		...props,
		numberOfGames,
	}

	let count = 0
	let failureCount = 0
	let lastReportedProgress = 0
	let consecutiveGamesFailures = 0
	let chosen

	const onSingleProgress = data => {

		count += 1

		if (data.ready) {

			data.rounds = calculateRounds({
				numCourts,
				roster: data.best.roster,
			})

			// console.log(count, data.rounds.length, 'rounds')

			if (data.placementFailures) {
				// console.log("Skipping due to", data.placementFailures, 'placementFailures.')
				failureCount += 1
			} else if (!chosen || data.rounds.length < chosen.rounds.length || data.rounds.length === numberOfRounds) {

				//
				// We have a good candidate.
				//

				optimizeRounds({
					chosen: data,
					players: Object.keys(preferences),
				})

				console.log("After optimizeRounds:", data.numConsecutiveGames, 'consecutive games and', data.rounds.length, 'rounds')
				if (data.numConsecutiveGames) {
					consecutiveGamesFailures += 1
				}

				if (!chosen || data.rounds.length < chosen.rounds.length || (data.rounds.length === chosen.rounds.length && data.numConsecutiveGames < chosen.numConsecutiveGames)) {
					chosen = data
				}
			}

			let isIdeal = chosen && (numCourts === 1 || chosen.rounds.length === numberOfRounds) && !chosen.numConsecutiveGames
			if (count > maxAttempts) {
				// We've tried for a while; this will do.
				console.log("Reached maxAttempts")
				isIdeal = true
			} else if (chosen && chosen.rounds.length === numberOfRounds && (consecutiveGamesFailures > 50 || Object.keys(preferences).length <= playersPerGame)) {
				console.log("Can't get to 0 consecutiveGames")
				isIdeal = true
			}

			if (chosen && isIdeal) {

				// console.log('chosen', chosen)

				onProgress({
					progress: {
						value: 100,
					},
					...chosen
				})

			} else if (count > maxAttempts) {
				//
				// No 'chosen' but we've tried for a long time
				//
				console.error('Failed to compute roster')
				onProgress({
					ready: true,
					progress: {
						value: 100,
						noneChosen: true,
					},
				})

			} else {
				//
				// Check for impossible rostering
				//
				if (!chosen && failureCount > 50) {
					console.error('50 attempts with all placementFailures - must be dodgy! Increasing allowable number of games.')
					myProps.numberOfGames = myProps.numberOfGames + 1
					failureCount = 0
				}

				const progress = Math.min(100,
					// Start at 10% progress
					10 +
					// For count 0-10, progress 10% - 20%
					Math.min(10, count) +
					// For count 11-70, progress is 20% - 45%
					(Math.max(0, Math.min(60, count - 10) * 0.5)) +
					// Thereafter, progress is 45% - 100%
					Math.max(0, (65 * (count - 70) / (maxAttempts - 70)))
				)

				if (progress - lastReportedProgress > reportProgressEvery) {
					onProgress({
						progress: {
							value: progress,
							numGames: myProps.numberOfGames,
							numRounds: chosen && chosen.rounds.length,
							numConsecutiveGames: chosen ? chosen.numConsecutiveGames : 0,
							noneChosen: chosen ? false : true,
						},
					})
					lastReportedProgress = progress
				}

				setTimeout(() =>
					planRoster({
						...myProps,
						onProgress: onSingleProgress,
					})
					, count < 100 ? 34 : 0)
			}
		}
	}

	planRoster({
		...myProps,
		onProgress: onSingleProgress,
	})
}


const planRoster = props => {
	const { onProgress, preferences, numberOfGames, numMinGamesPerPlayer, options } = props

	// console.log('cumulativePreferences', cumulativePreferences)

	const roster = new Array(numberOfGames * playersPerGame)

	// console.log('roster', roster)

	const shuffledPlayers = Object.keys(preferences)
	shuffle(shuffledPlayers)

	const noEmptySlots = shuffledPlayers.length >= playersPerGame && options.noEmptySlots

	let placementFailures = 0

	//
	// Phase 1: Allocate players who have nominated specific positions to play.
	//
	fieldPositions.forEach((positionName, positionIndex) => {

		//
		// Build an object that contains all of the roster indices that
		// point to this position.
		//
		const freeSlots = { }
		for (let gameNumber = 0; gameNumber < numberOfGames; gameNumber += 1) {
			for (let teamOffset = 0; teamOffset <= 1; teamOffset += 1) {
				const offset = gameNumber * playersPerGame + teamOffset * playersPerTeam + positionIndex
				freeSlots[offset] = true
			}
		}

		shuffledPlayers.forEach(player => {
			if (preferences[player][positionIndex]) {
				// console.log(player, 'wants to play', positionName)

				// If a player has only nominated this one position, they must play every game here, so let's
				// set that up right away.
				let minNumberOfGamesInThisPosition = 1
				if (options.noFillIns && preferences[player].reduce((total, n) => total + n, 0) === 1) {
					minNumberOfGamesInThisPosition = numMinGamesPerPlayer
				}

				for (let i = 0; i < minNumberOfGamesInThisPosition; i += 1) {

					let successfullyPlacedPlayer = false

					Object.keys(freeSlots).forEach(slot => {
						if (!successfullyPlacedPlayer) {

							const gameNumber = Math.floor(slot / playersPerGame)

							if (!gameContainsPlayer({ roster, gameNumber, player })) {

								roster[slot] = player
								delete freeSlots[slot]
								successfullyPlacedPlayer = true

								// console.log('Placed in game #', gameNumber + 1)
							}
						}
					})

					if (!successfullyPlacedPlayer) {
						//
						// We iterated through every game but there were none that had free slots
						// AND didn't already have this player playing.
						//

						//
						// Go through our free slots - of which there must be at least 1 - and see if we can
						// find a player in a different game who could swap into it to free up a space for our
						// current player.
						//
						Object.keys(freeSlots).forEach(slot => {

							// console.log("slot", slot, successfullyPlacedPlayer)
							if (!successfullyPlacedPlayer) {

								const destinationGameNumber = Math.floor(slot / playersPerGame)

								for (let sourceGameNumber = numberOfGames - 1; sourceGameNumber >= 0 && !successfullyPlacedPlayer; sourceGameNumber -= 1) {

									//
									// Is this a game where we could put our player instead?
									//
									// (Note: for really tricky rosters, it might be worth coming back and trying this stage
									// again but skipping this check, to see if we can shuffle things around enough to eventually
									// open up space for our player.)
									//

									if (!gameContainsPlayer({ roster, gameNumber: sourceGameNumber, player: player })) {

										//
										// Yes: our player could be placed into this game. So let's see if we can swap someone out
										// to make room for them.
										//

										for (let teamOffset = 0; teamOffset <= 1 && !successfullyPlacedPlayer; teamOffset += 1) {
											const swapPartnerOffset = sourceGameNumber * playersPerGame + teamOffset * playersPerTeam + positionIndex
											const swapPartner = roster[swapPartnerOffset]
											if (!gameContainsPlayer({ roster, gameNumber: destinationGameNumber, player: swapPartner })) {
												//
												// We've found a player who can be placed into the destination game instead! Yay.
												// Let's go ahead and swap them.
												//
												roster[slot] = swapPartner
												roster[swapPartnerOffset] = player
												successfullyPlacedPlayer = true
												delete freeSlots[slot]
												// console.log("Swapping out", swapPartner, "from game #", sourceGameNumber + 1, "to make room for ", player)
											}
										}
									}
								}
							}
						})

						if (!successfullyPlacedPlayer) {
							//
							// This can happen if a player can only be placed by moving them out of a
							// game they're already in. For example, we place Max in GK first, and
							// then we can't find anywhere for him to play GD unless we swap him out
							// of GK in that first game.
							//
							// I can't think of an easy way to fix this algorithmically, so I think
							// I'll just retry until there are no errors.
							//
							// This can also occur if there's simply no way to fit in all the players.
							// So if we've already tried for a while, create a new game.
							//
							console.error("STILL failed to place player", player)
							placementFailures += 1
						}
					}
				}
			}
		})
	})

	//
	// Phase 2: Ensure players have reached their minimum number of games,
	// by placing them into any free slot.
	//
	// It's important to use shuffled players here.
	//
	// If the option 'noRepeatPositions' is enabled, make sure we don't put
	// players in the same position twice.
	//
	// If the option 'noFillIns' is enabled, make sure they ONLY play in their
	// nominated position.
	//

	const gamesPerPlayer = shuffledPlayers.reduce((map, player) => {
		map[player] = 0
		return map
	}, { })

	roster.forEach(player => gamesPerPlayer[player] += 1)

	Object.keys(gamesPerPlayer).forEach(player => {
		const shortfall = Math.max(0, numMinGamesPerPlayer - gamesPerPlayer[player])
		if (shortfall) {
			for (let n = 0; n < shortfall; n += 1) {

				let successfullyPlacedPlayer = false

				//
				// If options.noRepeatPositions is set, build a list of existing
				// positions, so we don't place this player in the same position twice.
				// e.g. is already playing GS and GA: { 0: true, 1: true }
				//
				const existingPositions = options.noRepeatPositions ?
					roster.reduce((map, thisPlayer, slot) => {
						if (thisPlayer === player) {
							map[slot % fieldPositions.length] = true
						}
						return map
					}, { })
					: { }
				// console.log("Existing positions for", player, existingPositions)

				//
				// Look for any free slot to put this player into.
				//
				for (let gameNumber = 0; gameNumber < numberOfGames && !successfullyPlacedPlayer; gameNumber += 1) {

					if (!gameContainsPlayer({ roster, gameNumber, player })) {

						for (let i = 0; i < playersPerGame && !successfullyPlacedPlayer; i += 1) {
							const slot = gameNumber * playersPerGame + i
							if (!roster[slot] && (!options.noFillIns || preferences[player][i]))  {
								//
								// This slot is empty
								//
								const position = slot % fieldPositions.length
								if (!existingPositions[position]) {
									roster[slot] = player
									successfullyPlacedPlayer = true
									// console.log("Min games: placing", player, gameNumber, slot)
								} else {
									// console.log("Didn't put", player, "in free slot because of position duplicateion.")
								}
							}
						}
					}
				}

				if (!successfullyPlacedPlayer) {
					//
					// We couldn't find any games with a free slot that this player wasn't already in.
					//
					// So let's see if we can shuffle players out from other games to make room for
					// this player.
					//

					//
					// Build an object that contains all of the roster indices that don't
					// have anyone in them yet -- and, if we sent options.noRepeatPositions,
					// which this player isn't already rostered in.
					//
					const freeSlots = [ ]
					for (let i = 0; i < roster.length; i += 1) {
						if (!roster[i]) {
							const positionIndex = i % fieldPositions.length
							if (!existingPositions[positionIndex]) {
								freeSlots.push(i)
							}
						}
					}

					for (let gameNumber = 0; gameNumber < numberOfGames && !successfullyPlacedPlayer; gameNumber += 1) {

						if (!gameContainsPlayer({ roster, gameNumber, player })) {

							//
							// Our player isn't in this game, so can we swap someone out from it to make room
							// for her?
							//

							for (let i = 0; i < playersPerGame && !successfullyPlacedPlayer; i += 1) {
								const positionOffset = i % fieldPositions.length
								const swapPartnerOffset = gameNumber * playersPerGame + i
								const swapPartner = roster[swapPartnerOffset]

								// No good if we won't accept Fill-Ins
								if (options.noFillIns && !preferences[player][positionOffset])  {
									continue
								}

								//
								// See if any of our unfilled slots are for positions that this swapParter
								// is currently in - because we don't want to change her position.
								//
								for (let j = 0; j < freeSlots.length && !successfullyPlacedPlayer; j += 1) {
									const slot = freeSlots[j]
									if (slot % fieldPositions.length === positionOffset) {
										//
										// There is a free slot of the right position. But is she already
										// in this game?
										//
										if (!gameContainsPlayer({
											player: swapPartner,
											gameNumber: Math.floor(slot / playersPerGame),
											roster,
										})) {
											//
											// swapPartner can swap into this game, leaving room for
											// player to take her place.
											//
											roster[slot] = swapPartner
											roster[swapPartnerOffset] = player
											successfullyPlacedPlayer = true
											// console.log('swapped', player, swapPartner)
										}
									}
								}
							}
						}
					}

					if (!successfullyPlacedPlayer) {
						console.error("Couldn't find place for flexible player", player, "even with swapping.")
						placementFailures += 1
					}

				}
			}
		}
	})

	//
	// If required, fill empty slots.
	//

	if (noEmptySlots) {
		placementFailures += fillEmptySlots({
			players: Object.keys(preferences),
			roster,
			options,
		})
	}

	//console.log("Finished!", roster)
	// console.table(roster)

	onProgress({
		ready: true,
		progress: {
			value: 100,
		},
		best: {
			roster,
			score: 0,
		},
		placementFailures,
	})
}

const calculateRounds = props => {
	const { numCourts, roster } = props

	if (!roster)
		return null

	const rounds = [ ]

	const numberOfGames = roster.length / playersPerGame

	for (let gameNumber = 0; gameNumber < numberOfGames; gameNumber += 1) {
		//
		// Figure out a round for this game.
		//
		let successfullyPlacedGame = false
		for (let round = 0; round < rounds.length && !successfullyPlacedGame; round += 1) {

			const roundGames = rounds[round]
			if (roundGames && roundGames.length < numCourts) {

				const offset = gameNumber * playersPerGame
				const game = roster.slice(offset, offset + playersPerGame)

				let mayPlaceGame = true

				roundGames.forEach(otherGameNumber => {
					if (mayPlaceGame) {
						const otherOffset = otherGameNumber * playersPerGame
						const otherGame = roster.slice(otherOffset, otherOffset + playersPerGame)
						const overlappingPlayers = game.filter(v => v && otherGame.includes(v))
						if (overlappingPlayers.length) {
							// console.log(gameNumber, otherGameNumber, 'may not be simultaneous', overlappingPlayers.length, overlappingPlayers)
							mayPlaceGame = false
						}
					}
				})

				if (mayPlaceGame) {
					rounds[round].push(gameNumber)
					successfullyPlacedGame = true
					// console.log("Placing", gameNumber, "into round", round)
				}
			}
		}
		if (!successfullyPlacedGame) {
			rounds.push([ gameNumber ])
			// console.log("Forced to create new round for ", gameNumber)
		}
	}

	// console.log('rounds', rounds, numCourts)

	return rounds
}

function optimizeRounds({ chosen, players }) {

	//
	// Before we send this back, if we have multiple games per round, rearrange the games
	// so that they're in a nice order, e.g. we will see "Round 1" with "Game 1" and "Game 2"
	// (not "Game 1" and "Game 7").
	//
	const { roster } = chosen.best
	const numGames = roster.length / playersPerGame

	if (chosen.rounds.length < numGames) {

		//
		// We have fewer rounds than games, so let's tweak!
		//
		// First, order the games so that Round 1 contains the earliest games.
		//

		let roundNumber = 0
		let roundOffset = 0
		const newRoster = [ ]
		const newRounds = [ ]
		for (let i = 0; i < numGames; i += 1) {
			//
			// Step through games in order that they will appear in reality
			// (e.g. first game of Round 1, then second game of Round 1), and
			// build a new roster in that order.
			//
			const gameNumber = chosen.rounds[roundNumber][roundOffset]
			newRoster.push(...roster.slice(gameNumber * playersPerGame, gameNumber * playersPerGame + playersPerGame))
			newRounds[roundNumber] = newRounds[roundNumber] || [ ]
			newRounds[roundNumber].push(i)

			roundOffset += 1
			if (roundOffset >= chosen.rounds[roundNumber].length) {
				roundNumber += 1
				roundOffset = 0
			}
		}
		chosen.best.roster = newRoster
		chosen.rounds = newRounds
	}

	//
	// Now try to reshuffle to avoid players having to play consecutive rounds.
	//
	// We should do this even with numCourts === 1.
	//
	const NUM_ATTEMPTS = 100

	let consecutiveBest
	let consecutiveCount = 0
	while ((!consecutiveBest || chosen.numConsecutiveGames > 0) && consecutiveCount < NUM_ATTEMPTS) {
		consecutiveCount += 1
		const { numConsecutiveGames, roster } = eliminateConsecutiveGames({
			players,
			rounds: chosen.rounds,
			roster: chosen.best.roster.slice(),
		})
		if (!consecutiveBest || numConsecutiveGames < chosen.numConsecutiveGames) {
			consecutiveBest = roster
			chosen.numConsecutiveGames = numConsecutiveGames
		}
	}
	chosen.best.roster = consecutiveBest
	console.log("BEST has", chosen.numConsecutiveGames, 'consecutive games after', consecutiveCount, 'attempts with', chosen.rounds.length, 'rounds')
}

//
// For each player, attempt to stagger their games so that they play
// every 2nd round, e.g. rounds 1, 3, & 5, or rounds 2 & 4.
//
// 1. Figure out current round numbers
// 2. When there is a problem, attempt to shift first round, then the second.
// 3. Calculate valid rounds: e.g. for a player with [ 0, 4, 5 ], with a max.
//    round of 8, valid candidates would be [ 2, 3, 7, 8 ] for the 4 round
//    and [ 2, 6, 7, 8 ] for the 5 round.
// 4. For each candidate round, see if we can swap with a player from any game
//    in that round. E.g. if we're trying to move our player from Round 4, and
//    we're looking at a potential swap partner in Round 2:
//    - swap partner must not have a game in Rounds 3, 4, or 5
// 5. If that's okay, make the swap and update roundsPlayed for both players.
//
const eliminateConsecutiveGames = props => {
	const { rounds, roster, players } = props

	// console.log('calculateConsecutiveGames', rounds, roster, players)

	const gameRounds = { }
	rounds.forEach((games, roundNumber) =>
		games.forEach(gameNumber =>
			gameRounds[gameNumber] = roundNumber
		)
	)

	// console.log('gameRounds', gameRounds)

	const playerRoster = parseRosterTrials({
		players,
		roster,
	})

	// console.log('playerRoster', playerRoster)

	const roundsPlayed = { }
	players.forEach(player => {
		roundsPlayed[player] = [ ]
		playerRoster[player].forEach((position, gameNumber) => {
			if (position) {
				const roundNumber = gameRounds[gameNumber]
				roundsPlayed[player].push(roundNumber)
			}
		})
	})

	// console.log('roundsPlayed', roundsPlayed)

	let numConsecutiveGames = 0

	const shuffledPlayers = players.slice()
	shuffle(shuffledPlayers)

	shuffledPlayers.forEach(player => {
		let myNumConsecutiveGames = 0

		roundsPlayed[player].forEach(round => {

			let isConsecutiveRound = false

			roundsPlayed[player].forEach(otherRound => {
				if (round === otherRound + 1 || round === otherRound - 1) {
					isConsecutiveRound = true
				}
			})

			//console.log(player, round, isConsecutiveRound)

			if (isConsecutiveRound) {
				//
				// Conflict found! This player has games in consecutive rounds.
				//


				//
				// Figure out the slot # & position of the player in this round.
				//
				let slot
				let positionIndex
				let position
				rounds[round].forEach(gameNumber => {
					if (slot === undefined) {
						for (let i = 0; i < playersPerGame; i += 1) {
							const offset = gameNumber * playersPerGame + i
							if (roster[offset] === player) {
								//
								// Found it!
								//
								slot = offset
								positionIndex = offset % fieldPositions.length
								position = fieldPositions[positionIndex]
								break
							}
						}
					}
				})

				// console.log(player, 'round', r, 'game', Math.floor(slot / playersPerGame), 'slot', slot, 'position', position)

				if (slot === undefined) {
					console.error("Failed to locate slot for", player, 'in round', round, ' (games:', rounds[round].join(' + '), '). Roster:', roster)
					rounds[round].forEach(gameNumber => {
						console.log(gameNumber, player, "should be in here somewhere:", gameNumber, roster.slice(gameNumber * playersPerGame, (gameNumber + 1) * playersPerGame).join(', '))
					})
				}

				//
				// Figure out which rounds we could move this player into.
				//
				const roundCandidateIndexes = { }
				Array(rounds.length).fill().forEach((v, index) => roundCandidateIndexes[index] = true)
				roundsPlayed[player].forEach(roundPlayed => {
					if (roundPlayed !== round) {
						delete roundCandidateIndexes[roundPlayed]
						delete roundCandidateIndexes[roundPlayed + 1]
						delete roundCandidateIndexes[roundPlayed - 1]
					}
				})

				const roundCandidates = Object.keys(roundCandidateIndexes).map(n => Number(n))

				// console.log(player, 'round candidates for moving out of', r, roundCandidates)

				let haveResolvedConflict = false

				roundCandidates.forEach(roundCandidate => {
					rounds[roundCandidate].forEach(gameNumber => {
						if (!haveResolvedConflict) {
							const positionIndex = queryPositionIndex(position)
							for (let team = 0; team <= 1 && !haveResolvedConflict; team += 1) {
								const swapPartnerIndex = gameNumber * playersPerGame + team * playersPerTeam + positionIndex
								const swapPartner = roster[swapPartnerIndex]
								const swapPartnerRounds = roundsPlayed[swapPartner]

								//
								// Note: swapPartner may be undefined (empty slot)
								//
								// console.log('consider swap partner', swapPartner, swapPartnerIndex, roundCandidate, gameNumber)
								let isViable = true

								if (swapPartnerRounds) {
									swapPartnerRounds.forEach(swapPartnerRound => {
										if (swapPartnerRound >= round - 1 && swapPartnerRound <= round + 1) {
											isViable = false
										}
									})
								}

								if (isViable) {
									// console.log("Found valid swap:", position, player, 'round', r, 'slot', slot, "->", swapPartner, 'round', roundCandidate, 'game', gameNumber, 'slot', swapPartnerIndex)
									//
									// We've found a player who can be placed into the destination game instead! Yay.
									// Let's go ahead and swap them.
									//
									roster[slot] = swapPartner
									roster[swapPartnerIndex] = player

									//
									// Update roundsPlayed
									//
									roundsPlayed[player] = roundsPlayed[player].map(r => r === round ? roundCandidate : r)
									// console.log('roundsPlayed[' + player + ']', roundsPlayed[player].join(' + '))

									if (roundsPlayed[swapPartner]) {
										roundsPlayed[swapPartner] = roundsPlayed[swapPartner].map(r => r === roundCandidate ? round : r)
										// console.log('roundsPlayed[' + swapPartner + ']', roundsPlayed[swapPartner].join(' + '))
									}

									haveResolvedConflict = true

								} else {
									// console.log("non-viable", swapPartner)
								}
							}
						}
					})
				})

				if (!haveResolvedConflict) {
					// console.error("Failed to resolve conflict for", player)
					myNumConsecutiveGames += 1
				}
			}
		})

		// console.log(player, 'consecutive games:', myNumConsecutiveGames, 'playing these rounds:', roundsPlayed[player].join(' '))

		numConsecutiveGames += myNumConsecutiveGames
	})

	// console.log('total numConsecutiveGames', numConsecutiveGames)

	return {
		numConsecutiveGames,
		roster,
	}
}


function gameContainsPlayer({ roster, gameNumber, player }) {
	for (let i = gameNumber * playersPerGame; i < (gameNumber + 1) * playersPerGame; i += 1) {
		if (roster[i] === player) {
			return true
		}
	}
	return false
}

const ExplainConstraints = props => {
	const { players, preferences, cumulativePreferences, numCourts, numMinGamesPerPlayer, options } = props

	const idealNumbers = calculateIdealNumbers({
		cumulativePreferences,
		preferences,
		numCourts,
		numMinGamesPerPlayer,
	})

	const { maxPlayerGames, maxPreferenceGames, numberOfGames, numberOfRounds, minPositionGames } = idealNumbers

	let constraintReason
	let roundConstraintReason

	const maxPlayersString = () => {
		const maxPlayers = players.filter(player => preferences[player] && preferences[player].filter(v => v).length === maxPlayerGames).map(name => (
			<span className="cell cell-player" key={name}>
				{name}
			</span>
		))
		let text
		if (maxPlayers.length === players.length) {
			text = 'all players have'
		} else if (maxPlayers.length >= 5) {
			text = `${maxPlayers.length} players have`
		} else {
			text = (
				<span>
					{joinListStrings(maxPlayers)}
					{' '}
					{ maxPlayers.length === 1 ? 'has' : 'have'}
				</span>
			)
		}
		return (
			<span>
				{text}
			</span>
		)
	}

	if (numMinGamesPerPlayer === numberOfGames) {
		//
		// Constrained by numMinGamesPerPlayer
		//
		constraintReason = (
			<span>
				you set that as your minimum number of games per player
			</span>
		)

	} else if (Math.ceil(players.length * numMinGamesPerPlayer / playersPerGame) === numberOfGames) {
		//
		// Constrained by numMinGamesPerPlayer
		//
		const plural = numMinGamesPerPlayer === 1 ? '' : 's'

		constraintReason = (
			<span>
				you have {players.length} players who must each play {numMinGamesPerPlayer} game{plural}
			</span>
		)

	} else if (maxPlayerGames === numberOfGames) {
		//
		// Constrained by player
		//
		constraintReason = (
			<span>
				{maxPlayersString()} {maxPlayerGames} different positions
			</span>
		)

	} else if (maxPreferenceGames === numberOfGames) {
		//
		// Constrained by position
		//
		const maxPositions = cumulativePreferences.map((n, index) => Math.ceil(n / 2) === maxPreferenceGames ? index : null).filter(v => v !== null)

		let positionList
		if (maxPositions.length === fieldPositions.length) {
			positionList = <span>all positions</span>
		} else {
			positionList = (
				<span>
					{
						joinListStrings(maxPositions.map(positionIndex => fieldPositions[positionIndex]).map(position => (
							<span className={`cell cell-position cell-position-${position}`} key={position}>
								{position}
							</span>
						)))
					}
				</span>
			)
		}

		constraintReason = (
			<span>
				{positionList}
				{' '}
				{maxPositions.length === 1 ? 'is' : 'are'}
				{' '}
				selected by
				{' '}
				{maxPreferenceGames * 2 - 1}+ players
			</span>
		)

	} else if (minPositionGames === numberOfGames) {
		//
		// Constrained by a combination of numMinGamesPerPlayer
		//
		const excessPlayers = players.filter(player => preferences[player].filter(v => v).length > numMinGamesPerPlayer).map(name => (
			<span className="cell cell-player" key={name}>
				{name}
			</span>
		))
		let text
		if (excessPlayers.length >= 5) {
			text = `${excessPlayers.length} players`
		} else {
			text = (
				<span>
					{joinListStrings(excessPlayers)}
				</span>
			)
		}

		const plural = numMinGamesPerPlayer === 1 ? '' : 's'
		constraintReason = (
			<span>
				you have {players.length} players who must each play {numMinGamesPerPlayer} game{plural} as well as {text} who must play {numMinGamesPerPlayer + 1} or more
			</span>
		)

	} else {
		console.error("Should not reach here!")
	}

	if (numCourts > 1) {
		roundConstraintReason = (
			<span>
				You will play at least
				{' '}
				<span className="cell cell-player">
					{numberOfRounds}
				</span>
				{' '}
				rounds because
				{' '}
				{
					numberOfRounds === maxPlayerGames ? (
						<span>
							{maxPlayersString()} {numberOfRounds} different positions
						</span>
					) : (
						<span>
							of your number of available courts
						</span>
					)
				}
				.
			</span>
		)
	}

	let noEmptySlotsReason
	const numEmptySlots = playersPerGame - players.length
	if (options.noEmptySlots && numEmptySlots > 0) {
		noEmptySlotsReason = (
			<span>
				You will have <span className="cell cell-player">{numEmptySlots}</span> empty slots per game because you have fewer than {playersPerGame} players.
			</span>
		)
	}

	let noFillInsConflict
	const numMissingNominations = Object.values(preferences).filter(arr => !arr.some(n => n)).length
	if (numMissingNominations && numMinGamesPerPlayer && options.noFillIns) {
		noFillInsConflict = (
			<span>
				There's no way for players to have <span className="cell cell-player">{numMinGamesPerPlayer}</span> min. games
				with <b>No Fill-ins</b> enabled because <span className="cell cell-player">{numMissingNominations}</span> players
				have no nominated position.
			</span>
		)
	}

	return (
		<div className="info-box trials-explain-constraints">
			<TiLightbulb />
			<div className="explain-container">
				<FadeText className="explain-block">
					You will play at least
					{' '}
					<span className="cell cell-player">
						{numberOfGames}
					</span>
					{' '}
					games because {constraintReason}.
					{' '}
				</FadeText>
				<FadeText className="explain-block">
					{roundConstraintReason}
				</FadeText>
				<FadeText className="explain-block">
					{noEmptySlotsReason}
				</FadeText>
				<FadeText className="explain-block">
					{noFillInsConflict}
				</FadeText>
			</div>
		</div>
	)
}

function calculateIdealNumbers({ cumulativePreferences, preferences, numCourts, numMinGamesPerPlayer }) {

	//
	// Calculate the number of times each position has been requested. If we
	// have 12 players wanting to play GS, that means we need at least 6 games.
	//
	const maxPreferenceNumber = cumulativePreferences.reduce((max, n) => Math.max(max, n), 0)
	const maxPreferenceGames = Math.ceil(maxPreferenceNumber / 2)

	//
	// Calculate the maximum number of positions requested by any individual player.
	// If we have a player wanting to play 7 positions, we need at least 7 games.
	//
	const maxPlayerGames = Object.values(preferences).reduce((max, prefs) => Math.max(max, prefs.filter(v => v).length), 0)

	//
	// Calculate the number of games that must be played due to a combination of numMinGamesPerPlayer
	// and preferences.
	//
	const totalPositionsRequired = Object.keys(preferences).reduce((total, player) => {
		const numPrefs = preferences[player].reduce((tot, n) => tot + n, 0)
		const num = Math.max(numPrefs, numMinGamesPerPlayer)
		return total + num
	}, 0)

	const minPositionGames = Math.ceil(totalPositionsRequired / playersPerGame)

	//
	// We must play at least this many games.
	//
	const numberOfGames = Math.max(maxPreferenceGames, maxPlayerGames, numMinGamesPerPlayer, minPositionGames)

	//
	// We must play at least this many rounds.
	//
	const numberOfRounds = Math.max(Math.ceil(numberOfGames / numCourts), maxPlayerGames)

	return {
		numberOfRounds,
		numberOfGames,
		maxPlayerGames,
		maxPreferenceGames,
		maxPreferenceNumber,
		minPositionGames,
		totalPositionsRequired,
	}
}

//
// Fill empty slots.
//
// Modifies 'roster' object
//
function fillEmptySlots(props) {
	const { players, roster, options } = props

	const playerRoster = parseRosterTrials({
		players,
		roster,
	})

	const emptySlots = [ ]
	for (let i = 0; i < roster.length; i += 1) {
		if (!roster[i]) {
			emptySlots.push(i)
		}
	}

	const gamesPerPlayer = Object.keys(playerRoster).reduce((map, player) => {
		map[player] = playerRoster[player].filter(v => v).length
		return map
	}, { })

	// console.log('gamesPerPlayer', gamesPerPlayer)

	//
	// Sort players from least number of games to most.
	//
	const sortedPlayers = Object.keys(gamesPerPlayer).sort((a, b) => gamesPerPlayer[a] - gamesPerPlayer[b])

	// console.log('sortedPlayers', sortedPlayers)

	let failures = 0

	emptySlots.forEach(slot => {
		const gameNumber = Math.floor(slot / playersPerGame)
		const position = fieldPositions[slot % fieldPositions.length]

		for (let i = 0; i < sortedPlayers.length; i += 1) {
			const player = sortedPlayers[i]

			if (options.noRepeatPositions && playerRoster[player].includes(position)) {
				// console.log("Not placing", player, "in repeat position", position)
				continue
			}

			if (!gameContainsPlayer({ roster, gameNumber, player })) {
				roster[slot] = player
				sortedPlayers.splice(i, 1)
				// console.log("Filled slot", slot, "with", player)
				break
			}
		}

		if (!roster[slot]) {
			failures += 1
			console.error("failed to fill slot", slot)
		}
	})

	return failures
}

function verifyData(data) {

	// console.log('verifyData', data)

	data.numCourts = Math.max(1, Math.min(99, Number(data.numCourts)))

	data.numMinGamesPerPlayer = Math.max(0, Math.min(fieldPositions.length, Number(data.numMinGamesPerPlayer)))

	data.players = [ ...new Set(data.players) ]

	if (data.players.length !== Object.keys(data.preferences).length) {
		throw Error('Length mismatch between players and preferences')
	}

	data.players.forEach(player => {
		if (!data.preferences[player]) {
			throw Error("No preferences for player: " + player)
		} else if (data.preferences[player].length !== fieldPositions.length) {
			throw Error(`Player ${player} has odd preference length`)
		}
	})

	if (!data.options || !Object.keys(data.options).length) {
		data.options = null
	}

	return data
}

function queryPositionIndex(position) {
	for (let i = 0; i < fieldPositions.length; i+=1) {
		if (fieldPositions[i] === position) {
			return i
		}
	}

	console.error("what is this position", position)
	return null
}

export default Trials
