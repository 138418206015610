import React from 'react'
import firebase, { db } from './firebase'
import { numPeriods, fieldPositions, BENCHED } from './definitions'

export function ucFirst(s) {
	return s.charAt(0).toUpperCase() + s.substr(1)
}

const dateOptions = {
	default: {
		month: 'short',
		day: 'numeric',
	},
	long: {
		month: 'short',
		day: 'numeric',
	},
	full: {
		weekday: 'short',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	},
}

const timeOptions = {
	long: {
		hour: 'numeric',
		minute: 'numeric',
	},
	full: {
		hour: 'numeric',
		minute: 'numeric',
	},
}

export function formatDate(t, format) {

	const myFormat = format || 'default'

	const d = new Date(t)

	const dateString = d.toLocaleDateString('en-AU', dateOptions[myFormat]).replace(/,/, '')
	const timeString = timeOptions[format] && d.toLocaleTimeString('en-AU', timeOptions[myFormat])

	if (timeString) {
		return (
			<span className="date">
				<span className="date-date">
					{dateString}
				</span>
				<span className="date-time">
					{timeString}
				</span>
			</span>
		)
	}

	return dateString
}

//
// Send a time; returns 'past', 'present' or 'future'
//
export function gameDateClass(t) {
	const secondsDiff = (t - Date.now()) / 1000

	if (secondsDiff < -86400) {
		return 'past'
	}
	if (secondsDiff < 6 * 86400) {
		return 'present'
	}
	return 'future'
}

export function generateSearchString({ section, type, item, extra }) {

	const sectionSegments = [ ]

	const defaultSegments = [ 'team', 'season' ]
	if (section) {
		defaultSegments.forEach(segment =>
			{
				if (section[segment] && (!item || type.unit !== segment)) {
					sectionSegments.push(`${segment}=${section[segment]}`)
				}
			}
		)
	}

	if (item) {
		sectionSegments.push(`${type.unit}=${item.id}`)
	}

	if (extra) {
		Object.keys(extra).forEach(key => sectionSegments.push(`${key}=${extra[key]}`))
	}

	// console.log('generateSearchString', section, type, item, sectionSegments)

	if (sectionSegments.length) {
		return '?' + sectionSegments.join('&')
	}

	return null
}

export function userCanAddItem({ user, type, team }) {

	if (user) {
		if (type && type.unit === 'team') {
			//
			// Anyone can create a team.
			//
			return true
		}
		if (team && team.managers && team.managers.includes(user.uid)) {
			//
			// Team Managers can add anything to their team.
			//
			return true
		}
	}

	return false
}

//
// You can edit items if you're a team manager.
//
export function userCanEditItem({ user, team, type }) {

	if (user) {
		//
		// User managing their own team.
		//
		if (team && team.managers && team.managers.includes(user.uid)) {
			return true
		}
		//
		// User viewing "My Teams" page
		//
		if (type && type.unit === 'team' && type.where) {
			return true
		}
	}

	return false
}

//
// You only see NetStats if you're a team manager.
//
export function userCanViewNetStats({ user, team, type }) {
	if (user) {
		//
		// User managing their own team.
		//
		if (team && team.managers && team.managers.includes(user.uid)) {
			return true
		}
	}

	return false
}

//
// Moved from Stats.js
//
export function parseData(data, includeFuture, lastGameIsBefore) {

	// console.log('parseData', data)

	//
	// Build a stats array.
	//
	// Will ignore any dummy players.
	//
	const stats = { }

	if (data.players) {
		Object.keys(data.players).forEach(playerId =>
			stats[playerId] = {
				positions: new Array(fieldPositions.length).fill(0),
				orangeman: 0,
				orangemanGames: [ ],
				absent: 0,
				absentGames: [ ],
				games: { },
				lastGame: null,
			}
		)

		const t = Date.now()

		if (data.rosters) {
			Object.keys(data.games).sort((a, b) => data.games[a].date - data.games[b].date).forEach(gameId => {
				if (data.rosters[gameId]) {
					if (includeFuture || t > data.games[gameId].date) {
						//
						// Who played in which position?
						//
						const { roster, players } = data.rosters[gameId]
						const nulled = data.rosters[gameId].nulled || [ ]
						if (roster && roster.length) {
							const numPositions = roster.length / numPeriods
							for (let qtr = 0; qtr < numPeriods; qtr += 1) {
								const offset = qtr * numPositions
								for (let i = 0; i < numPositions; i += 1) {
									if (i < fieldPositions.length && nulled.indexOf(offset + i) === -1) {
										const playerId = roster[offset + i]

										// Don't record stats if a player was assigned a position on the
										// roster but were also marked absent (e.g. team was short on players).
										if (stats[playerId] && players.includes(playerId)) {
											stats[playerId].positions[i] += 1

											//
											// Record the positions each player played in each game,
											// so we can easily access their most recent game.
											//
											if (!stats[playerId].games[gameId]) {
												stats[playerId].games[gameId] = new Array(numPeriods).fill(null)
											}
											stats[playerId].games[gameId][qtr] = i

											if (!stats[playerId].lastGame || data.games[gameId].date > data.games[stats[playerId].lastGame].date) {
												if (!lastGameIsBefore || data.games[gameId].date < lastGameIsBefore) {
													stats[playerId].lastGame = gameId
												}
											}
										}
									}
								}
							}
						}

						//
						// Orangeman!
						//
						const orangeman = data.rosters[gameId].orangeman
						if (orangeman && stats[orangeman]) {
							stats[orangeman].orangemanGames.push(gameId)
						}

						//
						// Absences
						//
						const absentPlayers = Object.assign({ }, data.players)
						data.rosters[gameId].players.forEach(player =>
							delete absentPlayers[player]
						)
						Object.keys(absentPlayers).forEach(playerId => {
							// Record this gameId under stats[playerId].absentGames
							stats[playerId].absentGames.push(gameId)
						})
					}
				}
			})
		}
	}
	// console.log('stats', stats)

	return stats
}

export function parseRoster({ roster, positions, players, playerKey, netStats, errorsFatal }) {

	if (!roster || !players)
		return null

	// console.log('parseRoster()', roster, roster.length, positions, players, players.length, netStats)

	//
	// Create initial object with a key of player names and a
	// value of an empty array.
	//
	const data = players.reduce((map, player) => {
		map[player] = [ ]
		return map
	}, { })

	if (roster.length) {

		//
		// Figure out whether any players have been added or deleted from this
		// team since the roster was made.
		//
		const playersInRoster = { }
		const deletedPlayers = [ ]
		const addedPlayers = [ ]
		const emptySlots = [ ]

		//
		// Identify any deleted players.
		//
		roster.forEach((playerName, slot) => {
			if (playerName) {
				playersInRoster[playerName] = true
				if (!data[playerName]) {
					console.log(playerName, "is in the roster but not the list of players!")
					deletedPlayers.push(playerName)
					if (errorsFatal)
						throw Error("Ghost player rostered.")
				}
			}
		})

		//
		// Remove any deleted players from the roster.
		//
		deletedPlayers.forEach(playerName => {
			for (let i = 0; i < roster.length; i += 1) {
				if (roster[i] === playerName) {
					roster[i] = ''
				}
			}
		})

		//
		// Identify any empty slots.
		//
		roster.forEach((playerName, slot) => {
			if (!playerName) {
				console.log("Slot", slot, "is empty!")
				emptySlots.push(slot)
				if (errorsFatal)
					throw Error("Empty slot.")
			}
		})

		//
		// Identify any added players (listed in `players` but not `roster`).
		//
		// If so, the fix seems to be to add a new '-' position and expand roster
		// to find a place for that player.
		//
		Object.keys(data).forEach(playerName => {
			if (!playersInRoster[playerName]) {
				console.log(playerName, "is a listed player but not in the roster!", 'players', players, 'positions', positions, 'roster', roster)
				addedPlayers.push(playerName)
				if (errorsFatal)
					throw Error("New player absent from roster.")
			}
		})

		//
		// Fill any empty slots.
		//
		if (emptySlots.length) {

			console.log(emptySlots.length, 'empty slots and', addedPlayers.length, 'added players', addedPlayers)

			// First attempt to assign new players to empty slots
			const slotsPerPeriod = roster.length / numPeriods
			addedPlayers.forEach(playerName => {
				for (let qtr = 0; qtr < numPeriods; qtr += 1) {
					for (let i = 0; i < emptySlots.length; i += 1) {
						const slot = emptySlots[i]
						if (Math.floor(slot / slotsPerPeriod) === qtr) {
							roster[slot] = playerName
							console.log("Assigned", playerName, "to empty slot", slot, 'in qtr', qtr)
							emptySlots.splice(i, 1)
							console.log("Empty slots is now", emptySlots)
							break
						}
					}
				}
			})
			addedPlayers.length = 0

			/*
			if (emptySlots.length === addedPlayers.length * numPeriods) {
				console.log("AHA a perfect match")
				emptySlots.forEach((slot, index) => {
					roster[slot] = addedPlayers[index % addedPlayers.length]
					console.log("assigned", roster[slot], 'to slot', slot)
				})
				addedPlayers.length = 0
			} else if (emptySlots.length === roster.length - (players.length * numPeriods)) {
			*/

			if (emptySlots.length) {
				console.log("Still some empty slots - probably from leftover deleted player. Removing:", emptySlots)
				for (let i = emptySlots.length - 1; i >= 0; i -= 1) {
					const slotNum = emptySlots[i]
					roster.splice(slotNum, 1)
				}
				console.log("Now roster is:", roster)
			}
		}

		//
		// Try to insert new players into the roster
		//
		if (addedPlayers.length) {
			console.log('Trying to add new players who are missing from roster:', addedPlayers)
			for (let j = 0; j < addedPlayers.length; j += 1) {
				const playerName = addedPlayers[j]
				const playersPerQtr = roster.length / numPeriods
				for (let qtr = 0; qtr < numPeriods; qtr += 1) {
					const offset = qtr * (playersPerQtr + 1)
					roster.splice(offset + playersPerQtr, 0, playerName)
				}
			}
			console.log('afterward, roster is', roster, 'positions', positions)
		}

		//
		// Build data.
		//
		for (let i = 0; i < roster.length; i += 1) {
			const qtr = Math.floor(i / positions.length)
			const player = roster[i]
			if (!data[player]) {
				//
				// Handle situation where players have been deleted since roster
				// was first created... this shouldn't happen any more, I think.
				//
				console.error('unknown player', player, 'Creating entry. But this will not work because of mismatch between players array and data object.')
				data[player] = [ ]
				if (errorsFatal)
					throw Error("Player deleted from roster.")
			}

			const posIndex = Math.min(7, i % positions.length)
			const position = positions[posIndex]

			// This is the netStat score we display on-screen
			const netStat = netStats && playerKey && playerKey[player] && netStats[playerKey[player]] && netStats[playerKey[player]].normalized[posIndex]
			// console.log('netstat', player, player && playerKey && playerKey[player], player && playerKey && netStats && netStats[playerKey[player]], position, posIndex, netStat)

			data[player][qtr] = {
				i,
				position,
				netStat,
			}
		}

	} else {

		//
		// No roster: put everyone on the bench.
		//

		// console.log("No roster: bench everyone")
		for (let qtr = 0; qtr < numPeriods; qtr += 1) {
			for (let pos = 0; pos < positions.length; pos += 1) {
				const i = qtr * positions.length + pos
				const player = players[pos]
				if (player) {
					const position = positions[pos]
					data[player][qtr] = {
						i,
						position,
					}
					// console.log('slot', player, qtr, data[player][qtr])
				}
			}
		}
	}

	// console.log('parseRoster results', positions, roster, data)

	return data
}

//
// Attempt to perform some data checks!
//
export function verifyData(data, type) {

	try {

		const sample = Object.assign({ }, data)
		sample.positions = sample.positions || calculatePositions(sample.players.length)
		const parsed = parseRoster({
			roster: sample.roster.roster,
			players: sample.players,
			positions: sample.positions,
			errorsFatal: true,
		})

		sample.players.forEach(playerName => {
			for (let qtr = 0; qtr < numPeriods; qtr += 1) {
				if (parsed[playerName][qtr].i === undefined || !parsed[playerName][qtr].position) {
					throw Error('Bogus result from parseData?')
				}
			}
		})

		if (sample.preferences) {
			if (Object.keys(sample.preferences).length !== sample.players.length) {
				throw Error("Incorrect number of keys in saved position preferences.")
			}
			sample.players.forEach(playerName => {
				if (!sample.preferences[playerName] || sample.preferences[playerName][0] === undefined) {
					throw Error("Bad data in saved position preferences.")
				}
			})
		}

	} catch(error) {
		console.error("Failed verification!", error)
		return null
	}

	//
	// Passed!
	//
	return data
}

//
// Return data like:
// { 'Matilda': [ undef, 'GS', 'GK', undef, 'WA' ], 'Max': [ ... ], ... }
//
export function parseRosterTrials({ roster, players, errorsFatal }) {

	if (!roster || !players)
		return null

	// console.log('parseRoster', roster, roster.length, positions, players, players.length)

	const playersPerGame = fieldPositions.length * 2
	const numGames = roster.length / playersPerGame

	//
	// Create initial object with a key of player names and a
	// value of an empty array.
	//
	const data = players.reduce((map, player) => {
		map[player] = new Array(numGames)
		return map
	}, { })

	if (roster.length) {

		for (let i = 0; i < numGames; i += 1) {
			for (let j = 0; j < playersPerGame; j += 1) {
				const offset = i * playersPerGame + j
				const player = roster[offset]
				const position = fieldPositions[j % fieldPositions.length]

				//
				// This happens if we rename a player after a roster already exists.
				//
				if (player !== undefined && data[player] === undefined)
					console.log("Unknown player", player, data[player], i, j, offset, position)

				if (player && data[player] !== undefined)
					data[player][i] = position
			}
		}
	}

	// console.log('parseRoster results', positions, roster, data)

	return data
}


//
// Create starting function with players assigned randomly
//
export function createEmptyPhenotype(players) {

	const positions = calculatePositions(players.length)

	const data = [ ]

	for (let i = 0; i < numPeriods; i += 1) {
		const p = players.slice()
		shuffle(p)
		for (let j = 0; j < positions.length; j++) {
			const offset = i * positions.length
			data[offset + j] = p[j]
		}
	}

	return { roster: data }
}

export function shuffle(array) {
	if (!Array.isArray(array)) {
		console.error('Not an array!', array)
	}
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
}

export function calculatePositions(numPlayers) {
	const positions = fieldPositions.slice()
	for (let i = fieldPositions.length; i < numPlayers; i += 1) {
		positions.push(BENCHED)
	}
	return positions
}

export function labelRound(str) {
	if (str && String(str).match(/^\d+$/)) {
		return `Round ${str}`
	}
	return str
}

export const shallowCompare = (obj1, obj2) => {
	return Object.keys(obj1).length === Object.keys(obj2).length && Object.keys(obj1).every(key => obj1[key] === obj2[key])
}

const TEAM_CHARS = 4
const SEASON_CHARS = 3
const GAME_CHARS = 3

export function sectionToShortcut(section) {

	if (!section || !section.team) {
		console.error("Request to sectionToShortcut with invalid section", section)
		return null
	}

	let shortcut = section.team.substr(0, TEAM_CHARS)
	if (section.season) {
		shortcut += section.season.substr(0, SEASON_CHARS)
		if (section.game) {
			shortcut += section.game.substr(0, GAME_CHARS)
		}
	}

	return shortcut
}

export function shortcutToSection(shortcut, callback) {

	const section = { }

	//
	// Does this look like a valid shortcut?
	//
	if (!shortcut || !callback || (shortcut.length !== TEAM_CHARS && shortcut.length !== TEAM_CHARS + SEASON_CHARS && shortcut.length !== TEAM_CHARS + SEASON_CHARS + GAME_CHARS)) {
		console.error("Invalid shortcut")
		return callback(section)
	}

	const teamId = shortcut.substr(0, TEAM_CHARS)
	const seasonId = shortcut.substr(TEAM_CHARS, SEASON_CHARS)
	const gameId = shortcut.substr(TEAM_CHARS + SEASON_CHARS, GAME_CHARS)

	db.collection("/teams")
		.orderBy(firebase.firestore.FieldPath.documentId())
		.startAt(teamId)
		.endAt(teamId + "\uf8ff")
		.get()
		.then(teamsSnapshot => {

			// console.log("Number of teams:", teamsSnapshot.size)

			if (teamsSnapshot.size < 1)
				return callback(section)

			teamsSnapshot.forEach(teamDoc => {

				// console.log(teamDoc.id, '=================>', teamDoc.data())

				section.team = teamDoc.id

				if (seasonId) {
					const seasonId = shortcut.substr(TEAM_CHARS, SEASON_CHARS)

					db.collection(`/teams/${teamDoc.id}/seasons`)
						.orderBy(firebase.firestore.FieldPath.documentId())
						.startAt(seasonId)
						.endAt(seasonId + "\uf8ff")
						.get()
						.then(seasonsSnapshot => {

							// console.log("Number of seasons:", seasonsSnapshot.size)

							if (seasonsSnapshot.size < 1)
								return callback(section)

							seasonsSnapshot.forEach(seasonDoc => {

								// console.log(seasonDoc.id, '=================>', seasonDoc.data())

								section.season = seasonDoc.id

								if (gameId) {

									db.collection(`/teams/${teamDoc.id}/seasons/${seasonDoc.id}/games`)
										.orderBy(firebase.firestore.FieldPath.documentId())
										.startAt(gameId)
										.endAt(gameId + "\uf8ff")
										.get()
										.then(gamesSnapshot => {

											if (gamesSnapshot.size < 1)
												return callback(section)

											// console.log("Number of games:", gamesSnapshot.size)

											gamesSnapshot.forEach(gameDoc => {

												// console.log(gameDoc.id, '=================>', gameDoc.data())

												section.game = gameDoc.id
												section.showRoster = true

												// console.log("Section is finally:", section)

												callback(section)
											})

										})
								} else {
									callback(section)
								}
							})
						})
				} else {
					callback(section)
				}
			})
		})
}

export function saveToLocalStorage(data, key) {
	try {
		localStorage.setItem(key, JSON.stringify(data))
	} catch(error) {
		console.error("Failed to save to localStorage.", error)
	}
}

export function loadFromLocalStorage(key) {
	try {
		return JSON.parse(localStorage.getItem(key))
	} catch(error) {
		console.error("Unable to read localStorage", error)
	}
	return null
}

const DEFAULT_PLAYER_NAME = 'Player'

//
// Calculate new name, e.g. "Player 11"
//
// Can generate multiple names at once if requested; in this case, returns
// an array of names rather than a single string.
//
// Examples:
//
// generateNewPlayerName(players)    => returns "Player 13"
//
// generateNewPlayerName(players, 2) => returns [ "Player 13", "Player 14" ]
//
export const generateNewPlayerName = (existingPlayers, num) => {
	let newPlayerNumber = existingPlayers.length + 1

	const numNamesToGenerate = Math.max(1, num || 1)

	const players = existingPlayers.slice()

	for (let i = 0; i < numNamesToGenerate; i += 1) {
		while (isDuplicate(players, newPlayerNumber)) {
			newPlayerNumber = newPlayerNumber + 1
		}

		const newPlayerName = DEFAULT_PLAYER_NAME + ' ' + newPlayerNumber.toString()
		players.push(newPlayerName)
	}

	if (!num) {
		return players[players.length - 1]
	}

	return players.slice(existingPlayers.length)
}

export const joinListStrings = arr => {
	return arr.reduce((acc, value, i, array) => acc === null ? [ value ] : [ acc, (i < array.length - 1 ? ' ' : ' and '), value ], null)
}

//
// Given an array of rounds, return:
// {
//    0: { round: 1, court: 1 },
//    1: { round: 1, court: 2 },
//    ...
// }
//
export const getGameInfo = rounds =>
	rounds.reduce((map, round, index) => {
		round.forEach((game, courtIndex) => map[game] = { round: index + 1, court: courtIndex + 1 })
		return map
	}, { })

const isDuplicate = ( existingPlayers, number) => existingPlayers.filter(playerName => playerName === DEFAULT_PLAYER_NAME + ' ' + number.toString()).length

export const deepCopy = obj => JSON.parse(JSON.stringify(obj))
