import React, { useReducer } from 'react'
import { Link } from 'react-router-dom'
import GridCell from './GridCell'
import GridPlayers from './GridPlayers'
import GridPositions from './GridPositions'
import GridPreferences from './GridPreferences'
import { fieldPositions, numPeriods } from './definitions'

const DisplayRoster = props => {

	const [ ignored, forceUpdate ] = useReducer(x => x + 1, 0)

	const { editMode, showAltBoard, showPositionPreferences, data, positions, roster, players, available, locks, preferences, nullPositions, setAmDragging, toggleLock, toggleAvailable, renamePlayer, setPreference, isSingleRoster, showNetStats } = props

	// console.log("DisplayRoster.js render with data", data)

	if (!roster || !data)
		return null

	if (!players || !players.length) {
		return (
			<p>
				This season has no players.
				{' '}
				<Link
					to={{
						pathname: "/players",
					}}

				>
					Add some players.
				</Link>
			</p>
		)
	}

	const periods = new Array(numPeriods).fill(undefined)

	//
	// *** IMPORTANT ***
	// So this motherfucker is bound at mount-time and can't
	// rely on reading any up-to-date values! That's super-annoying.
	// I need to be very careful that I don't rely on any values
	// that might change after mount.
	//
	// You *can* use dynamic variables in a callback function that has been
	// supplied to onDrop via props.
	//
	function onDrop(event, obj) {
		const { setAmDragging, swapPlayers, swapPositions, findAltPosition, setOrangeman } = props

		setAmDragging(false)

		const coords = { }
		const isTouch = event.changedTouches !== undefined

		if (!isTouch) {
			coords.x = event.x
			coords.y = event.y
		} else {
			const touch = event.changedTouches[0]
			coords.x = touch.clientX
			coords.y = touch.clientY
		}

		//
		// Edge doesn't work properly: https://caniuse.com/#search=elementsfrompoint
		//
		const elementsFromPointFunction = document.elementsFromPoint || document.msElementsFromPoint

		elementsFromPointFunction.apply(document, [ coords.x, coords.y ]).some(element => {
			if (element.classList.contains('posed-element')) {

				//
				// We dropped something on the grid.
				//
				const tile = element.firstChild
				if (tile) {
					const i = tile.getAttribute('data-i')
					// console.log(obj, 'was dropped on tile i', i, element)

					if (obj.player && obj.qtr !== undefined) {
						//
						// We're moving a player tile in edit-altboard mode
						//
						const from = findAltPosition(obj.player, obj.qtr)
						if (Number(from) !== Number(i)) {
							// console.log("ok so these are not equal", from, i, from !== i)
							swapPositions(from, i)
							return true
						}
					} else if (obj.player) {
						//
						// We're moving a player tile.
						//
						if (tile.classList.contains('cell-player')) {
							if (obj.player !== i) {
								swapPlayers(obj.player, i)
								return true
							}
						}
					} else {
						//
						// We're moving a position tile.
						//
						if (tile.classList.contains('cell-position')) {
							if (Number(i) !== Number(obj.i)) {
								// console.log('Invoking swapPositions!', i, obj)
								swapPositions(obj.i, i)
								return true
							}
						}
					}
				}
			} else if (obj.player && element.classList.contains('orangeman')) {

				//
				// We dropped something on the Orangeman space.
				//
				setOrangeman(obj.player)
				return true
			}

			return false
		})

		//
		// No valid drop target found: make sure we re-render anyway
		// so the tile gets animated back into place.
		//
		forceUpdate(ignored)
	}

	// console.log('display render', players, roster, data, players.length, roster.length, data.length)

	const editModePositions = (editMode && roster && roster.length) ? true : false

	return (
		<div id="board" className="grid">
			{
				(showAltBoard && !showPositionPreferences) ? (
					<AltGridPositions
						positions={positions}
						available={available}
					/>
				) : (
					<GridPlayers
						players={players}
						editMode={editMode}
						isSingleRoster={isSingleRoster}
						onDrop={onDrop}
						setAmDragging={setAmDragging}
						available={available}
						toggleAvailable={toggleAvailable}
						renamePlayer={renamePlayer}
					/>
				)
			}
			{
				(showPositionPreferences && editMode) ?
					fieldPositions.map((position, index) => (
						<GridPreferences
							key={index}
							position={position}
							positionIndex={index}
							players={players}
							preferences={preferences}
							setPreference={editMode && setPreference}
						/>
					))
					:
					periods.map((v, qtr) => (
						<GridPositions
							key={qtr}
							players={players}
							available={available}
							data={data}
							roster={roster}
							toggleLock={toggleLock}
							nullPositions={nullPositions}
							onDrop={onDrop}
							locks={locks}
							setAmDragging={setAmDragging}
							editMode={editModePositions}
							qtr={qtr}
							showNetStats={showNetStats}
							showAltBoard={showAltBoard}
						/>
					))
			}
		</div>
	)
}

const AltGridPositions = props => {

	// console.log('AltGridPositions', props)

	const { positions, available } = props

	if (!positions || !available)
		return null

	let numAvailablePlayers = Object.values(available).filter(isAvailable => isAvailable ? 1 : 0).length
	if (numAvailablePlayers < fieldPositions.length) {
		numAvailablePlayers = fieldPositions.length
	}

	const availablePositions = positions.slice(0, numAvailablePlayers)

	return (
		<div className="column column-players">
			<GridCell type="void" content="" />
			{
				availablePositions.map((position, index) => {
					const myClasses = [ "cell", "cell-position", `cell-position-${position}` ]
					return (
						<div key={index} className="fixed-element">
							<div className={myClasses.join(' ')}>
								{position}
							</div>
						</div>
					)
				})
			}
		</div>
	)
}

export default DisplayRoster
