import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
import { config } from './firebaseCredentials'

const databaseLogging = 'error' // 'debug'

firebase.initializeApp(config)

firebase.firestore.setLogLevel(databaseLogging)

export const db = firebase.firestore()

export const analytics = firebase.analytics()
//console.log("Analytics", analytics)

export default firebase
