import React from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import FunFact from './FunFact'
import News from './News'
import demoVideo from './demo-700.mp4'
import "./home.css"

const Home = props => {

	const navItems = [
		{
			text: 'Make one roster',
			linkTo: '/single-roster',
		},
		{
			text: 'Manage a team',
			linkTo: '/teams',
		},
		{
			text: 'Make rosters for trials',
			linkTo: '/trials',
		},
	]

	return (
		<section className="home">

			<Header
				title="Generate netball rosters with ease"
			/>

			<div className="this-is-v1">
				This is an archived version of AutoRoster.
				For the latest version,
				{' '}
				<a href="https://autoroster.io">
				go here
				</a>
				.
			</div>

			<div className="home-upper">
				<Link
					className="home-video-container"
					to='/single-roster'
				>
					<video playsInline autoPlay muted width="700" height="536">
						<source src={demoVideo} type="video/mp4" />
					</video>
				</Link>

				<FunFact />
			</div>

			<p className="i-wanna">
				I want to...
			</p>
			<div className="splash-boxnav list-items">
				{
					navItems.map((item, index) =>
						<BoxNavItem key={index} item={item} index={index} />
					)
				}
			</div>

			<News />

		</section>
	)
}

const BoxNavItem = props => {
	const { item, index } = props
	const { text, linkTo } = item

	let content

	if (linkTo) {
		content = (
			<Link
				className={`item item-${index}`}
				to={linkTo}
			>
				{text}
			</Link>
		)
	} else {
		content = (
			<div
			className={`item item-nolink item-${index}`}
			>
				{text}
			</div>
		)
	}
	return (
		<div className="splash-boxnav-item list-item">
			{content}
		</div>
	)
}

export default Home
