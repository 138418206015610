import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import firebase from './firebase'
import FirebaseAuth from 'react-firebaseui/FirebaseAuth'
import Header from './Header'
import FadeText from './FadeText'
import { TiTick } from 'react-icons/ti'

const uiConfig = {
	signInOptions: [
		firebase.auth.GoogleAuthProvider.PROVIDER_ID,
		firebase.auth.FacebookAuthProvider.PROVIDER_ID,
		firebase.auth.EmailAuthProvider.PROVIDER_ID,
		{
			provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
			defaultCountry: 'AU',
		},
	],
	callbacks: {
		signInSuccessWithAuthResult: (authResult, redirectUrl) => {
			console.log("Auth successful", authResult, redirectUrl)
			return true
		},
	},
	signInSuccessUrl: '/',
}

const Login = ({ user, signout }) => {

	useEffect(() => {
		console.log('mounted Login')
	}, [ ])

	useEffect(() => {

		if (signout) {
			console.log('signing out!')

			firebase.auth().signOut().then(() => {
				console.log('Signed Out')
				window.location.href = '/home'
			}, error => {
				console.error('Sign Out Error', error);
			})
		}
	}, [ signout ])

	let content
	if (user) {
		//
		// This shouldn't be ever displayed unless a user navigates here
		// manually, since after a successful signin we redirect elsewhere.
		//
		content = (
			<div>
				<p className="i-am-signed-in">
					<TiTick /> You are signed in as {user.displayName}.
				</p>
				<p>
					<Link
						to="/"
					>
						<button>
							Continue
						</button>
					</Link>
				</p>
			</div>
		)
	} else {
		content = (
			<div>
				<FirebaseAuth
					uiConfig={uiConfig}
					firebaseAuth={firebase.auth()}
				/>
			</div>
		)
	}

	return (
		<div>
			<Header title="Sign In" />
			<h2>
				Sign In / Register
			</h2>
			<FadeText>
				{content}
			</FadeText>
		</div>
	)
}

export default Login
