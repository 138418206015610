import React from 'react'
import GridCell from './GridCell'
import GridCellPosition from './GridCellPosition'
import { PositionPreference } from './definitionsPoses'

const GridPreferences = props => {

	const { position, positionIndex, players, preferences, totalNumber, isTrials, setPreference } = props

	if (!players || !players.length) {
		return null
	}

	return (
		<div className="column column-positions column-preferences">
			{
				totalNumber !== undefined && (
					<div className="cell-header num-preferences">
						{totalNumber}
					</div>
				)
			}
			<GridCell type="header" content={position} />
			{
				players.map((player, index) =>
					<CellPreference
						key={`${index}-${position}`}
						isTrials={isTrials}
						player={player}
						position={position}
						positionIndex={positionIndex}
						value={(preferences[player] && preferences[player][positionIndex]) || 0}
						setPreference={setPreference}
					/>
				)
			}
		</div>
	)
}

//
// Don't re-render cells unless the contents change
//
const CellPreference = React.memo(props => {

	const { value, player, position, positionIndex, isTrials, setPreference } = props

	const handleClick = args => {
		if (setPreference) {
			let newValue = value + 1
			if (newValue > 1) {
				if (isTrials) {
					newValue = 0
				} else {
					newValue = -1
				}
			}
			const newArgs = Object.assign({ }, args)
			newArgs.value = newValue
			setPreference(newArgs)
		}
	}

	const content = (value ? position : '-')

	const richContent = (value === -1 ? (
		<div className="exclude-position">
			<BanIcon />
			{position}
		</div>
	) : position)

	return (
		<PositionPreference
			key={`${position}-${player}`}
			className="posed-element position-preference"
		>
			<GridCellPosition
				i={position}
				content={content}
				richContent={richContent}
				player={player}
				onClick={target => handleClick({ target, player, positionIndex })}
			/>
		</PositionPreference>
	)
})

const BanIcon = () => (
	<svg viewBox="0 0 100 100" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
		<line x1="20" y1="20" x2="80" y2="80" stroke="red" strokeWidth="6"/>
	</svg>
)

export default GridPreferences
