import { useState } from 'react'
import EditItem, { validateResultField } from './EditItem'
import { db } from './firebase'
import { deepCopy } from './util'
import './displayscores.css'

const DisplayScores = props => {
	const { game, section, editMode } = props

	if (!game)
		return null

	const { result, date } = game

	let content = null

	if (result) {

		const resultStr = result.me[3] > result.them[3] ? 'Won' : result.me[3] < result.them[3] ? 'Lost' : 'Drew'

		const qtrs = [ 0, 1, 2, 3 ]

		const qtrScores = {
			me: [ ],
			them: [ ],
		}

		qtrs.forEach(qtr => {
			if (!qtr) {
				qtrScores.me[0] = result.me[0]
				qtrScores.them[0] = result.them[0]
			} else {
				qtrScores.me[qtr] = result.me[qtr] - result.me[qtr - 1]
				qtrScores.them[qtr] = result.them[qtr] - result.them[qtr - 1]
			}
		})

		content = (
			<div className="undergrid undergrid-scores">
				<div className="grid">
					<div className="column column-players">
						<div className="fixed-element">
							<div className={`cell cell-position cell-void cell-netstat cell-netstat-${resultStr === 'Won' ? 'positive' : resultStr === 'Drew' ? 'equal' : 'negative'}`}>
								{resultStr} {result.me[3]} - {result.them[3]}
							</div>
						</div>
					</div>
					{
						qtrs.map(qtr => (
							<div
								key={qtr}
								className="column column-positions"
							>
								<div className="fixed-element">
									<div className={`cell cell-position cell-void cell-netstat cell-netstat-${qtrScores.me[qtr] > qtrScores.them[qtr] ? 'positive' : qtrScores.me[qtr] === qtrScores.them[qtr] ? 'equal' : 'negative'}`}>
										{qtrScores.me[qtr]} - {qtrScores.them[qtr]}
									</div>
								</div>
							</div>
						))

					}
				</div>
			</div>
		)
	}

	let editContent = null

	const gameIsInPast = date && date < Date.now() - 1800000

	// console.log('gameIsInPast', gameIsInPast)

	if (gameIsInPast && editMode) {
		editContent = <OfferEnterScores game={game} section={section} />
	}

	return (
		<>
			{content}
			{editContent}
		</>
	)
}

const OfferEnterScores = props => {
	const { game, section } = props

	const originalResult = game && {
		result: game.result ? deepCopy(game.result) : null
	}

	const [ fieldData, setFieldData ] = useState(originalResult)

	if (!game)
		return null

	const type = {
		name: 'games',
		unit: 'game',
		dbid: `/teams/${section?.team}/seasons/${section?.season}/games`,
	}

	const gameId = section?.game

	const isValid = fieldData?.result && validateResultField(fieldData.result)
	const isValidNull = !isValid && fieldData?.result && validateResultField(fieldData.result, true)
	const isDifferent = JSON.stringify(game?.result) !== JSON.stringify(fieldData?.result)

	const handleChange = e => {
		const newData = Object.assign({ }, fieldData)

		if (newData.result !== undefined) {
			if (!isValid) {
				console.log('invalid result', newData.result)
				if (isValidNull) {
					// It's only invalid because we've deleted all the values, so allow saving.
					newData.result = null
				} else {
					// It's just plain invalid, so don't save.
					return
				}
			}
		}

		// console.log('Saving newData', newData, 'for', gameId)
		db.collection(type.dbid).doc(gameId).update(newData)
	}

	return (
		<div className="enter-scores-container">
			<div className="enter-scores">
				<div className="enter-scores-title">Scores</div>
				<EditItem
					field="result"
					type={type}
					setFieldData={setFieldData}
					fieldData={fieldData}
					onEnter={handleChange}
				/>
				<div className="edit-buttons">
					<button
						className="button-update-item"
						onClick={handleChange}
						disabled={!isDifferent || (!isValid && !isValidNull)}
					>
						Submit Match Scores
					</button>
					<button
						className="button-icon button-cancel"
						onClick={() => setFieldData(originalResult)}
					>
						Reset
					</button>
				</div>
			</div>
		</div>
	)
}

export default DisplayScores
