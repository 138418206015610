import React from 'react'
import TextField from '@material-ui/core/TextField'
import { ucFirst } from './util'

const EditItem = props => {
	const { field, type, fieldData, setFieldData, lastItem, index, plain, amAdding, onBlur, onEnter, onCancel, onTab } = props

	// Don't allow entering of results when adding a game for the first time
	if (amAdding && field === 'result')
		return null

	const isDate = field === 'date'
	const isResult = field === 'result'

	const onChange = e => {

		// console.log('onChange', e, e.target.value)

		let v = e.target.value

		if (isDate) {
			// We store dates as a number.
			v = new Date(v).getTime()
		}
		else if (isResult) {
			const el = e.target.closest('.add-result-field')
			const qtr = el.dataset.resultQtr
			const side = el.dataset.resultSide
			// console.log('qtr', qtr, 'side', side, v, el.dataset)

			const newResult = formatResult(fieldData.result)
			newResult[side][qtr] = v
			v = formatResult(newResult)
		}

		setField(v)
	}

	const setField = value => {
		// console.log('setField', field, value)
		setFieldData({
			...fieldData,
			[field]: value,
		})
	}

	let value = fieldData[field] || ''

	//
	// Default values when entering new stuff.
	//
	if (!value) {
		if (lastItem) {
			if (field === 'round') {
				const n = Number(lastItem.round)
				if (n && Number.isInteger(n)) {
					// console.log("Setting round from lastItem", n, lastItem)
					setField(n + 1)
				}
			} else if (field === 'date' && lastItem.date) {
				setField(lastItem.date + 7 * 86400000)
			}
		} else {
			if (field === 'date') {
				const now = new Date().getTime()
				setField(now + 3600000 - (now % 3600000))
			}
		}
	}

	if (isDate) {
		if (value) {
			value = new Date(value)
		} else {
			value = new Date()
		}
		value = formatDate(value)
	} else if (isResult) {
		value = formatResult(value)
	}

	// console.log('field', field, 'value', value)

	const onKeyDown = e => {
		// console.log('onKeyDown', e)
		if (e) {
			if (e.key === 'Enter') {
				onChange(e)
				onBlur && onBlur()
				onEnter && onEnter()
			} else if (e.key === 'Escape' && onCancel) {
				onCancel()
			} else if (e.key === 'Tab' && onTab) {
				onTab(e.shiftKey)
			}
		}
	}

	if (plain) {
		return (
			<input
				key={field}
				autoFocus
				className="add-item-field"
				type="text"
				name="newItem"
				data-field={field}
				placeholder={`${ucFirst(type.unit)} ${ucFirst(field)}`}
				onChange={onChange}
				onBlur={onBlur}
				onKeyDown={onKeyDown}
				value={value}
			/>
		)
	}

	const Element = isResult ? ResultFields : TextField

	return (
		<Element
			autoFocus={index === 0}
			className="add-item-field"
			label={`${ucFirst(type.unit)} ${ucFirst(field)}`}
			data-field={field}
			type={isDate ? "datetime-local" : "text"}
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			onKeyDown={onKeyDown}
			InputLabelProps={{
				shrink: true,
			}}
		/>
	)

}

const ResultFields = props => {

	const { value } = props

	const qtrs = [ 0, 1, 2, 3 ]

	return (
		<div
			className="MuiFormControl-root MuiTextField-root"
		>
			<label
				className="edit-scores-title MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
				data-shrink="true"
			>
				Score
			</label>
			<table
				className="enter-results-table"
			>
				<thead>
					<tr>
						<th />
						<th>
							You
						</th>
						<th>
							Them
						</th>
					</tr>
				</thead>
				<tbody>
					{
						qtrs.map(qtr => (
							<tr key={qtr}>
								<td>
									{qtr === 3 ? 'Final' : `Q${qtr + 1}`}
								</td>
								<td>
									<ResultField
										result={value}
										side="me"
										qtr={qtr}
										specialProps={props}
									/>
								</td>
								<td>
									<ResultField
										result={value}
										side="them"
										qtr={qtr}
										specialProps={props}
									/>
								</td>
							</tr>
						))
					}
				</tbody>
			</table>
		</div>
	)
}

const ResultField = props => {
	const { result, side, qtr, specialProps } = props

	const isError = !validateResult({ result, side, qtr })
	const label = isError ? 'Error' : ''

	return (
		<TextField
			{...specialProps}
			value={result[side][qtr] === null ? '' : result[side][qtr]}
			data-result-qtr={qtr}
			data-result-side={side}
			error={isError}
			className='add-result-field'
			label={label}
			type="number"
			variant="outlined"
			size="small"
			InputProps={{
				inputProps: {
					min: 0
				}
			}}
		/>
	)
}

function formatDate(d) {
	const offsetMs = d.getTimezoneOffset() * 60 * 1000
	const dateLocal = new Date(d.getTime() - offsetMs)
	return dateLocal.toISOString().slice(0, 16)
}

function formatResult(result) {

	//
	// Ensure basic format is correct
	//
	if (!result || typeof result !== 'object' || !result.me || !result.them || result.me.length !== 4 || result.them.length !== 4) {
		return {
			me:		[ null, null, null, null ],
			them:	[ null, null, null, null ],
		}
	}

	;[ 'me', 'them' ].forEach(side => {
		[ 0, 1, 2, 3 ].forEach(qtr => {
			if (result[side][qtr] === '') {
				result[side][qtr] = null
			} else if (result[side][qtr] !== null) {
				result[side][qtr] = Number(result[side][qtr])
			}
		})
	})

	/*
	 * This is no good -- it's annoying to have the site refuse to
	 * permit you to have invalid values even for a second.
	 *

	const hasAtLeastOneValue = result.me.filter(v => v !== null).length || result.them.filter(v => v !== null)

	if (hasAtLeastOneValue) {
		console.log("There's a value!", result)
		;[ 'me', 'them' ].forEach(side => {
			[ 0, 1, 2, 3 ].forEach(qtr => {
				result[side][qtr] = Number(result[side][qtr])
				if (result[side][qtr] < 0) {
					result[side][qtr] = 0
				}
				if (qtr > 0 && result[side][qtr] < result[side][qtr - 1]) {
					result[side][qtr] = result[side][qtr - 1]
				}
			})
		})
	}
	*/

	return result
}

//
// Check whether this result value makes sense.
//
function validateResult({ result, side, qtr }) {
	const value = result[side][qtr]
	const otherSide = side === 'me' ? 'them' : 'me'

	// Both sides must have value
	if (value === null) {
		if (result[otherSide][qtr] !== null) {
			return false
		}
		// Can't be null if previous qtr is not null
		if (qtr > 0 && result[side][qtr - 1] !== null) {
			return false
		}
		return true
	}

	// Value must be higher than prev values
	for (let i = qtr - 1; i >= 0; i -= 1) {
		if (value < Number(result[side][i])) {
			return false
		}
	}

	return true
}

//
// Return true if we should save this 'result' value,
// or false if we should consider it corrupt and delete it.
//
// Consider all 'null' values to be invalid, too.
//
export function validateResultField(result, permitAllNull) {
	let ok = true
	let allNull = true

	;[ 'me', 'them' ].forEach(side => {
		;[ 0, 1, 2, 3 ].forEach(qtr => {
			if (ok && !validateResult({ result, side, qtr })) {
				ok = false
			}
			if (allNull && result[side][qtr] !== null) {
				allNull = false
			}
		})
	})

	// console.log('validateResultField', result, ok, allNull)

	if (allNull && !permitAllNull) {
		ok = false
	}

	return ok
}

export default EditItem
