import React, { useState, useEffect } from 'react'
import Header from './Header'
import TourRoster from './TourRoster'
import { fetchDataSet, saveData } from './Data'
import { generateAllNetStats } from './Stats'
import { userCanEditItem, userCanViewNetStats, verifyData, labelRound, loadFromLocalStorage } from './util'
import { BENCHED, fieldPositions, singleRosterLocalStorageKey } from './definitions'

function RouteRoster(props) {
	const { isSingleRoster, isTrials, section, team, game, user } = props

	const [ loading, setLoading ] = useState(true)
	const [ players, setPlayers ] = useState()
	const [ preferences, setPreferences ] = useState()
	const [ key, setKey ] = useState({ })
	const [ initialRoster, setInitialRoster ] = useState([ ])
	const [ netStats, setNetStats ] = useState({ })

	useEffect(() => {

		setLoading(true)

		let unsubscribe

		if (isSingleRoster) {

			//
			// Set up default players: 1 per position + 1 BENCH
			//
			const defaultPlayerName = 'Player'
			const defaultPlayers = [ ...fieldPositions, BENCHED ].map((position, index) => defaultPlayerName + ' ' + Number(index + 1).toString())

			let saveData = loadFromLocalStorage(singleRosterLocalStorageKey)
			try {
				const ok = saveData && verifyData(saveData)
				// console.log("Data verification result:", ok)
				if (!ok) {
					saveData = null
				}
			} catch(error) {
				console.error('Failed to load from localStorage', error)
			}

			const savedPlayers = saveData && saveData.players
			setPlayers(savedPlayers || defaultPlayers)

			if (saveData) {
				setPreferences(saveData.preferences)
			}

			//
			// Load any saved roster
			//
			const savedRoster = saveData && saveData.roster
			if (savedRoster) {
				savedRoster.nulled = parseNulled(savedRoster.nulled, savedRoster.roster.length)
				setInitialRoster(savedRoster)
			}

			setLoading(false)

		} else if (section) {

			//
			// Managing a team
			//

			const requests = [
				{
					name: 'players',
					section,
				},
				{
					name: 'roster',
					id: section.game,
					section,
				},
				{
					name: 'rosters',
					section,
				},
				{
					name: 'games',
					section,
				},
			]

			unsubscribe = fetchDataSet(requests, (data) => {
				const playerData = data.players

				// FIXME: Sometimes players seem to have no name field. Substituting '???' is better
				// than nothing but leads to duplicate keys because names are supposed to be unique.
				const newPlayers = Object.values(playerData).map(player => player.name || '???')
				const newKey = Object.keys(playerData).reduce((map, id) => {
					map[playerData[id].name] = id
					return map
				}, { })

				if (data.roster) {
					const newRoster = Object.assign({ }, data.roster)

					if (newRoster.players) {
						newRoster.players = newRoster.players.map(playerId => playerData[playerId] ? playerData[playerId].name : '???')
					}

					if (newRoster.roster) {
						newRoster.roster = newRoster.roster.map(playerId => playerData[playerId] ? playerData[playerId].name : '')
						newRoster.nulled = parseNulled(data.roster.nulled, newRoster.roster.length)
					}

					if (newRoster.orangeman) {
						const orangemanData = playerData[newRoster.orangeman]
						if (orangemanData) {
							newRoster.orangeman = playerData[newRoster.orangeman].name
						} else {
							console.error("Missing orangeman", newRoster.orangeman)
						}
					}

					setInitialRoster(newRoster)
				}
				// console.log('set players and key', newPlayers, newKey)
				setKey(newKey)
				setPlayers(newPlayers)
				setLoading(false)

				// netStats
				setNetStats(generateAllNetStats(data))
			})
		}

		return unsubscribe

	}, [ section, isSingleRoster ])

	function setRoster(data) {

		data.roster = data.roster.map(playerName => key[playerName] || '')

		data.players = data.players.map(playerName => key[playerName])

		if (data.players.filter(player => player === undefined).length) {
			window.alert("Cannot save: players are not defined.")
			return
		}

		if (data.orangeman) {
			data.orangeman = key[data.orangeman]
		} else {
			delete data.orangeman
		}

		data.created = Date.now()

		saveData({
			name: 'roster',
			section,
			data,
			id: section.game,
		})

		return true
	}

	const userCanEdit = isSingleRoster || userCanEditItem({
		type: {
			unit: 'roster',
		},
		team,
		user,
	})

	const showNetStats = !isSingleRoster && userCanViewNetStats({
		user,
		team,
	})

	const pageTitle = isSingleRoster ? 'https://autoroster.io' : (game && (game.round ? labelRound(game.round) : '') + (game.opponent ? ` vs ${game.opponent}` : ''))

	return (
		<div>
			<Header team={isSingleRoster ? null : team} title={pageTitle || "Roster"} />
			<TourRoster
				loading={loading}
				isSingleRoster={isSingleRoster}
				isTrials={isTrials}
				players={players}
				playerKey={key}
				game={game}
				initialRoster={initialRoster}
				initialPreferences={preferences}
				userCanEdit={userCanEdit}
				setRoster={setRoster}
				section={section}
				showNetStats={showNetStats}
				netStats={netStats}
			/>
		</div>
	)
}

//
// Convert 'nulled' from an array of values like [ 2, 3] into
// an array like [ false, false, true, true, false, false, ... ]
//
function parseNulled(nulled, rosterLength) {
	const newNulled = new Array(rosterLength).fill(false)
	if (nulled) {
		nulled.forEach(i =>
			newNulled[i] = true
		)
	}
	return newNulled
}

export default RouteRoster
