import React from 'react'
import { FaTwitter } from 'react-icons/fa'
import packageInfo from '../package.json'
import './footer.css'

const SHOW_TWITTER_LINK = false

const Footer = props => {

	const { version } = packageInfo

	const twitterLink = SHOW_TWITTER_LINK && (
		<p>
			<a
				id="twitter-follow-button"
				title="Follow Max Barry on Twitter"
				href="https://twitter.com/intent/follow?original_referer=https%3A%2F%2Fautoroster.io%2F&amp;ref_src=twsrc%5Etfw&amp;region=follow_link&amp;screen_name=SquiggleAFL&amp;tw_p=followbutton"
			>
				<FaTwitter /><span>Follow Max</span>
			</a>
		</p>
	)

	return (
		<footer>
			<p>
				AutoRoster {version} by
				{' '}
				<a
					className="linkish"
					href="https://maxbarry.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					Max Barry
				</a>
			</p>
			<p>
				<a
					href="https://autoroster.io"
				>
					AutoRoster 2
				</a>
			</p>
			{twitterLink}
		</footer>
	)
}

export default Footer
