import React from 'react'
import List from './List'
import { Link } from 'react-router-dom'
import FadeText from './FadeText'

const MyTeams = (props) => {
	const { user } = props

	return (
		<List
			{...props}
			type={{
				name: 'teams',
				niceName: 'My Teams',
				unit: 'team',
				fields: [ 'name' ],
				dbid: '/teams',
				where: [ 'managers', 'array-contains', user ? user.uid : 'public' ],
				pathname: '/seasons/',
				sortFunction: (a, b) => a.name.localeCompare(b.name),

				//
				// When creating a new team, append this data.
				//
				onAdd: (fieldData, props) => (
					{
						managers: [ user.uid ],
					}
				),

				//
				// If we have no items, display this message:
				//
				noItemsMessage: props => (
					<FadeText isBlock={true}>
						{ props.user ? (
							<p>
								Hi {props.user.displayName}!
								Create your first team using the <b>Add</b> button below.
							</p>
						) : (
							<p>
								To create or manage a team,
								{' '}
								<Link
									to="/login?mode=select&signInSuccessUrl=teams"
								>
									<b>
										Sign In
									</b>.
								</Link>
							</p>
						)
						}
					</FadeText>
				),

				//
				// Require a valid user before attempting to load any data.
				//
				precheck: () => user ? true : false,

			}}
		/>
	)
}

export default MyTeams
