import React from 'react'
import GoButton from './GoButton'
import { FiPrinter } from 'react-icons/fi'
import { FaFileDownload } from 'react-icons/fa'

const TrialControls = props => {

	const { ready, haveRoster, haveMadeChanges, onAutoRoster, onExport } = props

	const onPrint = e => window.print()

	return (
		<div className="controlbox">

			<GoButton
				ready={ready}
				highlight={ready && (!haveRoster || haveMadeChanges)}
				onAutoRoster={onAutoRoster}
			/>

			<button className="button-export button-icon button-right" onClick={onExport} disabled={!ready || !haveRoster} title="Export">
				<FaFileDownload />
				<span className="roster-button-text">
					Export
				</span>
			</button>

			<button className="button-print button-icon" onClick={onPrint} disabled={!ready || !haveRoster} title="Print">
				<FiPrinter />
				<span className="roster-button-text">
					Print
				</span>
			</button>

		</div>
	)
}

export default TrialControls
