import React from 'react'
import EditButton from './EditButton'
import { TiThSmallOutline } from 'react-icons/ti'
import { FiSave, FiPrinter } from 'react-icons/fi'
import { FaStreetView } from 'react-icons/fa'
import { MdHelpOutline } from 'react-icons/md'

const ControlBox = props => {

	const { ready, isSingleRoster, editMode, madeChanges, haveSetPositions, numPlayers, showAltBoard, showPositionPreferences, userCanEdit, onSave, onEdit, onTourOpen, onToggleAltBoard, onTogglePositionPreferences } = props

	if (!numPlayers)
		return null

	const firstRightButton = editMode ? 'help' : 'print'
	const helpClass = (firstRightButton === 'help' ? 'button-right' : '')
	const printClass = (firstRightButton === 'print' ? 'button-right' : '')

	const onPrint = e => {
		if (showPositionPreferences) {
			onTogglePositionPreferences()
			setTimeout(() => window.print(), 100)
		} else if (editMode) {
			onEdit()
			setTimeout(() => window.print(), 100)
		} else {
			window.print()
		}
	}

	return (
		<div className="controlbox">

			 <EditButton
				 show={userCanEdit}
				 ready={ready}
				 editMode={editMode}
				 text={editMode && 'Done'}
				 onClick={onEdit}
			 />

			<button className={'button-preferences button-icon' + (showPositionPreferences ? ' enabled' : '') + (haveSetPositions ? ' have-set-positions' : '')} onClick={onTogglePositionPreferences} title="Set some required player positions">
				<FaStreetView />
				<span className="roster-button-text">
					Positions
				</span>
			</button>

			{
				isSingleRoster ? null : (
					<button className="button-save button-icon" onClick={onSave} disabled={!ready || !madeChanges} title="Save">
						<FiSave />
						<span className="roster-button-text">
							Save
						</span>
					</button>
				)
			}

			{
				editMode ? (
					<button
						className={`button-help button-icon ${helpClass}`}
						onClick={() => {
							if (showAltBoard) {
								onToggleAltBoard()
							}
							onTourOpen()
						}}
						disabled={!ready}
						title="See how it works"
					>
						<MdHelpOutline />
						<span className="roster-button-text">
							Tour
						</span>
					</button>
				) : null
			}

			<button className={`button-print button-icon ${printClass}`} onClick={onPrint} disabled={!ready} title="Print">
				<FiPrinter />
				<span className="roster-button-text">
					Print
				</span>
			</button>

			<button className={'button-toggleview button-icon ' + (showAltBoard ? 'enabled' : '')} onClick={onToggleAltBoard} title="Change how you view the roster">
				<TiThSmallOutline />
				<span className="roster-button-text">
					Toggle View
				</span>
			</button>

		</div>
	)
}

export default ControlBox

