import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import DisplayRoster from './DisplayRoster'
import ControlBox from './ControlBox'
import Settings from './Settings'
import AwayPlayers from './AwayPlayers'
import AddPlayerButton from './AddPlayerButton'
import PositionPreferencesSetting from './PositionPreferencesSetting'
import Orangeman from './Orangeman'
import GoButton from './GoButton'
import DeleteButton from './DeleteButton'
import Share from './Share'
import DisplayScores from './DisplayScores'
import { fetchDataSet } from './Data'
import { generateRoster } from './generateRoster'
import { fieldPositions, numPeriods, singleRosterLocalStorageKey } from './definitions'
import { FadeInBox } from './definitionsPoses'
import { parseData, parseRoster, createEmptyPhenotype, calculatePositions, generateNewPlayerName, generateSearchString, saveToLocalStorage } from './util'
import { analytics } from './firebase'
import ProgressBar from './ProgressBar'
import { BiStats } from 'react-icons/bi'
import { FaExclamationTriangle, FaUserFriends } from 'react-icons/fa'
import "./roster.css"

const sortPlayersDelay = 1000

// TODO - make this a modern React component

//
// Use global variables here because the popmotion posed
// library is severely affected by any calls to setState()
// during animation!
//
// See: https://github.com/Popmotion/popmotion/issues/684
//
let amDragging = false
let needSort = null

class Roster extends Component {

	constructor(props) {
		super(props)

		this.state = {
			players: [ ],
			positions: [ ],
			available: { },
			preferences: { },
			preferenceType: 0,
			locks: [ ],
			nullPositions: [ ],
			ready: true,
			editMode: false,
			progress: 0,
			settings: { },
			showAltBoard: false,
			showPositionPreferences: false,
			madeChanges: false,
			rosterIsStale: false,
		}

		this.saveState = this.saveState.bind(this)
		this.go = this.go.bind(this)
		this.onProgress = this.onProgress.bind(this)
		this.clearPins = this.clearPins.bind(this)
		this.saveRoster = this.saveRoster.bind(this)
		this.eraseRoster = this.eraseRoster.bind(this)
		this.toggleEditMode = this.toggleEditMode.bind(this)
		this.toggleLock = this.toggleLock.bind(this)
		this.toggleAvailable = this.toggleAvailable.bind(this)
		this.toggleAltBoard = this.toggleAltBoard.bind(this)
		this.togglePositionPreferences = this.togglePositionPreferences.bind(this)
		this.haveRoster = this.haveRoster.bind(this)
		this.setOrangeman = this.setOrangeman.bind(this)
		this.compressNullPositions = this.compressNullPositions.bind(this)
		this.swapPositions = this.swapPositions.bind(this)
		this.swapPlayers = this.swapPlayers.bind(this)
		this.findAltPosition = this.findAltPosition.bind(this)
		this.sortPlayers = this.sortPlayers.bind(this)
		this.sortedPlayers = this.sortedPlayers.bind(this)
		this.sortThesePlayers = this.sortThesePlayers.bind(this)
		this.setAmDragging = this.setAmDragging.bind(this)
		this.setPreference = this.setPreference.bind(this)
		this.renamePlayer = this.renamePlayer.bind(this)
		this.addPlayer = this.addPlayer.bind(this)
		this.removePlayers = this.removePlayers.bind(this)
		this.updateSettings = this.updateSettings.bind(this)
		this.updatePositionPreferenceType = this.updatePositionPreferenceType.bind(this)
		this.parseStats = this.parseStats.bind(this)
		this.calculateInitialValues = this.calculateInitialValues.bind(this)
		this.notifyTour = this.notifyTour.bind(this)
	}

	//
	// Run this after players and positions have been set
	//
	calculateInitialValues(erase, fromRoster) {

		const { initialRoster, isSingleRoster } = this.props

		// console.log('calculateInitialValues', fromRoster)

		const obj = { }

		let dummyPlayers

		obj.players = (fromRoster && fromRoster.players) || this.props.players
		if (obj.players.length < fieldPositions.length) {
			dummyPlayers = generateNewPlayerName(obj.players, fieldPositions.length - obj.players.length)
			obj.players.push(...dummyPlayers)
		}

		obj.positions = calculatePositions(obj.players.length)

		obj.locks = new Array(obj.positions.length * numPeriods).fill(false)

		//
		// When erasing, always erase preferences
		//
		obj.preferences = !erase && ((fromRoster && fromRoster.preferences) || this.props.initialPreferences)

		obj.best = { }

		//
		// nullPositions are when a quarter is rained out.
		//
		obj.nullPositions = [ ]

		const copyFromRoster = fromRoster || initialRoster
		if (copyFromRoster && Object.keys(copyFromRoster).length && copyFromRoster.players?.length) {
			// console.log('copyFromRoster', copyFromRoster)

			//
			// Set roster
			//
			if (!erase) {
				obj.best.roster = copyFromRoster.roster
				obj.nullPositions = copyFromRoster.nulled || [ ]
			}

			obj.best.orangeman = copyFromRoster.orangeman

			//
			// Set player availability: first set everyone's availabilty
			// to false, then switch it to true for the specified players.
			//
			obj.available = { }

			obj.players.forEach(player =>
				obj.available[player] = false
			)

			copyFromRoster.players.forEach(player => {
				//
				// Ignore '???' players
				//
				if (player !== '???')
					obj.available[player] = true
			}
			)
		}

		//
		// The initialRoster may have set some or all of our fields like
		// 'roster', 'orangeman', etc. Let's fill in any missing ones with
		// default values.
		//

		if (!obj.available) {

			obj.available = { }

			//
			// Default to all players available.
			//
			obj.players.forEach(player =>
				obj.available[player] = true
			)

			/* Hmmmmmmmmmmmmmmmm
			//
			// Dummy players are unavailable
			//
			if (dummyPlayers) {
				dummyPlayers.forEach(player =>
					obj.available[player] = false
				)
			}
			*/

		}

		if (!obj.preferences || !Object.keys(obj.preferences).length) {
			obj.preferences = { }
			obj.players.forEach(player => obj.preferences[player] = new Array(fieldPositions.length).fill(0))
		}

		obj.players = this.sortThesePlayers(obj.players, obj.available)

		if (!obj.best.roster) {
			obj.best.roster = [ ]
		}

		if (!erase)
			obj.madeChanges = false

		if (isSingleRoster)
			obj.editMode = true

		obj.rosterIsStale = false

		// console.log("Calculated", obj)

		this.saveState(obj)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.loading === true && this.props.loading === false) {
			this.calculateInitialValues()
		}
	}

	render() {

		const { ready, editMode, madeChanges, rosterIsStale, showAltBoard, showPositionPreferences, progress, best, players, available, positions, locks, preferences, preferenceType, nullPositions } = this.state
		const { section, game, playerKey, netStats, showNetStats, userCanEdit, isSingleRoster, isTrials, onTourOpen, tourAction } = this.props

		const roster = best && best.roster

		const haveSetPositions = Object.values(preferences).filter(arr => arr.filter(v => v).length).length ? true : false

		const rosterNetStats = showNetStats && calculateRosterNetStats({
			netStats,
			roster,
			playerKey,
			nullPositions,
			available,
		})

		// console.log('rosterNetStats', rosterNetStats)

		// console.log('Roster.js render', this.props, this.state)

		const data = parseRoster({
			roster,
			positions,
			players,
			netStats,
			playerKey,
		})

		const myClasses = [
			'roster-container',
			`roster-${editMode ? 'un' : ''}locked`,
		]
		if (showPositionPreferences) {
			myClasses.push('roster-position-preferences')
		} else {
			if (showAltBoard && editMode) {
				myClasses.push('roster-edit-altboard')
			}
			if (showAltBoard) {
				myClasses.push('roster-altboard')
			}
		}

		const content = (
			<div className={myClasses.join(' ')}>
				<ControlBox
					ready={ready}
					isSingleRoster={isSingleRoster}
					editMode={editMode}
					madeChanges={madeChanges}
					haveSetPositions={haveSetPositions}
					numPlayers={players.length}
					showAltBoard={showAltBoard}
					showPositionPreferences={showPositionPreferences}
					userCanEdit={userCanEdit}
					onSave={this.saveRoster}
					onEdit={e => { tourAction && tourAction(e); this.toggleEditMode && this.toggleEditMode(e); }}
					onTourOpen={onTourOpen}
					onToggleAltBoard={e => { tourAction && tourAction(e); this.toggleAltBoard(); }}
					onTogglePositionPreferences={this.togglePositionPreferences}
				/>
				<ProgressBar
					variant="determinate"
					value={progress}
				/>
				<Warnings
					playerKey={playerKey}
					players={players}
					section={section}
					editMode={editMode}
					isSingleRoster={isSingleRoster}
				/>
				<DisplayRoster
					ready={ready}
					isSingleRoster={isSingleRoster}
					isTrials={isTrials}
					data={data}

					roster={roster}
					editMode={editMode}
					players={players}
					available={available}
					positions={positions}
					locks={locks}
					preferences={preferences}
					playerKey={playerKey}
					nullPositions={nullPositions}
					netStats={netStats}
					toggleLock={this.toggleLock}
					toggleAvailable={this.toggleAvailable}
					setPreference={this.setPreference}
					showNetStats={showNetStats && this.state.settings.showNetStats}
					swapPositions={this.swapPositions}
					swapPlayers={this.swapPlayers}
					findAltPosition={this.findAltPosition}
					renamePlayer={this.renamePlayer}
					showAltBoard={showAltBoard}
					showPositionPreferences={showPositionPreferences}
					toggleAltBoard={this.toggleAltBoard}
					togglePositionPreferences={this.togglePositionPreferences}
					setAmDragging={this.setAmDragging}
					setOrangeman={this.setOrangeman}
				/>
				{
					editMode && showPositionPreferences && (
						<PositionPreferencesSetting
							showPositionPreferences={showPositionPreferences}
							editMode={editMode}
							preferenceType={preferenceType}
							updatePositionPreferenceType={this.updatePositionPreferenceType}
						/>
					)
				}
				{
					!isSingleRoster && showNetStats && this.state.settings.showNetStats && (
						<>
							<RateNetStats
								rosterNetStats={rosterNetStats}
							/>
							<DisplayScores
								game={game}
								section={section}
								editMode={editMode}
							/>
						</>
					)
				}
				{
					!editMode &&
						<AwayPlayers
							available={available}
						/>
				}
				<div className="underneath-grid">
					<Orangeman
						orangeman={best && best.orangeman}
						players={players}
						setOrangeman={this.setOrangeman}
						editMode={editMode}
						onClick={e => tourAction && tourAction(e)}
					/>
					<GoButton
						ready={ready}
						highlight={!roster || !roster.length || rosterIsStale}
						onAutoRoster={this.go}
					/>
				</div>
				{
					isSingleRoster ? null : (
						<DeleteButton
							show={editMode}
							onClick={this.eraseRoster}
						/>
					)
				}
				<AddPlayerButton
					show={isSingleRoster && editMode}
					onAddPlayer={this.addPlayer}
					onRemoveUnusedPlayers={this.removePlayers}
					onEraseRoster={this.eraseRoster}
					players={players}
					roster={roster}
					positions={positions}
					ready={ready}
				/>
				<Settings
					show={editMode}
					isSingleRoster={isSingleRoster}
					updateSettings={this.updateSettings}
					haveNetStats={Object.keys(netStats).length > 0}
				/>
				{
					isSingleRoster || (
						<Share
							name="Roster"
							section={section}
						/>
					)
				}
			</div>
		)

		return (
			<FadeInBox pose={roster ? 'visible' : 'hidden'}>
				{content}
			</FadeInBox>
		)
	}

	toggleEditMode() {

		const { editMode } = this.state

		this.saveState({
			editMode: !editMode,
		})
	}

	toggleAltBoard() {

		const { showAltBoard } = this.state

		this.saveState({
			showAltBoard: !showAltBoard,
			showPositionPreferences: false,
		})
	}

	togglePositionPreferences() {

		const { showPositionPreferences } = this.state

		this.notifyTour()

		this.saveState({
			showPositionPreferences: !showPositionPreferences,
		})
	}

	setOrangeman(player) {
		const best = this.state.best || { }

		best.orangeman = player

		this.saveState({
			best,
			madeChanges: true,
		})
	}

	haveRoster() {

		const { best } = this.state

		if (best && best.roster && best.roster.length)
			return true

		return false
	}

	toggleLock(i, event) {

		const { nullPositions, locks } = this.state

		if (this.haveRoster()) {

			//
			// If we're holding down the control key, interpret it as setting
			// null positions.
			//
			if (event.ctrlKey) {
				const newNullPositions = nullPositions.slice()
				newNullPositions[i] = !newNullPositions[i]
				this.setState({
					nullPositions: newNullPositions,
					madeChanges: true,
				})
			} else {

				const newLocks = locks.slice()

				newLocks[i] = !newLocks[i]

				this.setState({
					locks: newLocks,
				})
			}
		}
	}

	toggleAvailable(player) {
		console.log('toggleAvailable', player)

		const { isSingleRoster, playerKey } = this.props

		if (!isSingleRoster && !playerKey[player]) {
			console.log('Ignoring attempt to toggle availability of dummy player', player)
			return
		}

		const newAvailable = Object.assign({ }, this.state.available)
		newAvailable[player] = !newAvailable[player]
		this.saveState({
			available: newAvailable,
			needSort: new Date(),
			madeChanges: true,
			rosterIsStale: true,
		})
		setTimeout(this.sortPlayers, sortPlayersDelay)
	}

	swapPositions(from, to) {
		// console.log('swapPositions', from, to, this.state.positions.length)

		const { best, positions } = this.state

		if (Math.floor(from / positions.length) !== Math.floor(to / positions.length)) {
			console.error("Illegal request to swap positions", from, to)
		} else if (this.haveRoster()) {
			const newBest = Object.assign({ }, best)
			const temp = newBest.roster[from]
			newBest.roster[from] = newBest.roster[to]
			newBest.roster[to] = temp
			this.saveState({
				best: newBest,
				madeChanges: true,
			})
		}
	}

	swapPlayers(from, to) {

		function swapPlayer(player) {
			if (player === from)
				return to
			else if (player === to)
				return from
			return player
		}

		if (this.haveRoster()) {

			const { best, players } = this.state

			const newPlayers = players.map(player => swapPlayer(player))

			const newBest = Object.assign({ }, best)

			newBest.roster = best.roster.map(player => swapPlayer(player))

			this.saveState({
				players: newPlayers,
				best: newBest,
				needSort: new Date(),
				madeChanges: true,
			})

			setTimeout(this.sortPlayers, sortPlayersDelay)
		}
	}

	findAltPosition(player, qtr) {

		const { best } = this.state
		const roster = best?.roster

		const playersPerQtr = roster.length / 4

		for (let i = qtr * playersPerQtr; i < (qtr + 1) * playersPerQtr; i += 1) {
			if (roster[i] === player) {
				// console.log("Converted", player, 'qtr', qtr, 'to i', i)
				return i
			}
		}

		console.error("Failed to locate position for player", player, "qtr", qtr, "in roster", roster)
		return null
	}

	//
	// For single rosters: rename a player
	//
	renamePlayer(props) {
		// console.log('renamePlayer', props)

		const { oldName, newName, index } = props

		const { players, available, preferences, best } = this.state

		const nameAlreadyUsed = players.filter(playerName => playerName === newName).length
		if (nameAlreadyUsed) {
			return null
		}

		if (newName && oldName !== newName) {

			const newState = { }

			//
			// Modify 'players'
			//
			const newPlayers = players.slice()
			newPlayers[index] = newName
			newState.players = newPlayers

			//
			// Modify 'available'
			//
			const newAvailable = Object.assign({ [newName]: true }, available)
			delete newAvailable[oldName]
			newState.available = newAvailable

			//
			// Modify 'preferences'
			//
			const newPreferences = Object.assign({ [newName]: preferences[oldName].slice() }, preferences)
			delete newPreferences[oldName]
			newState.preferences = newPreferences
			// console.log('woo set new preferences to', newPreferences)

			//
			// Modify 'best.roster'
			//
			if (best && best.roster) {
				const newRoster = best.roster.map(playerName =>
					playerName === oldName ? newName : playerName
				)
				const newBest = Object.assign({ }, best)
				newBest.roster = newRoster

				if (newBest.orangeman === oldName) {
					newBest.orangeman = newName
				}

				newState.best = newBest
			}

			this.saveState(newState)
		}
	}

	//
	// For single rosters: add a player
	//
	addPlayer() {
		const { players, available, preferences, best } = this.state

		const newState = { }

		this.notifyTour()

		const newPlayerName = generateNewPlayerName(players)

		//
		// Modify 'players'
		//
		const newPlayers = players.slice()
		newPlayers.push(newPlayerName)
		newState.players = newPlayers

		//
		// Modify 'available'
		//
		const newAvailable = Object.assign({ }, available)
		newAvailable[newPlayerName] = true
		newState.available = newAvailable

		//
		// Modify 'preferences'
		//
		const newPreferences = Object.assign({ }, preferences)
		newPreferences[newPlayerName] = new Array(fieldPositions.length).fill(0)
		newState.preferences = newPreferences

		//
		// Modify 'positions'
		//
		const newPositions = calculatePositions(newPlayers.length)
		newState.positions = newPositions

		//
		// Modify arrays
		//
		const toModify = this.modifyOnPlayerNumberChange(newPlayerName)
		Object.keys(toModify).forEach(id => {
			const item = toModify[id]
			if (item.data && item.data.length) {
				const oldData = item.data
				const newData = new Array(numPeriods * newPlayers.length)
				// console.log('initialized new', id, 'to', newData.length, 'elements')
				for (let qtr = 0; qtr < numPeriods; qtr += 1) {
					for (let i = 0; i < players.length; i += 1) {
						const newIndex = qtr * newPlayers.length + i
						const oldIndex = qtr * players.length + i
						newData[newIndex] = oldData[oldIndex]
						// console.log('Set', id, newIndex, 'from', oldIndex, oldData[oldIndex])
					}
					const extraIndex = qtr * newPlayers.length + players.length
					newData[extraIndex] = item.defaultValue
					// console.log('Set', id, extraIndex, 'as', item.defaultValue)
				}
				// console.log("New data has ", newData.length, "elements.", newData)

				if (id === 'best.roster') {
					newState.best = Object.assign(best, { roster: newData })
				} else {
					newState[id] = newData
				}
			}
		})

		newState.rosterIsStale = true

		// console.log('newState', newState)
		this.saveState(newState)
	}

	//
	// For single rosters: remove all unusued players
	//
	removePlayers(unusedPlayers) {
		// console.log("let's remove these unusued players", unusedPlayers)

		const { players, available, preferences, best } = this.state

		this.notifyTour()

		const newState = { }

		const newPlayers = players.filter(playerName => unusedPlayers.filter(unusedPlayer => unusedPlayer === playerName).length ? false : true)
		newState.players = newPlayers

		const newAvailable = Object.assign({ }, available)
		unusedPlayers.map(unusedPlayer => delete newAvailable[unusedPlayer])
		newState.available = newAvailable

		const newPreferences = Object.assign({ }, preferences)
		unusedPlayers.map(unusedPlayer => delete newPreferences[unusedPlayer])
		newState.preferences = newPreferences

		const newPositions = calculatePositions(newPlayers.length)
		newState.positions = newPositions

		//
		// Modify arrays
		//
		// 1. Go through best.roster and record the indexes of unused players.
		//    (We can't assume they're at the end because they might be mixed
		//    in with benched players.)
		// 2. Go through the arrays and cut out those array elements.
		//
		const unusedIndexes = best.roster.map((playerName, index) => {
			if (unusedPlayers.filter(unusedPlayer => unusedPlayer === playerName).length) {
				return index
			} else {
				return null
			}
		}).filter(i => i !== null)

		// console.log("Unused indexes", unusedIndexes)

		const toModify = this.modifyOnPlayerNumberChange()
		Object.keys(toModify).forEach(id => {
			const item = toModify[id]
			if (item.data && item.data.length) {
				const newData = item.data.filter((value, index) =>
					unusedIndexes.filter(unusedIndex => unusedIndex === index).length ? false : true
				)

				// console.log("New data has ", newData.length, "elements.", newData)

				if (id === 'best.roster') {
					newState.best = Object.assign(best, { roster: newData })
					//
					// Check orangeman as well
					//
					if (unusedPlayers.filter(unusedPlayer => unusedPlayer === newState.best.orangeman).length) {
						newState.best.orangeman = null
					}
				} else {
					newState[id] = newData
				}
			}
		})

		// console.log('newState', newState)
		this.saveState(newState)
	}

	//
	// Modify data structures that are Arrays of length = numPeriods * numPlayers
	//
	modifyOnPlayerNumberChange(newPlayerName) {
		const { best, locks, nullPositions } = this.state

		return {
			'best.roster': {
				data: best.roster,
				defaultValue: newPlayerName,
			},
			'locks': {
				data: locks,
				defaultValue: false,
			},
			'nullPositions': {
				id: 'nullPositions',
				data: nullPositions,
				defaultValue: false,
			},
		}
	}

	//
	// Position Preferences
	//
	setPreference(props) {
		const { player, positionIndex, value } = props
		const { preferences } = this.state

		// console.log('setPreference', props, preferences)

		const newPreferences = Object.assign({ }, preferences)
		newPreferences[player][positionIndex] = value
		this.saveState({
			preferences: newPreferences,
			rosterIsStale: true,
		})
	}

	sortedPlayers() {
		const { players, available } = this.state

		return this.sortThesePlayers(players, available)
	}

	sortThesePlayers(players, available) {
		return players.slice().sort((a, b) => {
			let result = available[b] - available[a]
			if (!result) {
				const aStr = a.toLowerCase()
				const bStr = b.toLowerCase()
				if (aStr > bStr) {
					result = 1
				} else if (aStr < bStr) {
					result = -1
				} else {
					result = 0
				}
			}
			return result
		})
	}

	sortPlayers(force) {

		const { isSingleRoster } = this.props

		if (!force) {
			if (amDragging || isSingleRoster || !needSort || Date.now() - needSort < sortPlayersDelay) {
				return
			}
		}

		this.saveState({
			players: this.sortedPlayers()
		})
	}

	//
	// No longer used
	//
	clearPins() {
		const { positions } = this.state

		const locks = new Array(positions.length * numPeriods).fill(false)
		this.setState({
			locks,
		})
	}

	setAmDragging(value) {
		amDragging = value
		needSort = new Date()

		if (!value) {
			setTimeout(this.sortPlayers, sortPlayersDelay)
		}
	}

	//
	// Can be sent either an object { id: 'share-ball', value: 5 },
	// which adds/modifies that entry, or else an array of such objects,
	// which replaces the existing value of this.state.settings.
	//
	updateSettings(obj) {

		const { settings } = this.state

		// console.log('updateSettings', obj, settings)

		const newState = { }

		let newSettings

		if (obj.id !== undefined) {
			newSettings = Object.assign({ }, settings)
			newSettings[obj.id] = obj.value
		} else {
			newSettings = obj
		}

		newState.settings = newSettings

		if (settings && Object.keys(settings).length) {
			newState.rosterIsStale = true
		}

		this.saveState(newState)
	}

	updatePositionPreferenceType(preferenceType) {
		this.saveState({
			preferenceType,
		})
	}

	notifyTour() {
		const { tourAction } = this.props

		tourAction && tourAction()
	}

	go() {
		const { best, players } = this.state
		const { isSingleRoster } = this.props

		let unsubscribe

		this.notifyTour()

		const orangeman = best && best.orangeman
		const startingPhenotype = (best && best.roster && best.roster.length) ? best : createEmptyPhenotype(players)
		startingPhenotype.orangeman = orangeman

		this.setState({
			best: startingPhenotype,
			showPositionPreferences: false,
			rosterIsStale: false,
		})

		const goReady = props => {
			const { items } = props

			// console.log('goReady with props', props)

			this.setState({
				players: this.sortedPlayers(),
				phenotype: startingPhenotype,
				ready: false,
				progress: 0,
				fetchDataListener: unsubscribe,
				stats: items ? this.parseStats(items) : [ ]
			}, () => {
				const { players, positions, locks, available, preferences, preferenceType, stats, settings } = this.state
				const { netStats, playerKey } = this.props

				generateRoster({
					onProgress: this.onProgress,
					startingPhenotype: startingPhenotype,
					players,
					positions,
					locks,
					available,
					preferences,
					preferenceType,
					stats,
					netStats,
					playerKey,
					settings,
				})
			})
		}

		if (isSingleRoster) {
			//
			// set players or something?
			//
			goReady({
				isSingleRoster,
			})

		} else {

			const requests = [ 'games', 'players', 'rosters' ].map(key => (
				{
					name: key,
					section: this.props.section,
				})
			)

			let haveStarted = false

			unsubscribe = fetchDataSet(requests, (items, callbackType) => {

				const { editMode } = this.state

				// console.log('setting data', requests, items, callbackType, editMode)

				if (callbackType === 'cache' && editMode && haveStarted) {
					//
					// When we save, this triggers a data update, but we don't want to
					// perform another AutoRoster at that point.
					//

					// console.log('Ignoring cache update')

				} else {
					// console.log('Processing update', callbackType)

					haveStarted = true

					goReady({
						items,
					})
				}
			})
		}

		analytics.logEvent('autoroster-go', {
			numPlayers: this.state.players.length,
		});
	}

	onProgress(newState) {
		if (newState.best) {
			newState.madeChanges = true
		}
		this.saveState(newState)
	}

	saveRoster() {
		const { best, available } = this.state
		const { initialRoster } = this.props

		if (!initialRoster
			|| !initialRoster.roster
			|| !initialRoster.roster.length
			|| window.confirm('Overwrite previously saved roster?')
		) {

			const success = this.props.setRoster({
				roster: best.roster,
				players: Object.keys(available).filter(player => available[player]),
				orangeman: best.orangeman,
				nulled: this.compressNullPositions(),
			})

			if (success) {
				this.saveState({
					madeChanges: false,
				})

				console.log('Saved')
			}
		}
	}

	eraseRoster() {

		const { isSingleRoster } = this.props
		const { players, preferences } = this.state

		if (window.confirm('Are you sure you want to erase this roster?')) {
			const fromRoster = isSingleRoster && {
				roster: [ ],
				orangeman: null,
				players,
				preferences,
			}
			this.calculateInitialValues(true, fromRoster)
			this.setState({
				madeChanges: true,
			})
		}
	}

	compressNullPositions() {

		const { nullPositions } = this.state

		const compressed = [ ]

		if (nullPositions && nullPositions.length) {

			nullPositions.forEach((value, index) => {
				if (value === true) {
					compressed.push(index)
				}
			})
		}

		return compressed
	}

	parseRoster(phenotype) {
		const { positions, players } = this.state

		const { roster } = phenotype

		const data = players.reduce((map, player) => {
			map[player] = [ ]
			return map
		}, { })

		for (let i = 0; i < roster.length; i += 1) {
			const qtr = Math.floor(i / positions.length)
			const player = roster[i]
			const position = positions[i % positions.length]
			data[player][qtr] = position
		}

		return data
	}

	parseStats(data) {

		const parsed = parseData(data, true, this.props.game.date)

		const reverseKey = { }
		Object.keys(this.props.playerKey).forEach(playerName =>
			reverseKey[this.props.playerKey[playerName]] = playerName
		)

		const obj = { }
		Object.keys(parsed).forEach(playerId => {
			const playerName = reverseKey[playerId]

			obj[playerName] = {
				lastPositions: parsed[playerId].lastGame ? parsed[playerId].games[parsed[playerId].lastGame] : new Array(numPeriods).fill(null),
				allPositions: parsed[playerId].positions,
			}
		})

		return obj
	}

	//
	// Like setState(), but also saves to localStorage in SingleRoster mode.
	//
	saveState(newState, postHookCallback) {

		const { isSingleRoster } = this.props

		this.setState(newState, () => {
			if (postHookCallback) {
				postHookCallback()
			}
			if (isSingleRoster) {
				const { players, available, preferences, best } = this.state

				const data = {
					players,
					preferences,
					roster: {
						players: players.filter(player => available[player]),
						roster: best ? best.roster : [ ],
						orangeman: best ? best.orangeman : null,
						nulled: this.compressNullPositions(),
					},
				}
				saveToLocalStorage(data, singleRosterLocalStorageKey)
			}
		})
	}
}

function calculateRosterNetStats(props) {
	const { netStats, roster, playerKey, nullPositions, available } = props

	// console.log('calculateRosterNetStats()', props, netStats)

	if (!roster || !netStats || !Object.keys(netStats).length)
		return null

	const numPositions = roster.length / 4

	const qtrs = [ 0, 0, 0, 0 ]

	const BENCHED_POSNUMBER		= 7

	const cells = roster.map((playerName, index) => {

		const qtr = Math.floor(index / numPositions)
		const playerId = playerKey[playerName]

		if (!playerId)
			return 0

		let posNumber
		if (nullPositions[index]) {
			posNumber = BENCHED_POSNUMBER
		} else if (!available[playerName]) {
			posNumber = BENCHED_POSNUMBER
		} else {
			posNumber = Math.min(index % numPositions, 7)
		}

		const score = netStats[playerId].normalized[posNumber]
		// console.log(playerName, playerId, score, 'in', posNumber, 'qtr', qtr)
		if (score) {
			qtrs[qtr] += score
		}
		return score
	})

	return {
		cells,
		qtrs,
		total: qtrs.reduce((total, score) => total + score, 0),
	}
}

const RateNetStats = props => {
	const { rosterNetStats } = props

	if (!rosterNetStats)
		return null

	// console.log('RateNetStats', rosterNetStats)

	const { qtrs } = rosterNetStats

	const matchNetScore = qtrs.reduce((total, score) => total + score, 0)

	return (
		<div className="undergrid undergrid-netstats">
			<div className="grid">
				<div className="column column-players">
					<div className="fixed-element">
						<div className={`cell cell-position cell-void cell-netstat cell-netstat-${matchNetScore < 0 ? 'negative' : 'positive'}`}>
							<BiStats /> {matchNetScore < 0 ? '' : '+'}{matchNetScore.toFixed(1)}
						</div>
					</div>
				</div>
				{
					qtrs.map((score, index) => (
						<div
							key={index}
							className="column column-positions"
						>
							<div className="fixed-element">
								<div className={`cell cell-position cell-void cell-netstat cell-netstat-${score < 0 ? 'negative' : 'positive'}`}>
									{score < 0 ? '' : '+'}{score.toFixed(1)}
								</div>
							</div>
						</div>
					))

				}
			</div>
		</div>
	)
}

const Warnings = props => {

	const { players, playerKey, editMode, isSingleRoster, section } = props

	if (!editMode)
		return null

	if (isSingleRoster)
		return null

    let str

	if (players.filter(playerName => !playerKey[playerName]).length) {
        str = "This roster cannot be saved. Set up your team's players first."
    } else if (players.length !== Array.from(new Set(players)).length) {
        str = "Two players share the same name - this will cause bugs. Please rename a player."
    }

    if (!str)
		return null

	const search = generateSearchString({
		section,
	})

    return (
        <div className="roster-warning">
            <span>
                <FaExclamationTriangle />
                {' '}
                {str}
            </span>
            {' '}
            <Link
                to={{
                    pathname: '/players',
                    search,
                }}
                className="button"
            >
                <FaUserFriends />
                {' '}
                Players
            </Link>
        </div>
    )
}

export default Roster
