import React from 'react'
import { Link } from 'react-router-dom'
import { generateSearchString } from './util'

const MultiPageTitle = ({ section, title, altTitles }) => {

	const search = generateSearchString({
		section,
	})

	return (
		<div className="list-title">
			<div className="list-title-main">
				<h2>
					{title}
				</h2>
			</div>
			<div className="list-title-alternates">
				{
					altTitles && altTitles.map(altTitle =>
						<h2 key={altTitle.title}>
							<Link
								to={{
									pathname: altTitle.pathname,
									search,
								}}
							>
								{altTitle.title}
							</Link>
						</h2>
					)
				}
			</div>
		</div>
	)
}

export default MultiPageTitle
