import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { TiTimes } from 'react-icons/ti'
import orange from './orange.svg'
import "./orangeman.css"

const Orangeman = props => {
	const { orangeman, players, setOrangeman, editMode, onClick } = props

	if (!orangeman && !editMode)
		return <div />

	return (
		<div className="orangeman" onClick={onClick} >
			<img className="orange-image" src={orange} alt="Oranges" />
			{
				players && players.length && (
					<FormControl className="select-orangeman" disabled={!editMode}>
						<Select
							value={orangeman || ''}
							onChange={e => setOrangeman(e.target.value)}
							onOpen={onClick}
						>
							{
								players.map(playerName =>
									<MenuItem key={playerName} value={playerName}>{playerName}</MenuItem>
								)
							}
						</Select>
					</FormControl>
				)
			}
			{
				orangeman &&
					<TiTimes
						className="clear-orangeman"
						onClick={() => setOrangeman(null) }
					/>
			}
		</div>
	)
}

export default Orangeman
