import './errorfallback.css'
import netball from './netball.svg'

const ErrorFallback = props => {

	const { error, resetErrorBoundary } = props

	console.log('my error is', error)

	return (
		<div className="error-boundary">
			<div className="error-title">
				Oh no! Sorry, something went wrong.
				<img src={netball} alt="" className="netball-icon" />
			</div>
			<div className="let-max-know">
				If this problem persists, please let me know via
				{' '}
				<a href="https://twitter.com/SquiggleAFL">Tweet</a>
				{' '}
				or
				{' '}
				<a href="mailto:max_barry@maxbarry.com?subject=AutoRoster error" >email</a>
				.
			</div>
			<fieldset>
				<legend>Details</legend>
				<p>
					<span className="error-subtitle">
						Error:
					</span>
					{error?.message}
				</p>
				<p>
					<span className="error-subtitle">
						Page:
					</span>
					{window.location.search}
				</p>
				<p>
					<span className="error-subtitle">
						Stack:
					</span>
					{error?.stack}
				</p>
			</fieldset>
			<p>
				<button
					onClick={resetErrorBoundary}
				>
					Try Reloading
				</button>
				{' '}
				or
				{' '}
				<a href="/">Go to home page</a>
			</p>
		</div>
	)
}

export default ErrorFallback
