import React, { useState } from 'react'
import Tour from 'reactour'
import { FreeFocusInside } from 'react-focus-lock'
import Roster from './Roster'
import { FaRandom } from 'react-icons/fa'
import { TiPin, TiTick } from 'react-icons/ti'
import { MdEdit } from 'react-icons/md'
import './tour.css'

/*
	Players and positions can both be <b>dragged</b> around. AutoRoster remembers
	where you put them when you come back later.
*/

let onClick

//
// *** IMPORTANT ***
//
// When adjusting this, make sure you update all the goTo refs.
//
const stepsForSingleRoster = [

	//
	// Step 0 / 1
	//
	{
		selector: '#board',
		content: (
			<>
				<h2>
					Welcome!
				</h2>
				<p>
					Let's make a netball roster!
				</p>
			</>
		),
	},

	//
	// Step 1 / 2
	//
	{
		selector: '.column-players',
		content: (
			<>
				<p>
					First you might want to
					{' '}
					<span className="tour-demo tour-demo-edit">
						<MdEdit /> rename
					</span>
					{' '}
					some players. Try it now!
				</p>
			</>
		),
	},

	//
	// Step 2 / 3
	//
	{
		selector: '.button-go',
		content: ({ goTo }) => {
			onClick = () => goTo(3)
			return (
				<>
					<p>
						Let's make a roster!
					</p>
					<p>
						Hit the
						{' '}
						<span className="tour-demo">
							<FaRandom /> Go
						</span>
						{' '}
						button.
					</p>
				</>
			)
		},
	},

	//
	// Step 3 / 4
	//
	{
		selector: '#board',
		content: (
			<>
				<p>
					AutoRoster reshuffles positions to make good rosters.
				</p>
				<p>
					But what makes a good roster?
					{' '}
					<span role="img" aria-label="Hmm...">
						🤔
					</span>
				</p>
			</>
		),
	},

	//
	// Step 4 / 5
	//
	{
		selector: '.settings',
		content: (
			<>
				<p>
					Use sliders to adjust the importance of each setting to you.
				</p>
				<p>
					<b>0</b> = <span role="img" aria-label="">🤷</span>. <b>10</b> = <span role="img" aria-label="">🔥🔥🔥</span>
				</p>
			</>
		),
	},

	//
	// Step 5 / 6
	//
	{
		selector: '.button-preferences',
		content: ({ goTo }) => {
			onClick = () => goTo(6)
			return (
				<>
					<p>
						Click <b>Positions</b> now.
					</p>
				</>
			)
		}
	},

	//
	// Step 6 / 7
	//
	{
		selector: '#board',
		content: (
			<>
				<p>
					On this page, you can suggest a few positions players should (or shouldn't) have, and
					let AutoRoster figure out how to put it all together. Click a few roles.
				</p>
			</>
		)
	},

	//
	// Step 7 / 8
	//
	{
		selector: '.button-go',
		content: ({ goTo }) => {
			onClick = () => goTo(8)
			return (
				<>
					<p>
						Now you've made changes, run AutoRoster again.
					</p>
					<p>
						Every time you hit Go, it will try to improve the current roster.
					</p>
				</>
			)
		},
	},

	//
	// Step 8 / 9
	//
	{
		selector: '#board',
		content: (
			<>
				<p>
					You might also want to tweak things manually! Drag players and positions around
					however you like.
				</p>
				<p>
					<TiPin /> means a position is pinned — AutoRoster won't move it.
					Click to toggle pins.
				</p>
			</>
		),
	},


	//
	// Step 9 / 10
	//
	{
		selector: '#board',
		content: (
			<>
				<p>
					You can drag player names to swap their entire rosters. And you can toggle their
					{' '}
					<span className="tour-demo tour-demo-available">
						<TiTick /> availability
					</span>
					{' '}
					for this game.
				</p>
			</>
		),
	},

	//
	// Step 10 / 11
	//
	{
		selector: '.button-toggleview',
		content: ({ goTo }) => {
			onClick = () => goTo(11)
			return (
				<p>
					If you prefer a classic roster format, switch views here.
				</p>
			)
		},
	},

	//
	// Step 11 / 12
	//
	{
		selector: '.button-delete button',
		content: "Need to start over? Here's your eraser.",
	},

	//
	// Step 12 / 13
	//
	{
		selector: '.add-player-buttons',
		content: () => {
			onClick = 'update'
			return (
				<p>
					You can add and remove players...
				</p>
			)
		},
	},

	//
	// Step 13 / 14
	//
	{
		selector: '.orangeman',
		content: () => {
			onClick = 'update'
			return (
				<p>
					And don't forget the oranges!
				</p>
			)
		},
	},
]

const stepsForTeamRoster = [

	//
	// Step 0 / 1
	//
	{
		selector: '#board',
		content: (
			<>
				<h2>
					Welcome!
				</h2>
				<p>
					Let's make a netball roster!
				</p>
			</>
		),
	},

	//
	// Step 1 / 2
	//
	{
		selector: '.button-go',
		content: ({ goTo }) => {
			onClick = () => goTo(2)
			return (
				<>
					<p>
						Let's make a roster!
					</p>
					<p>
						Hit the
						{' '}
						<span className="tour-demo">
							<FaRandom /> Go
						</span>
						{' '}
						button.
					</p>
				</>
			)
		},
	},

	//
	// Step 2 / 3
	//
	{
		selector: '#board',
		content: (
			<>
				<p>
					AutoRoster reshuffles positions to make good rosters.
				</p>
				<p>
					But what makes a good roster?
					{' '}
					<span role="img" aria-label="Hmm...">
						🤔
					</span>
				</p>
			</>
		),
	},

	//
	// Step 3 / 4
	//
	{
		selector: '.settings',
		content: (
			<>
				<p>
					Use sliders to adjust the importance of each setting to you.
				</p>
				<p>
					<b>0</b> = <span role="img" aria-label="">🤷</span>. <b>10</b> = <span role="img" aria-label="">🔥🔥🔥</span>
				</p>
			</>
		),
	},

	//
	// Step 4 / 5
	//
	{
		selector: '.button-preferences',
		content: ({ goTo }) => {
			onClick = () => goTo(5)
			return (
				<>
					<p>
						Click <b>Positions</b> now.
					</p>
				</>
			)
		}
	},

	//
	// Step 5 / 6
	//
	{
		selector: '#board',
		content: (
			<p>
				To make AutoRoster prefer (or avoid) players in particular positions, click here.
			</p>
		)
	},

	//
	// Step 6 / 7
	//
	{
		selector: '.button-go',
		content: ({ goTo }) => {
			onClick = () => goTo(8)
			return (
				<>
					<p>
						Now you've made a few changes, run AutoRoster again.
					</p>
					<p>
						Every time you hit Go, it will try to improve the current roster.
					</p>
				</>
			)
		},
	},

	//
	// Step 7 / 8
	//
	{
		selector: '#board',
		content: (
			<>
				<p>
					You might also want to tweak things manually! Drag players and positions around
					however you like.
				</p>
				<p>
					<TiPin /> means a position is pinned — AutoRoster won't move it.
					Click to toggle pins.
				</p>
			</>
		),
	},


	//
	// Step 8 / 9
	//
	{
		selector: '#board',
		content: (
			<>
				<p>
					You can drag player names to swap their entire rosters. And you can toggle their
					{' '}
					<span className="tour-demo tour-demo-available">
						<TiTick /> availability
					</span>
					{' '}
					for this game.
				</p>
			</>
		),
	},

	//
	// Step 9 / 10
	//
	{
		selector: '.button-delete button',
		content: "Need to start over? Here's your eraser.",
	},

	//
	// Step 10 / 11
	//
	{
		selector: '.orangeman',
		content: () => {
			onClick = 'update'
			return (
				<p>
					And don't forget the oranges!
				</p>
			)
		},
	},
]


const TourRoster = props => {

	const { loading, isSingleRoster } = props

	const [ isOpen, setIsOpen ] = useState(false)
	const [ update, setUpdate ] = useState()

	const tourAction = needUpdate => {
		// console.log("tourAction", onClick, needUpdate)
		if (isOpen && onClick) {
			if (onClick === 'close') {
				setIsOpen(false)
			} else if (onClick === 'update') {
				setUpdate("Update-" + Math.floor(Math.random() * 1000000))
			} else {
				onClick()
			}
		}
	}

	//
	// I use FreeFocusInside to prevent Reactour from trapping
	// focus during a Tour, which keeps grabbing focus back from
	// elements if you try to interact with them (e.g. rename
	// player, drag cell).
	//
	return (
		<FreeFocusInside>
			<Roster
				{...props}
				onTourOpen={() => setIsOpen(true)}
				tourAction={tourAction}
			/>
			<Tour
				steps={isSingleRoster ? stepsForSingleRoster : stepsForTeamRoster}
				isOpen={isOpen && !loading}
				onRequestClose={() => setIsOpen(false)}
				accentColor="#65b965"
				badgeContent={(curr, tot) => `Step ${curr} of ${tot}`}
				className="tour-helper"
				scrollDuration={400}
				update={update}
			/>
		</FreeFocusInside>
	)
}

export default TourRoster
