export const config = {
	apiKey: "AIzaSyA9pDtbeY6lt3bNZMwP1Yk829JcEfcli5o",
	authDomain: "autoroster.io",
	databaseURL: "https://netball-autoroster.firebaseio.com",
	projectId: "netball-autoroster",
	storageBucket: "",
	messagingSenderId: "419786536264",
	appId: "1:419786536264:web:f4da6ec1fb04b8be",
	measurementId: "G-L5CFFT7KK0"
}
