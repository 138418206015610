//
// Definitions
//

export const fieldPositions = [
	'GS', 'GA', 'WA', 'C', 'WD', 'GD', 'GK',
]

// Unfortunately this is duplicated in CSS
export const fieldPositionColors = [
	'#65b965', '#00c097', '#00bac0', '#60ade0', '#ab9dec', '#e38edf', '#ff86c0',
]

export const BENCHED = '-'
export const RAIN = 'nulled'

export const numPeriods = 4

export const playersPerTeam = fieldPositions.length
export const playersPerGame = playersPerTeam * 2

export const singleRosterLocalStorageKey = 'single-roster'

export const maxTrialsPlayers = 500

//
// If a player's never been tried in a position, assume they're not great
// at it.
//
export const NULL_NETSTAT_VALUE	= -1.25

export const NORMALIZE_MIN_QTRS	= 6
