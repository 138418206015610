import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Link } from 'react-router-dom'
import { SpinButton } from './definitionsPoses'
import { generateSearchString } from './util'
import { FaCalendarAlt, FaChartPie, FaListAlt, FaUserFriends, FaFlag } from 'react-icons/fa'
import { TiThMenu } from 'react-icons/ti'
import { MdHelpOutline } from 'react-icons/md'
import netball from './netball.svg'
import './menu.css'

const TESTING = false

const AppMenu = ({ section, showMenu, setShowMenu, user }) => {
	const [anchorEl, setAnchorEl] = React.useState(null)

	function handleClick(event) {
		setAnchorEl(document.getElementById('header-topline'))
		setShowMenu(!showMenu)
	}

	function handleClose() {
		setAnchorEl(null)
		setShowMenu(false)
	}

	const search = generateSearchString({
		section,
	})

	let displayUser = null
	if (user) {
		const userPhoto = user.photoURL && <img className="user-photo" src={user.photoURL} alt="" />
		displayUser = (
			<span>
				{userPhoto}
				<span className="user-info">
					<span className="user-name">
						{user.displayName}
					</span>
					<span className="user-email">
						{user.email}
					</span>
					<span className="user-phone">
						{user.phoneNumber}
					</span>
				</span>
			</span>
		)
	}

	return (
		<div className="menu">
			<SpinButton
				aria-controls="main-menu"
				aria-haspopup="true"
				pose={showMenu ? 'active' : 'inactive'}
				className="button-menu button-icon button-animated"
				title="Menu"
				type="button"
				onClick={handleClick}
			>
				<TiThMenu />
		</SpinButton>
		<Menu
			id="main-menu"
			className="menu-overlay"
			anchorEl={anchorEl}
			getContentAnchorEl={null}
			anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={handleClose}
		>
			<MenuItem
				component={Link}
				to={{
					pathname: "/home",
					search,
				}}
				onClick={handleClose}
			>
				<img src={netball} alt="" />
				Home
			</MenuItem>
			<MenuItem
				component={Link}
				to={{
					pathname: "/games",
					search,
				}}
				onClick={handleClose}
				disabled={!section.season}
			>
				<FaCalendarAlt />
				Games
			</MenuItem>
			<MenuItem
				component={Link}
				to={{
					pathname: "/players",
					search,
				}}
				onClick={handleClose}
				disabled={!section.season}
			>
				<FaUserFriends />
				Players
			</MenuItem>
			<MenuItem
				component={Link}
				to={{
					pathname: "/stats",
					search,
				}}
				onClick={handleClose}
				disabled={!section.season}
			>
				<FaChartPie />
				Stats
			</MenuItem>
			<MenuItem
				component={Link}
				to={{
					pathname: "/seasons",
					search,
				}}
				onClick={handleClose}
				disabled={!section.team}
			>
				<FaListAlt />
				Seasons
			</MenuItem>
			<MenuItem
				component={Link}
				to={{
					pathname: "/teams",
					search,
				}}
				onClick={handleClose}
				disabled={!user}
			>
				<FaFlag />
				Teams
			</MenuItem>
			<MenuItem
				component={Link}
				to="/help"
				onClick={handleClose}
			>
				<MdHelpOutline />
				Help
			</MenuItem>
			{
				TESTING ?
					null :
					(
						user ?
							<MenuItem
								component={Link}
								to="/logout"
								onClick={handleClose}
							>
								<span className="user-box">
									{displayUser}
									<span>
										Sign Out
									</span>
								</span>
							</MenuItem>
							:
							<MenuItem
								component={Link}
								to="/login"
								onClick={handleClose}
							>
								<span className="signin-box">
									Sign In / Register
								</span>
							</MenuItem>
					)
			}
		</Menu>
		</div>
	)
}

export default AppMenu
