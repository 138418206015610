import React from 'react'

//
// Using React.memo() here means that we won't re-render the component unless the props
// change -- largely useless because the onClick functions get rebuilt a lot for most
// cells, but it avoids re-renders of a few, like Header cells.
//
const GridCell = React.memo(props => {
	const { type, isAvailable, isNullPosition, content, richContent, editContent, positionName, icons, iconsRight, i, onClick, onClickIcon, onClickIconRight, children } = props

	const safeContent = typeof content === 'string' && content.replace(/ /, '-')
	const available = (isAvailable ? '' : 'un') + 'available'
	const nullPosition = (isNullPosition ? 'nulled' : 'normal')

	const iconbox = makeIconbox({
		i,
		icons,
		onClick: onClickIcon,
	})

	const iconboxRight = makeIconbox({
		i,
		icons: iconsRight,
		onClick: onClickIconRight,
		extraClass: 'iconbox-right',
	})

	const myContent = editContent || richContent || (
		<>
			{content}
			{iconbox}
			{iconboxRight}
		</>
	)

	const myClasses = [
		'cell',
		`cell-${type}`,
		`cell-${type}-${available}`,
		`cell-${type}-${nullPosition}`,
	]

	if (positionName) {
		myClasses.push(`cell-${type}-${positionName}`)
	} else {
		myClasses.push(`cell-${type}-${safeContent}`)
	}

	return (
		<div
			className={myClasses.join(' ')}
			data-i={i}
			onClick={event => onClick && onClick(i, event)}
		>
			<span>
				{myContent}
			</span>
			{children}
		</div>
	)
})

const makeIconbox = props => {

	const { i, icons, onClick, extraClass } = props

	if (icons) {
		const iconList = Array.isArray(icons) ? icons : [ icons ]

		if (iconList.length) {
			return (
				<div
					className={`iconbox ${extraClass || ''}`}
				>
					{
						iconList.map((icon, index) => (
							<span
								key={index}
								className="iconbox-hitbox"
								onClick={event => onClick && onClick(i, event)}
							>
								{icon}
							</span>
						))
					}
				</div>
			)
		}
	}
}

export default GridCell
