import React, { useMemo } from 'react'
import List from './List'

const Seasons = (props) => {

	const { section } = props

	const type = useMemo(() => ({
		name: 'seasons',
		unit: 'season',
		fields: [ 'name' ],
		pathname: '/games',
		dbid: `/teams/${section.team}/seasons`
	}), [ section.team ])

	return (
		<List
			{...props}
			type={type}
		/>
	)
}

export default Seasons
