import React, { useMemo } from 'react'
import List from './List'
import TimeAgo from 'react-timeago'
import { formatDate, gameDateClass, labelRound } from './util'
import { FaCalendarCheck, FaCalendarTimes } from 'react-icons/fa'
import { IoIosAlarm } from 'react-icons/io'
import orange from './orange.svg'
import "./games.css"

const Games = (props) => {
	const { section } = props

	const type = useMemo(() => ({
		name: 'games',
		unit: 'game',
		fields: [
			'round',
			'date',
			'venue',
			'opponent',
		],
		editOnlyFields: [ 'result' ],
		pathname: '/roster',
		linkToItem: true,
		formatField: props => <GameField {...props} />,
		extraItems: [ 'players', 'rosters' ],
		extraContent,
		quickAdding: true,
		sortFunction: (a, b) => a.date - b.date,
		filter: [
			{
				name: 'Future',
				filterFunction: game => game.date && game.date < Date.now() - 86400000,
				id: 'default',
			},
			{
				name: 'All Games',
				filterFunction: data => false,
				id: 'all',
			},
		],
		altTitles: [
			{
				pathname: '/players',
				title: 'Players',
			},
		],
		share: true,
		dbid: `/teams/${section.team}/seasons/${section.season}/games`,
	}), [ section.team, section.season ])

	return (
		<List
			{...props}
			type={type}
		/>
	)
}

const GameField = props => {
	const { field, value } = props

	let content
	let dateClass

	if (field === 'round') {
		content = labelRound(value)
	} else if (field === 'opponent') {
		content = value ? `vs ${value}` : null
	} else if (field === 'date') {
		content = formatDate(value, 'long')

		dateClass = gameDateClass(value)

		if (dateClass === 'present') {
			content = (
				<div>
					{content}
					<div className="game-current">
						<IoIosAlarm />
						<TimeAgo date={value} className="game-start" />
					</div>
				</div>
			)
		}
	} else if (field === 'result') {
		if (value !== null && typeof value === 'object' && value.me && value.them) {
			const resultStr = value.me[3] > value.them[3] ? 'Won' :
				value.me[3] < value.them[3] ? 'Lost' : 'Drew'

			content = (
				<>
					{resultStr}
					{' '}
					<b>
						{value.me[3]}
					</b>
					{' '}
					-
					{' '}
					<b>
						{value.them[3]}
					</b>
				</>
			)
		}
	} else {
		content = value
	}

	return (
		<div className={dateClass && `date-${dateClass}`}>
			{content}
		</div>
	)
}

const extraContent = props => {
	const { item, extraItems } = props
	const { rosters, players } = extraItems

	// console.log('woo extra content', props, item, extraItems)

	const obj = rosters && rosters[item.id]

	let lastUpdated = null
	if (obj && obj.created) {
		lastUpdated = obj.created && (
			<div className="field-created">
				Updated <TimeAgo date={obj.created} className="roster-created"/>
			</div>
		)
	}

	let roster = null
	if (obj && obj.roster && obj.roster.length) {
		roster = (
			<div className="field-roster">
				<FaCalendarCheck className="roster-yes" />
				Roster
			</div>
		)
	}

	let absent = null
	if (players && obj && obj.players) {
		const missingPlayers = Object.assign({ }, players)
		obj.players.forEach(playerId => delete missingPlayers[playerId])
		if (Object.keys(missingPlayers).length) {
			const absentStr = Object.values(missingPlayers).map(player => player.name).sort().join(', ')
			absent = (
				<div className="field-absent">
					<FaCalendarTimes className="roster-no" />
					{absentStr}
				</div>
			)
		}
	}

	let orangeman = null
	if (players && obj && obj.orangeman) {
		const player = players[obj.orangeman]

		if (player) {
			// Make sure orangeman isn't absent
			const warning = absent && obj.players.indexOf(obj.orangeman) === -1 && 'field-orangeman-illegal'

			orangeman = (
				<div className={`field-orangeman ${warning}`}>
					<img className="orange-image" src={orange} alt="Oranges" />
					{player.name}
				</div>
			)
		} else {
			console.error('Orangeman does not exist on this team any more:', obj.orangeman)
		}
	}

	return (
		<div className="extra-content">
			{absent}
			{orangeman}
			{roster}
			{lastUpdated}
		</div>
	)
}

export default Games
