import { Link } from 'react-router-dom'
import { BiStats } from 'react-icons/bi'
import "./news.css"

const News = props => {

	return (
		<div className="news">

			<p className="i-wanna">
				Latest updates
			</p>

			<div className="news-entry">
				<span className="news-date">
					Apr 25, 2023
				</span>
				<span className="news-content">
					A new version of AutoRoster is out, aimed at providing better mobile support
					and focused on team management. <a href="https://autoroster.io">You can find it here!</a>
					The new version doesn't have Trials nor Single Rosters, so you need this site for those.
				</span>
			</div>

			<div className="news-entry">
				<span className="news-date">
					Aug 13, 2022
				</span>
				<span className="news-content">
					You can now edit rosters in traditional roster format, with position names
					down the side and player names in columns. Click <b>TOGGLE VIEW</b> and <b>EDIT</b>.
				</span>
			</div>

			<div className="news-entry">
				<span className="news-date">
					Jun 7, 2022
				</span>
				<span className="news-content">
					The <b>POSITIONS</b> button now lets you set <em>preferred</em> positions
					for each player, rather than operating strictly as a this-person-must-play-here
					rule. I also fixed a bug that could produce stubborn, sub-optimal rosters
					that wouldn't improve.
				</span>
			</div>

			<div className="news-entry">
				<span className="news-date">
					May 27, 2022
				</span>
				<span className="news-content">
					<b>NetStats!</b> <BiStats /><br />Enter your game scores and let AutoRoster analyze which
					rosters delivered your best team performances. With this data, it can even generate rosters that optimize
					team strength.
					{' '}
					<Link
						to={{
							pathname: "/help",
							hash: 'managers',
						}}
					>
						Learn more.
					</Link>
				</span>
			</div>

		</div>
	)
}

export default News
