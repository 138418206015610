import React from 'react'
import { FaEraser } from 'react-icons/fa'

const DeleteButton = props => {
	const { show, text, onClick } = props

	if (!show)
		return null

	const buttonText = text || 'Erase Roster'

	return (
		<div className="button-delete">
			<button
				type="button"
				className="button-icon button-mixed"
				onClick={onClick}
			>
				<FaEraser />
				<span className="roster-button-text">
					{buttonText}
				</span>
			</button>
		</div>
	)
}

export default DeleteButton
