import React, { useState, useEffect } from 'react'
import FadeText from './FadeText'
import { TiLightbulb } from 'react-icons/ti'
import './funfact.css'

const initialNumPlayers = 7
const maxNumPlayers = 10
const minNumPlayers = 1

const precalc = [
	null, // 'zero',
	'two thousand', // '2,401',
	'three million', // '3,111,696',
	'one billion', // '1,944,810,000',
	'400 billion', // '497,871,360,000',
	'40 trillion', // '40,327,580,160,000',
	'600 trillion', // '645,241,282,560,000',
	'600 trillion', // '645,241,282,560,000',
	'2 quintillion', // '2,642,908,293,365,760,000',
	'10 sextillion', // '10,825,352,369,626,152,960,000',
	'44 septillion', // 44,340,643,305,988,722,524,160,000',
	'181 octillion', // '181,619,274,981,329,807,458,959,360,000',
	'743 nonillion', // '743,912,550,323,526,891,351,897,538,560,000',
	'3 undecillion', // '3,047,065,806,125,166,146,977,372,317,941,760,000',
]

const FunFact = () => {

	const [ numPlayers, setNumPlayers ] = useState(initialNumPlayers)
	const [ numCombinations, setNumCombinations ] = useState()

	useEffect(() => {
		const timer = setInterval(() => {
			let newNumber = numPlayers + 1
			if (newNumber > maxNumPlayers) {
				newNumber = minNumPlayers
			}
			setNumPlayers(newNumber)
		}, 6000)
		return () => clearInterval(timer)
	})

	useEffect(() => {
		setNumCombinations(precalc[numPlayers])
		//setNumCombinations(numberOfRosterCombinations(numPlayers).toLocaleString())
	}, [ numPlayers ])

	if (!numPlayers || !numCombinations)
		return null

	const playersString = 'player' + (numPlayers === 1 ? '' : 's')

	return (
		<div className="funfact">
			<TiLightbulb />
			Fun Fact: With
			{' '}
			<FadeText>
				{numPlayers}
			</FadeText>
			{' '}
			{playersString}, you can make
			{' '}
			<FadeText>
				{numCombinations}
			</FadeText>
			{' '}
			different netball rosters.
		</div>
	)
}

//
// How many different roster combinations are there for X players?
//
// 1 player: 7 x 7 x 7 x 7 ... or 7**4 ... which is 2,401 ways
// 2 players: (7 x 6) ** 4 = 3,111,696 ways
// 3 players: (7 x 6 x 5) ** 4
// 4 players: (7 x 6 x 5 x 4) ** 4
// 5 players: (7 x 6 x 5 x 4 x 3) ** 4
// 6 players: (7 x 6 x 5 x 4 x 3 x 2) ** 4
// 7 players: (7 x 6 x 5 x 4 x 3 x 2 x 1) ** 4
// 8 players: (8 x 7 x 6 x 5 x 4 x 3 x 2 x 1) ** 4
// 9 players: (8 x 8 x 7 x 6 x 5 x 4 x 3 x 2 x 1) ** 4
// 10 players: (8 x 8 x 8 x 7 x 6 x 5 x 4 x 3 x 2 x 1) ** 4
//

/*
const f = []

//
// Note: This suffers from JavaScript's Number limitations at
// high values, and starts rounding.
//
function numberOfRosterCombinations(numPlayers) {
	if (f[numPlayers])
		return f[numPlayers]

	const multipliers = [ ]
	for (let i = 0; i < numPlayers; i += 1) {
		// If there are 8+ players, there are 8 court positions
		// (7 on-court + bench).

		let n

		if (numPlayers < 7) {
			n = 7 - i
		} else {
			n = numPlayers - i
			if (n > 8) {
				n = 8
			}
		}
		multipliers.push(n)
	}
	// console.log('multiplers', multipliers, numPlayers, multipliers.reduce((total, n) => { return total * n }, 1))
	return f[numPlayers] = Math.pow(multipliers.reduce((total, n) => { return total * n }, 1), 4)
}
*/
export default FunFact
