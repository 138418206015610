import React from 'react'
import { FaEdit } from 'react-icons/fa'

const EditButton = props => {

	const { show, ready, editMode, text, icon, onClick, className } = props

	if (!show)
		return null

	const editButtonText = text || 'Edit'
	const editButtonIcon = icon || <FaEdit />

	return (
		<button
			type="button"
			className={'button-edit button-icon' + (className ? ` ${className}` : '') + (editMode ? ' enabled edit-mode' : '')}
			disabled={!ready}
			title="Toggle Edit Mode"
			onClick={e => { e.stopPropagation(); e.preventDefault(); onClick(e); }}
		>
			{editButtonIcon}
			<span className="roster-button-text">
				{editButtonText}
			</span>
		</button>
	)
}

export default EditButton
