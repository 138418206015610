import React from 'react'

const AwayPlayers = ({ available }) => {

	const players = Object.keys(available).filter(player => !available[player])

	if (!players.length)
		return null

	return (
		<div className="awayplayers">
			<p>
				<b>Away:</b>
				{' '}
				{
					players.sort().join(', ')
				}
			</p>
		</div>
	)
}

export default AwayPlayers
