import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { sectionToShortcut } from './util'
import { MdShare } from 'react-icons/md'
import { FiShare } from 'react-icons/fi'
import { FaShareSquare } from 'react-icons/fa'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import './share.css'

//
// NOTE: Calling navigator.share({ title: 'title', url: 'url' }) seems
// to crash Chrome at the moment, even though calling canShare() returns
// true. So I'm trying to limit this to touch devices only.
//

//
// This is a bit messy because it copies the URL to the Clipboard
// and THEN tries to use the Web Share API - which, if it succeeds,
// has no use for the Clipboard. So we overwrite the Clipboard
// unneccessarily in that situation. But I can't figure out how to
// do it otherwise.
//
const Share = props => {

	const { name, section } = props

	const userAgent = (navigator && navigator.userAgent) || ''
	const ShareIcon = userAgent.match('Android') ? ( <MdShare /> ) : userAgent.match('iPhone') ? ( <FiShare /> ) : ( <FaShareSquare /> )

	const shortcut = sectionToShortcut(section)
	const pageUrl = shortcut ? (window.location.origin + '/?' + sectionToShortcut(section)) : window.location.href
	const pageTitle = document.title

	const onClick = event => {

		if (navigator.share) {
			navigator
				.share({
					title: pageTitle,
					url: pageUrl,
				})
				.then(() => {
					console.log('Thanks for sharing!')
					// window.alert('Thanks for sharing!')
				})
				.catch(err => {
					console.error(err)
					// window.alert("Error: " + err)
					copyToClipBoard()
				})
		} else {
			copyToClipBoard()
		}
	}

	const [ open, setOpen ] = useState(false)

	const copyToClipBoard = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const title = name || ''

	return (
		<div className="share-area">

			<CopyToClipboard text={pageUrl}>
				<button className="button-icon button-mixed linkish button-share" type="button" title={`Share ${title}`} onClick={onClick}>
					{ShareIcon} Share {title}
				</button>
			</CopyToClipboard>

			<Dialog onClose={handleClose} aria-labelledby="link-copied-dialog" open={open}>
				<DialogTitle id="link-copied-dialog">Link Copied</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{pageTitle}
					</DialogContentText>
					<DialogContentText className="share-url">
						{pageUrl}
					</DialogContentText>
					<DialogContentText>
						Paste into a message to share a link to this page with others.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<button onClick={e => setOpen(false)}>
						OK
					</button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default Share
