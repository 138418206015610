import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Menu from './Menu'
import FadeText from './FadeText'
import { generateSearchString, formatDate, labelRound } from './util'
import netball from './netball.svg'

const SiteHeader = props => {

	const { team, section, location } = props

	const [ showMenu, setShowMenu ] = useState(false)

	const isFrontPage = location && location.pathname === '/home'
	const isTrials = location && location.pathname === '/trials'
	const isSingleRoster = location && (location.pathname === '/single-roster' || isTrials)

	const headerTitle = {
		text: (team && !isFrontPage && !isSingleRoster) ? team.name : (isTrials ? 'AutoRoster Trials' : 'AutoRoster for Netball'),
		link: team ? `/games` : '/',
	}

	const htLength = headerTitle.text.length
	const htClass = (
		htLength > 24 ? 'gigantic' :
		htLength > 20 ? 'huge' :
		htLength > 15 ? 'large' :
		htLength > 10 ? 'medium' :
		htLength > 6 ? 'small' : 'tiny'
	)

	const linkTo = {
		pathname: headerTitle.link,
		search: generateSearchString({
			section,
		})
	}

	const isBeta = false

	return (
			<header className="header">
				<section className="header-topline" id="header-topline">
					<Link
						to={linkTo}
						className="header-title"
					>
						<FadeText
							contentKey={headerTitle.text}
							className="header-title-block"
						>
							<div className={`header-title-teamname textlength-${htClass} ${isBeta ? 'header-beta' : ''}`}>
								{headerTitle.text}
							</div>
						</FadeText>
						<img src={netball} alt="" className="netball-icon" />
					</Link>
					<Menu
						{...props}
						section={section}
						showMenu={showMenu}
						setShowMenu={setShowMenu}
					/>
				</section>
				<UserDataWithLocation
					show={!isFrontPage && !isSingleRoster}
					{...props}
				/>
			</header>
	)
}

const UserData = props => {

	const { show, section, season, game, location } = props

	if (!show)
		return null

	const seasonName = season && (
		<div className="header-season-name">
			<FadeText>
				{season.name}
			</FadeText>
		</div>
	)

	const isRoster = game && ((section && section.showRoster) || (location && location.pathname === '/roster'))

	let gameName
	if (isRoster) {
		const lines = [ ]
		if (game.round)
			lines.push(labelRound(game.round))
		if (game.opponent)
			lines.push(`v ${game.opponent}`)

		if (lines.length) {
			gameName = (
				<div className="header-game-name">
					<FadeText>
						{lines.join(' ')}
					</FadeText>
				</div>
			)
		}
	}

	const gameVenue = isRoster && game.venue && (
		<div className="header-game-venue">
			<FadeText>
				{game.venue}
			</FadeText>
		</div>
	)

	const gameTime = isRoster && game.date && (
		<div className="header-game-date">
			<FadeText>
				{
					formatDate(game.date)
				}
			</FadeText>
		</div>
	)

	return (
		<section className="header-userdata" id="header-userdata">
			{seasonName}
			{gameTime}
			{gameVenue}
			{gameName}
		</section>
	)
}


const UserDataWithLocation = withRouter(UserData)

export default SiteHeader
