import React, { useState } from 'react'
import { db } from './firebase'
import posed, { PoseGroup } from 'react-pose'
import EditItem from './EditItem'
import { ucFirst, userCanAddItem } from './util'
import "./additem.css"

const Modal = posed.div({
	enter: {
		x: 0,
		opacity: 1,
		delay: 30,
		transition: {
			x: { type: 'spring', stiffness: 500, damping: 50 },
			default: { duration: 100 }
		}
	},
	exit: {
		x: -50,
		opacity: 0,
		transition: { duration: 100 }
	}
})

const AddItem = props => {

	const { onCancel, lastItem, type, team, user, items } = props

	const [ fieldData, setFieldData ] = useState({ })
	const [ lastSubmittedItem, setLastSubmittedItem ] = useState(lastItem)

	if (!userCanAddItem({
		type,
		user,
		team,
	})) {
		return null
	}

	if (type?.unit === 'team' || type?.unit === 'season') {
		return (
			<div class="this-is-v1">
				This is an archived version of AutoRoster, which can't
				add new teams or seasons. For the latest version,
				{' '}
				<a href="https://autoroster.io">
					go here
				</a>
				.
			</div>
		)
	}

	const missingFields = type.fields.filter(field => fieldData[field] === undefined)
	const haveMissingFields = missingFields.length ? true : false
	console.log('missing fields', missingFields)

	const handleSubmit = e => {
		const { dbid, quickAdding } = props

		e.preventDefault()

		if (haveMissingFields) {
			console.error("Attempt to create item with missing fields.", missingFields)
			return
		}

		fieldData.created = fieldData.created || Date.now()

		if (props.type.onAdd) {
			const extraFields = props.type.onAdd(fieldData, props)
			Object.keys(extraFields).forEach(key =>
				fieldData[key] = extraFields[key]
			)
		}

        // Prevent adding a new player with the same name as an existing player, because that
        // throws errors in rosters.
        if (type.unit === 'player' && items?.filter(obj => obj.name === fieldData.name)?.length) {
            console.log("Name dupe!")
            window.alert("Multiple players may not share the same name.")
        } else {

            db.collection(dbid).add(fieldData).then(docRef =>
                console.log('doc written', docRef.id)
            ).catch(error =>
                console.error('Failed to write doc!', error)
            )

            setLastSubmittedItem(fieldData)

            setFieldData({ })

            if (!quickAdding) {
                onCancel()
            }
        }
	}

	return (
		<section className="add-item">
			<PoseGroup>
				<Modal key="hmm" className="add-item-details">
					<form
						className="additemform"
						onSubmit={handleSubmit}
					>
						{
							type.fields.map((field, index) =>
								<EditItem
									key={field}
									field={field}
									type={type}
									index={index}
									setFieldData={setFieldData}
									fieldData={fieldData}
									lastItem={lastSubmittedItem}
								/>
							)
						}
						<button
							type="submit"
							disabled={haveMissingFields}
						>
							Add New {ucFirst(type.unit)}
						</button>
						<button
							className="linkish"
							type="button"
							onClick={onCancel}
						>
							Cancel
						</button>
					</form>
				</Modal>
			</PoseGroup>
		</section>
	)
}

export default AddItem
