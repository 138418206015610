import React from 'react'
import { FaRandom } from 'react-icons/fa'

const GoButton = props => {

	const { ready, highlight, onAutoRoster } = props

	return (
			<button className={'button-go button-icon' + (highlight ? ' button-go-highlight' : '')} onClick={onAutoRoster} disabled={!ready} title="Automatically generate a roster">
				<FaRandom />
				<span className="roster-button-text">
					Go
				</span>
			</button>
	)
}

export default GoButton
