import React, { useState, useEffect, useMemo } from 'react'
import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import ReactTooltip from 'react-tooltip'
import { loadFromLocalStorage, saveToLocalStorage } from './util'
import { FaRegQuestionCircle, FaRandom, FaExclamationTriangle } from 'react-icons/fa'

const DEVELOPMENT		= 'DEVELOPMENT'
const JUNIOR			= 'JUNIOR'
const SENIOR			= 'SENIOR'
const CUSTOM			= 'CUSTOM'

const defaultPreset		= JUNIOR

const presets = {
	[DEVELOPMENT]: {
		name: 'Development',
		order: 1,
		tooltip: "Development players are given more positional variety, and simple bib changes are prioritized.",
		defaults: {
			multiplePositions: 7,
			similarPositions: 7,
			centerExhaustion: 7,
			directSwaps: 7,
			halfTimeSwaps: 6,
			benchEvenly: 8,
			similarLastGame: 4,
			evenSeason: 3,
			netStats: 0,
		},
	},
	[JUNIOR]: {
		name: 'Junior',
		order: 2,
		tooltip: "Junior teams mix optimal team performance with some positional variety.",
		defaults: {
			multiplePositions: 5,
			similarPositions: 4,
			centerExhaustion: 5,
			directSwaps: 1,
			halfTimeSwaps: 3,
			benchEvenly: 8,
			similarLastGame: 5,
			evenSeason: 0,
			netStats: 5,
		},
	},
	[SENIOR]: {
		name: 'Senior',
		order: 3,
		tooltip: "For Senior players, team performance is prioritized, and \"similar positions\" are determined by height and mobility.",
		defaults: {
			multiplePositions: 1,
			similarPositions: 5,
			centerExhaustion: 5,
			directSwaps: 0,
			halfTimeSwaps: 0,
			benchEvenly: 5,
			similarLastGame: 4,
			evenSeason: 0,
			netStats: 8,
		},
	},
	[CUSTOM]: {
		name: 'Custom',
		order: 4,
		tooltip: "Your own prefrences.",
		defaults: { },
	}
}

const settings = [
	{
		id: 'netStats',
		desc: 'Optimize team performance',
		tip: 'Analyze match results to calculate the most effective roles for each player. See Help → Managers → NetStats.',
		default: 5,
		hideOnSingleRoster: true,
		disabled: ({ haveNetStats }) => !haveNetStats,
		disabledText: <span className="settings-warning"><FaExclamationTriangle /> Requires game scores</span>,
	},
	{
		id: 'benchEvenly',
		desc: 'Bench players evenly',
		tip: 'Give all players the same amount of game time.',
		default: 5,
	},
	{
		id: 'multiplePositions',
		desc: 'Prefer multiple positions',
		tip: 'Players have more than one position during the game.',
		default: 5,
	},
	{
		id: 'similarPositions',
		desc: 'Prefer similar positions',
		tip: 'When changing positions, players move to a similar role.',
		default: 5,
	},
	{
		id: 'similarLastGame',
		desc: 'Similar to previous game',
		tip: 'Players have similar positions to last time they played.',
		default: 5,
		hideOnSingleRoster: true,
	},
	{
		id: 'centerExhaustion',
		desc: 'Avoid exhausting Centre',
		tip: 'Give breaks to players who play Centre.',
		default: 5,
	},
	{
		id: 'directSwaps',
		desc: 'Prefer direct swaps',
		tip: 'Players swap in pairs, with each taking the other\'s position.',
		default: 5,
	},
	{
		id: 'halfTimeSwaps',
		desc: 'Prefer half-time changes',
		tip: 'Players change positions at half-time rather than after the 1st and 3rd quarters.',
		default: 5,
	},
	{
		id: 'evenSeason',
		desc: 'Rotate positions throughout season',
		tip: 'Throughout the season, give all players equal time in each position.',
		default: 5,
		hideOnSingleRoster: true,
	},
]

const localStorageKey = 'settings'

const PrettoSlider = withStyles({
	root: {
		color: '#52af77',
		height: 8,
	},
	thumb: {
		height: 24,
		width: 24,
		backgroundColor: '#fff',
		border: '2px solid currentColor',
		marginTop: -8,
		marginLeft: -12,
		'&:focus,&:hover,&$active': {
			boxShadow: 'inherit',
		},
	},
	active: {},
	valueLabel: {
		left: 'calc(-50% + 4px)',
	},
	track: {
		height: 8,
		borderRadius: 4,
	},
	rail: {
		height: 8,
		borderRadius: 4,
	},
})(Slider)

//
// For reasons I haven't figured out, if this component is first loaded
// with 'show={true}', the ReactTooltip doesn't work. So we delay it a
// little.
//
const Wrapper = props => {
	const [ ready, setReady ] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => setReady(true), 150)
		return () => clearTimeout(timer)
	},  [ ])

	return <Settings {...props} show={(props.show && !ready) ? false : props.show} />
}

const Settings = props => {

	const { show, updateSettings, isSingleRoster, haveNetStats } = props

	const [ validSettings, setValidSettings ] = useState()
	const [ initialValues, setInitialValues ] = useState()
	const [ values, setValues ] = useState({ })

	const valuesForPreset = useMemo(() => Object.keys(presets).reduce((obj, id) => {
		obj[id] = settings.reduce((map, setting) => {
			map[setting.id] = presets[id].defaults[setting.id] ?? setting.default
			return map
		}, { })
		return obj
	}, { }), [ ])

	//
	// On load, figure out which settings we'll be using.
	//
	useEffect(() => {
		setValidSettings(queryDefaultValidSettings({ isSingleRoster }))
	}, [ isSingleRoster ])

	//
	// Rebuild our defaults when validSettings() change.
	//
	useEffect(() => {

		if (validSettings) {
			//
			// First build default values. We might add new settings in the future,
			// so do this even if we're about to overwrite most/all of them with
			// saved values from localStorage.
			//
			const savedSettings = loadFromLocalStorage(localStorageKey) || [ ]

			const initialValues = validSettings.reduce((map, setting) => {
				map[setting.id] = Number.isInteger(savedSettings[setting.id]) ? savedSettings[setting.id] : setting.default
				return map
			}, { })
			initialValues.preset = savedSettings.preset || defaultPreset
			initialValues.showNetStats = savedSettings.showNetStats ?? true

			if (!presets[initialValues.preset]) {
				initialValues.preset = CUSTOM
			} else if (initialValues.preset !== CUSTOM) {
				Object.keys(valuesForPreset[initialValues.preset]).forEach(settingId => initialValues[settingId] = valuesForPreset[initialValues.preset][settingId])
			}

			//
			// Save initial values for use as 'defaultValue' of sliders below.
			//
			setInitialValues(initialValues)

			setValues(initialValues)
		}
	}, [ validSettings, valuesForPreset ])

	//
	// When our values change, save this locally and transmit back to
	// Roster.js for use by the genetic algorithm.
	//
	useEffect(() => {
		if (values && Object.keys(values).length) {
			updateSettings(values)
			const savedValues = (values.preset === CUSTOM ? values : { preset: values.preset })
			saveToLocalStorage(savedValues, localStorageKey)
		}
	}, [ values, updateSettings ])

	if (!show)
		return null

	const changeValue = obj => {
		let newValues = Object.assign({ }, values)
		newValues[obj.id] = obj.value

		if (obj.id !== 'preset') {
			//
			// When manually changing a slider, set the preset to CUSTOM
			//

			newValues.preset = CUSTOM

		} else if (obj.id === 'preset' && obj.value !== 'CUSTOM') {
			//
			// When selecting a preset, return all the values to default
			//

			settings.forEach(setting => {
				const { id } = setting
				newValues[id] = valuesForPreset[obj.value][id]
			})
		}

		setValues(newValues)
	}

	const sortedPresetIds = Object.keys(presets).sort((a, b) => presets[a].order - presets[b].order)

	// console.log('values', values, 'valuesForPreset', valuesForPreset)

	return (
		<section className="settings">
			<h3>
				AutoRoster
				<FaRandom className="text-icon" />
				<div
					className="settings-preset-container"
					data-tip={presets[values.preset]?.tooltip}
					data-for="tooltip-settings"
				>
					<FaRegQuestionCircle className="tooltip-icon" />
					<FormControl className="settings-preset">
						<Select
							labelId="demo-simple-select-outlined-label"
							id="demo-simple-select-outlined"
							value={values.preset || defaultPreset}
							onChange={e => changeValue({ id: 'preset', value: e.target.value })}
						>
							{
								sortedPresetIds.map(id =>
									<MenuItem key={id} value={id}>{presets[id].name}</MenuItem>
								)
							}
						</Select>
					</FormControl>
				</div>
			</h3>

			{
				haveNetStats && (
					<FormControl component="div" className="settings-netstats-toggle trials-form-switches">
						<FormControlLabel
							control={
								<Switch
									checked={!!values.showNetStats}
									onChange={e => changeValue({ id: 'showNetStats', value: !values.showNetStats })}
									name="showNetStats"
									color="primary"
								/>
							}
							label="Show NetStats"
							labelPlacement="start"
						/>
					</FormControl>
				)
			}

			<div>
				{
					initialValues && validSettings.map((setting, index) => {
						const disabled = setting.disabled && setting.disabled({ haveNetStats})

						return (
							<div key={setting.id} className="setting">
								<p data-tip={setting.tip} data-for="tooltip-settings">
										{setting.desc}
									<FaRegQuestionCircle className="tooltip-icon" />
									{disabled && setting.disabledText}
								</p>
								<PrettoSlider
									valueLabelDisplay="auto"
									aria-label="pretto slider"
									defaultValue={initialValues[setting.id]}
									value={values[setting.id]}
									disabled={disabled}
									max={10}
									onChange={(event, value) => changeValue({
										id: validSettings[index].id,
										value,
									})}
								/>
							</div>
						)
					})
				}
			</div>

			<ReactTooltip
				id='tooltip-settings'
				clickable={true}
				multiline={true}
			/>
		</section>
	)
}

const queryDefaultValidSettings = ({ isSingleRoster }) => isSingleRoster ? settings.filter(item => !item.hideOnSingleRoster) : settings

export const queryDefaultSettings = ({ isSingleRoster }) => {
	const validSettings = queryDefaultValidSettings({ isSingleRoster })

	const defaults = validSettings.reduce((map, setting) => {
		map[setting.id] = setting.default
		return map
	}, { })
	defaults.preset = defaultPreset
	defaults.showNetStats = true
	return defaults
}

export default Wrapper
