import React from 'react'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { FaVolleyballBall, FaUserFriends } from 'react-icons/fa'
import { AiOutlineSchedule, AiOutlineUser, AiOutlineBorderOuter } from 'react-icons/ai'

export const TabsMain = props => {

	const { view, setView } = props

	return (
		<Paper square className="trial-tabs">
			<Tabs
				value={view}
				indicatorColor="primary"
				textColor="primary"
				onChange={(e, newValue) => setView(newValue)}
				variant="fullWidth"
				aria-label="tabs"
				centered
			>
				<Tab
					icon={<FaUserFriends />}
					label="Players"
				/>
				<Tab
					icon={<FaVolleyballBall />}
					label="Games"
				/>
			</Tabs>
		</Paper>
	)
}

export const TabsRoster = props => {

	const { view, setView } = props

	return (
		<div className="trial-roster-tabs">
			<Tabs
				value={view}
				indicatorColor="primary"
				textColor="primary"
				onChange={(e, newValue) => setView(newValue)}
				aria-label="tabs"
				centered
			>
				<Tab
					icon={<AiOutlineSchedule />}
					label="Roster"
				/>
				<Tab
					icon={<AiOutlineUser />}
					label="Player Schedule"
				/>
				<Tab
					icon={<AiOutlineBorderOuter />}
					label="Player Grid"
				/>
			</Tabs>
		</div>
	)
}

export const TabPanel = props => {
	const { children, value, index, ...other } = props

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	)
}

