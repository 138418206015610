import React, { useMemo } from 'react'
import List from './List'

const Players = (props) => {

	const { section } = props

	const type = useMemo(() => ({
		name: 'players',
		unit: 'player',
		fields: [
			'name',
		],
		pathname: '/player/',
		linkToItem: true,
		quickAdding: true,
		sortFunction: (a, b) => a.name && a.name.localeCompare(b.name),
		altTitles: [
			{
				pathname: '/games',
				title: 'Games',
			},
		],
		dbid: `/teams/${section.team}/seasons/${section.season}/players`,
	}), [ section.team, section.season ])

	return (
		<List
			{...props}
			type={type}
		/>
	)
}

export default Players
