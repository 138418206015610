import React, { useState, useReducer } from 'react'
import { PoseGroup } from 'react-pose'
import GridCell from './GridCell'
import EditItem from './EditItem'
import { FixedItem, DraggableItemPlayer, TrialsPlayer, Div } from './definitionsPoses'
import { TiTick, TiTimes } from 'react-icons/ti'
import { MdEdit } from 'react-icons/md'

const GridPlayers = props => {
	const { players, editMode, isSingleRoster, isTrials, extraHeader, onDrop, setAmDragging, available, toggleAvailable, renamePlayer } = props

	//
	// Posed elements don't work well with Trials - in particular, they get cached
	// in a weird way that makes the list non-responsive to changes.
	//
	const ItemPlayer = isTrials ? TrialsPlayer : (editMode ? DraggableItemPlayer : FixedItem)
	const Group = isTrials ? Div : PoseGroup

	//
	// nameEdit tracks which name we're editing, if any.
	//
	// It doesn't call renamePlayer() - that needs to be invoked separately.
	//
	const nameEditReducer = (state, action) => {
		// console.log('nameEditReducer', state, action)
		const { type, index, hitTab } = action
		switch (type) {
			case 'START':
				return index
			case 'STOP':
				let newNameEdit = null
				if (hitTab !== undefined) {
					newNameEdit = index + (hitTab ? -1 : 1)
					if (newNameEdit >= players.length) {
						newNameEdit = 0
					} else if (newNameEdit < 0) {
						newNameEdit = players.length - 1
					}
				}
				return newNameEdit
			default:
				return index
		}
	}

	const [ nameEdit, setNameEdit ] = useReducer(nameEditReducer, null)

	return (
			<div className="column column-players">
				<GridCell type="void" content="" />
				{
					extraHeader ? <GridCell type="void" content="" /> : null
				}
				<Group>
					{
						players.map((player, index) =>
							<ItemPlayer
								key={player}
								className="posed-element"
								onDragStart={e => setAmDragging && setAmDragging(true)}
								onDragEnd={e => onDrop && onDrop(e, { player: player }) }
								isAvailable={available[player]}
							>
								<CellPlayer
									index={index}
									content={player}
									isAvailable={available[player]}
									toggleAvailable={toggleAvailable}
									canEditName={isSingleRoster}
									amEditing={nameEdit === index}
									setNameEdit={setNameEdit}
									renamePlayer={renamePlayer}
									isTrials={isTrials}
								/>
							</ItemPlayer>
						)
					}
				</Group>
			</div>
	)
}

//
// Don't re-render cells unless the contents change
//
const CellPlayer = React.memo(props => {

	const { content, isAvailable, index, toggleAvailable, canEditName, amEditing, setNameEdit, renamePlayer, isTrials } = props

	let editContent

	const isDefaultName = content.match(/^Player \d+$/)
	const [ fieldData, setFieldData ] = useState({ name: isDefaultName ? null : content })
	const [ hitTab, setHitTab ] = useState()

	const onNameEditStart = () => {
		setNameEdit({
			type: 'START',
		index,
		})
		setHitTab(undefined)
	}

	const onNameEditStop = () => {
		setNameEdit({
			type: 'STOP',
			index,
			hitTab,
		})
		renamePlayer({
			oldName: content,
			newName: fieldData.name,
			index,
		})
		setHitTab(undefined)
	}

	const onCancel = () => {
		setNameEdit({
			type: 'STOP',
			index: null,
		})
		setHitTab(undefined)
	}

	if (amEditing) {
		editContent = (
			<EditItem
				plain={true}
				field="name"
				type={{
					unit: "player"
				}}
				setFieldData={setFieldData}
				fieldData={fieldData}
				onBlur={onNameEditStop}
				onCancel={onCancel}
				onTab={setHitTab}
			/>
		)
	}

	const playerAvailable = (isAvailable && !isTrials) ? (
		<TiTick className="tick" />
	) : (
		<TiTimes className="cross" />
	)

	const editButton = canEditName ? (
		<MdEdit />
	) : null

	return (
		<GridCell
			type="player"
			content={content}
			editContent={editContent}
			icons={playerAvailable}
			onClickIcon={toggleAvailable}
			iconsRight={editButton}
			onClickIconRight={onNameEditStart}
			isAvailable={isAvailable}
			i={content}
		/>
	)
})

export default GridPlayers
