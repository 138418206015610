import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import './positionpreferencessetting.css'

const PositionPreferencesSetting = props => {
	const { showPositionPreferences, editMode, preferenceType, updatePositionPreferenceType } = props

	if (!editMode || !showPositionPreferences)
		return null

	const values = [ 'Prefer', 'Require' ]

	return (

		<div className="position-preferences-setting">
			<FormControl className="settings-preset">
				<Select
					labelId="position-preferences-label"
					id="position-preferences-toiggle"
					value={preferenceType}
					onChange={e => updatePositionPreferenceType(e.target.value)}
				>
					{
						values.map((name, index) =>
							<MenuItem key={name} value={index} selected={preferenceType === index}>{name}</MenuItem>
						)
					}
				</Select>
			</FormControl>
			<span className="position-preferences-trailing-text">
				these positions
			</span>
		</div>
	)
}

export default PositionPreferencesSetting
