import { deepCopy, calculatePositions } from './util'
import { BENCHED, NULL_NETSTAT_VALUE, fieldPositions, numPeriods } from './definitions'

const GeneticAlgorithm = require('geneticalgorithm')

const timerInterval = 17
const numGenerationsPerLoop = 16
const pingEvery = 150
const defaultPhenotypeSize = 40
const defaultMaxGenerations = 2500
const extendTimeOnLateMutation = true

let ga
let ready
let generation
let maxGenerations
let best
let lastPing

let players
let positions
let locks
let available
let preferences
let preferenceType
let stats
let settings
let onProgress
let playerKey
let netStats
let netStatWeighting

const DEFAULT_NETSTAT_WEIGHTING		= 2.8

export const generateRoster = props => {
	const { startingPhenotype } = props

	ready = false
	generation = 0
	maxGenerations = defaultMaxGenerations
	lastPing = 0

	players = props.players
	positions = props.positions || calculatePositions(players.length)
	locks = props.locks || defaultLocks()
	available = props.available
	preferences = props.preferences
	preferenceType = props.preferenceType
	stats = props.stats
	settings = props.settings
	netStats = props.netStats
	playerKey = props.playerKey
	onProgress = props.onProgress
	const totalPosCount = netStats && Object.values(netStats).reduce((total, obj) => total + obj.posCount.reduce((myTotal, n) => myTotal + n, 0), 0)
	netStatWeighting = Math.max(1.15, Math.min(DEFAULT_NETSTAT_WEIGHTING, DEFAULT_NETSTAT_WEIGHTING * (totalPosCount / 50)))

	console.log('generateRoster() with startingPhenotype', startingPhenotype, totalPosCount, netStatWeighting)

	//
	// Force a recalculation of score, because we might have assigned new position
	// preferences or changed settings since this was generated.
	//
	startingPhenotype.score = fitnessFunction(startingPhenotype)

	const options = {
		population: [ startingPhenotype ],
		populationSize: defaultPhenotypeSize,

		mutationFunction,
		crossoverFunction,
		fitnessFunction,

		doesABeatBFunction,
	}

	ga = GeneticAlgorithm(options)

	//
	// Replace the scoredPopulation function with this, which makes
	// use of the 'score' cache in each phenotype.
	//
	ga.scoredPopulation = () => {
		return ga.population().map(pheno => {
			return {
				phenotype: deepCopy(pheno), // not sure it's strictly necessary to do this, but anyway...
				score: pheno.score || fitnessFunction(pheno)
			}
		})
	}

	best = Object.assign({ }, startingPhenotype)
	best.generation = 0

	console.log("Set best with score", best.score, best)

	tick()
}

function tick() {

	if (generation < maxGenerations) {
		for (let i = 0; i < numGenerationsPerLoop; i += 1) {
			ga.evolve()
			generation += 1
		}
	}

	const percent_complete = generation / maxGenerations

	//
	// Don't show best rosters during the first 15% of computation,
	// since the results change very rapidly then, which causes
	// janky animation.
	//
	let foundNewBest
	if (percent_complete > 0.15) {
		const newBest = ga.best()
		newBest.score = newBest.score || fitnessFunction(newBest)

		if (newBest.score > best.score) {
			// console.log("New score of", newBest.score, "beats old best score of", best.score)
			foundNewBest = true
			newBest.generation = generation
			best = newBest

			//
			// If we found an improvement after the first 60% of iterations,
			// extend our search as we may discover something even better in
			// new mutations.
			//
			if (extendTimeOnLateMutation && percent_complete > 0.6) {
				maxGenerations += defaultMaxGenerations / 8
			}

			console.log('new best', best.score, 'generation', best.generation)

			//
			// Force alphabetical sort of benched players
			//
			const numPlayers = best.roster.length / 4
			const numFieldPositions = fieldPositions.length
			const numBenched = numPlayers - numFieldPositions
			const sortFunction = (a, b) => available[b] - available[a] || a.localeCompare(b)
			if (numBenched > 1) {
				for (let qtr = 0; qtr < 4; qtr += 1) {
					const start = numFieldPositions + qtr * numPlayers
					const benchedPlayers = best.roster.slice(start, start + numBenched).sort(sortFunction)
					// console.log('sorted benchedPlayers qtr', qtr, benchedPlayers)
					for (let i = 0; i < benchedPlayers.length; i += 1) {
						best.roster[start + i] = benchedPlayers[i]
					}
				}
			}
		}
	}

	if (generation >= maxGenerations) {
		ready = true
	}

	if (ready || foundNewBest || generation - lastPing > pingEvery) {
		lastPing = generation
		const ping = {
			ready,
			progress: Math.min(100, 100 * generation / maxGenerations),
		}
		if (foundNewBest) {
			ping.best = best
		}

		//
		// Ping parent with progress, because:
		// - we're finished; or
		// - we found a new 'best'
		// - it's been a while since we last pinged
		//
		onProgress && onProgress(ping)
	}

	if (ready) {

		console.log('Finished after', generation, 'generations.')
		console.log('Best:', best)
		displayRoster(best)

	} else {

		setTimeout(tick, timerInterval)

	}
}

function doesABeatBFunction(a, b) {

	const AScore = a.score || fitnessFunction(a)
	const BScore = b.score || fitnessFunction(b)
	return AScore >= BScore
}

function defaultLocks() {
	return players.map(player => false)
}

function haveSetting(id) {
	return settings[id] !== undefined
}

function settingModifier(id, value) {
	return querySetting(id) * value
}

function querySetting(id) {
	let value = settings[id]
	if (value >= 5) {
		value = 1 + Math.pow(value - 5, 1.3) / 10
	} else {
		value = value / 5
	}

	if (isNaN(value) || value === null || value === undefined) {
		throw new Error("Bad setting: " + id)
	}

	return value
}

function queryPreset() {
	return settings.preset
}


//
// *********************************************************
// GA Functions for regular rosters (single games of 4 qtrs)
// *********************************************************
//

function fitnessFunction(phenotype, debug) {

	const allScores = { }

	const roster = parseRoster(phenotype)

	const preset = queryPreset()

	//
	// How many different positions should a player have if we haven't
	// changed from the average setting?
	//
	const IDEAL_NUM_POSITIONS = 2

	const numAvailablePlayers = Object.values(available).filter(isAvailable => isAvailable).length
	const avgBenchedPeriodsPerPlayer = ((numAvailablePlayers - fieldPositions.length) * numPeriods) / numAvailablePlayers
	const multiplePositionsModifier = querySetting('multiplePositions', 5)
	const idealNumPositions = IDEAL_NUM_POSITIONS // Math.pow(Math.max(IDEAL_NUM_POSITIONS, multiplePositionsModifier / 2), Math.pow(multiplePositionsModifier, 1.5))

	if (debug)
		console.log("ideal number of positions", idealNumPositions, multiplePositionsModifier)

	Object.keys(roster).forEach(player => {
		let numOfBenchPeriods = 0
		const diffPositions = { }
		let swapTiming = 0
		let swapIndirectness = 0
		let unavailability = 0
		let centerExhaustion = 0

		for (let qtr = 0; qtr < roster[player].length; qtr += 1) {
			const position = roster[player][qtr]
			//
			// Unavailability
			//
			if (position !== BENCHED && !available[player]) {
				//
				// If we have to assign an position to an unavailable
				// player, make it WD or WA.
				//
				if (position === 'WD') {
					unavailability += 1
				} else if (position === 'WA') {
					unavailability += 2
				} else if (position === 'GK') {
					unavailability += 3
				} else if (position === 'GS') {
					unavailability += 4
				} else {
					unavailability += 5
				}
			}

			//
			// Number of times benched
			//
			if (position === BENCHED) {
				numOfBenchPeriods += 1
			} else {
				diffPositions[position] = true
			}

			const prevPosition = qtr ? roster[player][qtr - 1] : null

			//
			// Consider positional changes, excluding moves to & from the bench
			//
			if (prevPosition !== null && position !== prevPosition) {

				//
				// When changing positions, prefer to do it at half-time
				//
				if (position !== BENCHED && prevPosition !== BENCHED) {
					if (qtr === numPeriods / 2) {
						swapTiming += 0
						if (debug) {
							console.log('halftime swap for ' + player, prevPosition, position, qtr, numPeriods)
						}
					} else {
						swapTiming += 1
						if (debug) {
							console.log('q1/3 swap for ' + player, prevPosition, position)
						}
					}
				}

				//
				// Directly swapping positions with another player is good
				//
				if (position !== BENCHED) {
					const playerInThisPositionLastQtr = phenotype.roster[((qtr - 1) * positions.length) + queryPositionIndex(position)]
					const thatPlayersNewPosition = roster[playerInThisPositionLastQtr][qtr]
					if (thatPlayersNewPosition === prevPosition) {
						//
						// Direct swap
						//
						if (debug) {
							console.log(player + ' has direct swap with ' + playerInThisPositionLastQtr, prevPosition, position)
						}
					} else {
						swapIndirectness += 5
						if (debug) {
							console.log(player + ' has indirect swap with ' + playerInThisPositionLastQtr, prevPosition, position)
						}
					}
				}
			}

			if (prevPosition !== null) {
				//
				// Consider all positional changes, regardless of whether you stay in
				// the same position or move to the bench.
				//

				//
				// Count how much we wear out the Center
				//
				if (position === 'C' || prevPosition === 'C') {
					if (position === 'C' && prevPosition === 'C') {
						centerExhaustion += 10
						if (debug)
							console.log(player + ' has two C in a row')
					} else if (position === BENCHED || prevPosition === BENCHED) {
						centerExhaustion += 0
						if (debug)
							console.log(player + ' has C with a break')
					} else if (position === 'GA' || prevPosition === 'GA' || position === 'GD' || prevPosition === 'GD') {
						centerExhaustion += 6
						if (debug)
							console.log(player + ' has C + GA/GD')
					} else {
						centerExhaustion += 3
						if (debug)
							console.log(player + ' has C + another oncourt position', qtr, position, prevPosition)
					}
				}
			}
		}

		const scores = { }

		if (!available[player]) {

			scores.unavailability = 100000000 * unavailability
			if (debug)
				console.log(player + ' is unavailable')

		} else {

			//
			// Times benched
			//
			scores.benched = Math.floor(Math.pow(1 + Math.abs(numOfBenchPeriods - avgBenchedPeriodsPerPlayer), settingModifier('benchEvenly', 20) / 2))
			if (debug)
				console.log(player + ' is benched ' + numOfBenchPeriods + ' times (avg ' + avgBenchedPeriodsPerPlayer.toFixed(1) + '): ', scores.benched)

			//
			// Avoid bookends, where you play the first and last qtrs, with
			// two benchings in the middle.
			//
			if (roster[player][0] !== BENCHED && roster[player][1] === BENCHED && roster[player][2] === BENCHED && roster[player][3] !== BENCHED) {
				scores.bookends = 250
				if (debug) {
					console.log(player, 'is bookended')
				}
			}

			//
			// Center Exhaustion
			//
			scores.centerExhaustion = Math.floor(Math.pow(centerExhaustion, settingModifier('centerExhaustion', 3.3)))
			if (debug) {
				console.log(player, 'centerExhaustion raw', centerExhaustion, '->', scores.centerExhaustion)
			}

			//
			// Backsies: returning to the same position after playing somewhere else.
			// That's not cool.
			//
			let backsies = 0
			const myPositions = roster[player]
			let prevPosition = myPositions[0]
			for (let i = 1; i < myPositions.length; i += 1) {
				const thisPosition = myPositions[i]
				if (thisPosition !== prevPosition) {
					if (thisPosition !== BENCHED) {
						const firstPlayed = myPositions.indexOf(thisPosition)

						if (firstPlayed !== i) {
							backsies += 1
							if (debug)
								console.log(player, 'has backsies in ', thisPosition)
						}
					}
					prevPosition = thisPosition
				}
			}
			if (backsies) {
				scores.backsies = backsies * 50
			}

			//
			// Similar Positions
			//
			const myUniquePositions = [ ...new Set(myPositions)]

			// if (debug)
			//	console.log(player, 'positions', myPositions, 'unique', myUniquePositions)

			if (myPositions.length) {
				const myUniquePositionIndexes = myUniquePositions.map(position => queryPositionIndex(position))
				//const myAverageUniquePositionIndex = myUniquePositionIndexes.reduce((total, n) => total + n) / myUniquePositionIndexes.length
				//const myUniquePositionVariation = myUniquePositionIndexes.reduce((total, n) => total + Math.abs(n - myAverageUniquePositionIndex), 0)
				const myUniquePositionVariation = queryPositionVariability(myUniquePositionIndexes, debug)
				scores.similarPositions = Math.floor(Math.pow(myUniquePositionVariation, settingModifier('similarPositions', 2)))
				if (debug) {
					console.log('myPositions', myPositions, myUniquePositionIndexes, myUniquePositionVariation, scores.similarPositions, preset)
				}
			}

			scores.swapTiming = Math.floor(Math.pow(4 * swapTiming, settingModifier('halfTimeSwaps', 3)))
			if (preset === 'Senior') {
				scores.swapTiming /= 4
			}

			scores.swapIndirectness = Math.floor(Math.pow(swapIndirectness, settingModifier('directSwaps', 2)))
			if (preset === 'Senior') {
				scores.swapIndirectness /= 4
			}

			//
			// If the 'multiplePositions' setting is turned all the way down to 0, we don't
			// care at all about number of positions. If it's at 1, we care about players having
			// few positions.
			//
			if (querySetting('multiplePositions')) {
				const numDifferentPositions = Object.keys(diffPositions).length
				scores.numPositions = Math.floor(Math.pow(2 + Math.abs(idealNumPositions - numDifferentPositions), settingModifier('multiplePositions', 6)))
			}

			// if (debug)
			//	console.log(player + ' number of positions: ', numDifferentPositions)

			//
			// Position Preferences
			//
			scores.posPrefs = 0
			const myRequiredPositions = preferences[player].map((v, index) => v === 1 && fieldPositions[index]).filter(v => v)

			if (myRequiredPositions.length) {
				const missingPositions = myRequiredPositions.filter(pos => myUniquePositions.filter(v => pos === v).length ? false : true)
				const numMissingPositions = missingPositions.length

				// Is this a "Prefer" or "Require" preference?
				const PREF_IMPORTANCE = preferenceType ? 5000 : 100
				scores.posPrefs += Math.pow(numMissingPositions * PREF_IMPORTANCE, 1.2)

				//
				// Let's also award a smaller score based on how many qtrs they are in a preferred position.
				// This should be pretty minor, but basically we want to be able to prefer a roster that has
				// a player in their preferred position/s more often.
				//
				const numPeriodsInPreferredPosition = myPositions.reduce((total, pos) => {
					let result = 0
					if (myRequiredPositions.filter(v => v === pos).length) {
						result = 1
					}
					return total + result
				}, 0)

				scores.posPrefs += Math.pow(numPeriods - numPeriodsInPreferredPosition, 2) * 4

				if (debug) {
					console.log(player + ' missing required positions:', missingPositions, 'periods in preferred position/s:', numPeriodsInPreferredPosition)
				}
			}

			const numAvoidedPositions = myPositions.filter(posName => preferences[player][queryPositionIndex(posName)] === -1).length

			if (numAvoidedPositions) {
				scores.posPrefs += 25000 * numAvoidedPositions
				if (debug) {
					console.log(player, 'numAvoidedPositions', numAvoidedPositions)
				}
			}

			//
			// These are used only for managed seasons, not single rosters
			//
			const myPositionIndexes = myPositions.map(position => queryPositionIndex(position))

			//
			// Match same positions as last week
			//
			if (haveSetting('similarLastGame') && stats[player]) {
				const myAveragePositionIndex = myPositionIndexes.reduce((total, n) => total + n, 0) / myPositionIndexes.length

				const myPositionIndexesLastGame = stats[player].lastPositions.filter(position => position !== null)
				const myAveragePositionIndexLastGame = stats[player].lastPositions.reduce((total, n) => total + n, 0) / myPositionIndexesLastGame.length

				if (!isNaN(myAveragePositionIndex) && !isNaN(myAveragePositionIndexLastGame)) {
					const lastGameSimilarity = Math.abs(myAveragePositionIndex - myAveragePositionIndexLastGame)

					scores.lastGameSimilarity = Math.floor(Math.pow(lastGameSimilarity, settingModifier('similarLastGame', 4)))

					if (debug)
						console.log(player + ' similarity to last game: ', lastGameSimilarity, myPositionIndexes, myPositionIndexesLastGame)
				}
			}

			//
			// Even out season
			//
			if (haveSetting('evenSeason') && stats[player]) {
				const newAllPositions = stats[player].allPositions.slice()
				myPositionIndexes.forEach(positionIndex => {
					if (positionIndex !== null) {
						newAllPositions[positionIndex] += 1
					}
				})

				const averageQtrsPerPosition = newAllPositions.reduce((total, n) => total + n, 0) / newAllPositions.length
				const variance = 2 * newAllPositions.reduce((total, n) => total + Math.pow(n - averageQtrsPerPosition, 2), 0) / newAllPositions.length

				scores.evenSeason = Math.floor(Math.pow(variance, settingModifier('evenSeason', 3)))

				if (debug)
					console.log(player + ' season evenness', variance, averageQtrsPerPosition, newAllPositions)
			}

			//
			// NetStats
			//
			if (haveSetting('netStats')) {

				const myNetStats = netStats[playerKey[player]]?.adjusted

				if (myNetStats) {

					const netStatScore = myPositionIndexes.reduce((total, posIndex) => {
						const score = myNetStats[posIndex ?? 7] ?? NULL_NETSTAT_VALUE
						return total + score
					}, 0)
					// scores.netStats = Math.floor(Math.pow(10 - netStatScore, settingModifier('netStats', netStatWeighting)))
					scores.netStats = Math.floor(Math.pow(netStatWeighting, settingModifier('netStats', 7)) * (10 - netStatScore) * 0.02)

					if (isNaN(scores.netStats)) {
						// console.log(player + ' has netStats', netStats && playerKey && netStats[playerKey[player]])
						console.error ("oh oh, it's isNaN - " + netStatScore + " / " + settingModifier('netStats', 2) + " === " + Math.floor(Math.pow(netStatScore, settingModifier('netStats', 2))))
					}

					if (debug)
						console.log(player + ' netStats', netStatScore, '->', scores.netStats, 'using myNetStats of', myNetStats)
				}
			}
		}

		allScores[player] = scores
	})

	const score = Object.values(allScores).reduce((total, p) => {
		return total - Object.values(p).reduce((pTotal, n) => pTotal + n)
	}, 0)

	if (debug) {
		console.log('score', score)
		console.table(allScores)
	}

	phenotype.score = score

	return score
}

function mutationFunction(phenotype) {

	const numTruePositions = fieldPositions.length
	const numAvailablePlayers = Object.values(available).filter(isAvailable => isAvailable).length

	const qtr = Math.floor(Math.random() * numPeriods)
	const offset = qtr * positions.length

	const pos1 = randomPosition()
	let pos2
	while (pos2 === undefined || pos2 === pos1) {
		pos2 = randomPosition()
	}

	// console.log('Qtr ' + qtr + ' offset ' + offset + ' swap', pos1, phenotype.roster[offset + pos1], pos2, phenotype.roster[offset + pos2])

	if (pos1 >= numTruePositions && pos2 >= numTruePositions) {
		//
		// Swapping two benched players -- ignore
		//
	} else if (locks[offset + pos1] || locks[offset + pos2]) {
		//
		// console.log('Not swapping: locked')
		//
	} else if (numAvailablePlayers >= numTruePositions && ((!available[phenotype.roster[offset + pos2]] && pos1 < numTruePositions) || (!available[phenotype.roster[offset + pos1]] && pos2 < numTruePositions))) {
		//
		// Trying to assign a position to an unavailable player -- avoid this unless we're
		// actually short of players, because it's a waste of time.
		//
		// console.log('Not swapping: unavailable', numTruePositions, pos1, pos2)
	} else {
		const temp = phenotype.roster[offset + pos1]
		phenotype.roster[offset + pos1] = phenotype.roster[offset + pos2]
		phenotype.roster[offset + pos2] = temp

		phenotype.score = null

		// console.log('swapped')
	}

	return phenotype
}

//
// Swap big chunks at once.
//
// Qtrs can swap randomly, e.g. you can copy Qtr 3 from b into Qtr 1 of a.
//
function crossoverFunction(a, b) {

	const x = deepCopy(a)
	const y = deepCopy(b)
	let cross = false

	//
	// Normally we swap quarters, but sometimes swap players
	//
	if (Math.random() < 0.97) {

		for (let qtr = 0; qtr < numPeriods; qtr += 1) {
			if (Math.random() > 0.5) {
				cross = !cross
			}

			if (cross) {

				//
				// Any locks?
				//
				let ok = true
				for (let i = qtr * positions.length; i < qtr * positions.length + positions.length; i += 1) {
					if (locks[i]) {
						ok = false
					}
				}

				if (ok) {
					// console.log('crossover qtr', qtr, qtr * positions.length, qtr * positions.length + positions.length)
					const yQtr = Math.floor(Math.random() * numPeriods)

					for (let i = 0; i < positions.length; i += 1) {
						const xi = qtr * positions.length + i
						const yi = yQtr * positions.length + i
						const temp = x.roster[xi]
						x.roster[xi] = y.roster[yi]
						y.roster[yi] = temp

						x.score = null
						y.score = null
					}
				} else {
					// console.log('not crossing because of locks')
				}
			}
		}
	} else {
		//
		// Randomly replace one player's positions with another.
		// This is helpful for when we have players unavailable,
		// so we can quickly copy an available player's roles to
		// an unavailable one, or vice versa.
		//
		const p1 = players[Math.floor(Math.random() * players.length)]
		const p2 = players[Math.floor(Math.random() * players.length)]
		if (p1 !== p2) {
			// console.log('let us swap all positions', p1, p2, a, b)

			;[ x, y ].forEach(obj => {
				//
				// Any locks to worry about?
				//
				let ok = true
				for (let i = 0; i < obj.roster.length; i += 1) {
					if (obj.roster[i] === p1 || obj.roster[i] === p2) {
						if (locks[i]) {
							ok = false
						}
					}
				}

				if (ok) {
					for (let i = 0; i < obj.roster.length; i += 1) {
						if (obj.roster[i] === p1) {
							obj.roster[i] = p2
							// console.log(i, p1, '=>', p2)
						} else if (obj.roster[i] === p2) {
							obj.roster[i] = p1
							// console.log(i, p2, '=>', p1)
						}
					}

					obj.score = null
				}
			})
		}
	}
	// console.log('crossover result', a, b, x, y)

	return [ x , y ]
}

function randomPosition() {
	return Math.floor(Math.random() * positions.length)
}

function queryPositionIndex(position) {
	if (position === BENCHED)
		return null

	for (let i = 0; i < positions.length; i+=1) {
		if (positions[i] === position) {
			return i
		}
	}

	console.error("what is this position", position)
	return null
}

//
// Like queryPositionIndex(), but considers GS/GK to be more similar than C.
//
function queryPositionVariability(myUniquePositionIndexes, debug) {
	const preset = queryPreset()

	const positionVariability = myUniquePositionIndexes.reduce((total, n, index) =>
		total + myUniquePositionIndexes.reduce((total2, n2, index2) => {
			let score = 0
			if (index !== index2 && n !== null && n2 !== null) {

				//
				// For development, we consider GS to be totally different to GK. But for
				// Juniors/Seniors, we consider them to be similar. And the same with
				// GA vs GD.
				//
				score = Math.abs(n - n2)

				if (preset !== 'DEVELOPMENT') {
					// console.log('woo preset', preset, score)
					if (score === 6) {
						//
						// Swapping between GS and GK -- needs to be fairly low because
						// it's hard to overcome the natural relationships between other
						// positions on court. This puts a GS/GK swap as just 20% worse
						// than a GS/GA swap.
						//
						score = 1.2 // .25
					} else if (score >= 4) {
						// Swapping between GS and GD, or GA and GK
						score = 1.8
					}

					// if (debug)
					//		console.log('queryPositionVariability()', myUniquePositionIndexes, n, n2, score)
				}
			}
			return total2 + score
		}, 0),
		0)

	if (debug)
		console.log('queryPositionVariability() TOTAL', positionVariability)

	//console.log("Variability: ", positionVariability, positionVariability / myUniquePositionIndexes.length)
	return positionVariability // / myUniquePositionIndexes.length
}

//
// Map a roster into a data structure like this:
// { 'Matilda': [ 'GS', 'GS', '-', 'C' ], ... }
//
function parseRoster(phenotype) {

	const { roster } = phenotype

	const data = players.reduce((map, player) => {
		map[player] = [ ]
		return map
	}, { })

	for (let i = 0; i < roster.length; i += 1) {
		const qtr = Math.floor(i / positions.length)
		const player = roster[i]
		const position = positions[i % positions.length]
		data[player][qtr] = position
	}

	return data
}

function displayRoster(phenotype) {

	fitnessFunction(phenotype, true)

	const ok = [ ]

	for (let i = 0; i < phenotype.roster.length; i += 1) {
		const qtr = Math.floor(i / positions.length)
		const player = phenotype.roster[i]
		// const position = positions[i % positions.length]

		ok[qtr] = ok[qtr] || { }

		if (ok[qtr][player]) {
			console.error('!!!! uhh ' + player + ' assigned twice in qtr ' + qtr)
		}
		ok[qtr][player] = true
	}

	// console.log('PHENOTYPE', phenotype)

	const roster = parseRoster(phenotype)

	console.table(roster)
}
