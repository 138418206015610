import React, { useState, useEffect } from 'react'
import posed from 'react-pose'

const DURATION = 160

const config = {
	show: {
		opacity: 1,
		transition: {
			duration: DURATION,
		},
	},
	hide: {
		opacity: 0,
		transition: {
			duration: DURATION,
		},
	}
}

export const FadeInText = posed.span(config)
export const FadeInBlock = posed.div(config)

//
// Text that fades out as it enters and exits.
//
// Example:
//
//	<FadeText>Hi there</FadeText>
//
// ... or for <div> instead of <span>:
//
//	<FadeText isBlock={true}>
//		<p>etc</p>
//		<p>etc</p>
//	</FadeText>
//
const FadeText = ({ children, className, isBlock }) => {

	const [ pose, setPose ] = useState('hide')
	const [ displayedContent, setDisplayedContent ] = useState('')
	const [ key, setKey ] = useState()

	const FadeItem = isBlock ? FadeInBlock : FadeInText

	useEffect(() => {

		let timer

		const contentKey = jsxToString(children)

		if (key !== contentKey || ((typeof children === 'string' || typeof children === 'number' || children === undefined) && children !== displayedContent)) {
			setPose('hide')

			timer = setTimeout(() => {
				setDisplayedContent(children || '')
				setKey(contentKey)
				setPose('show')
			}, DURATION + 20)
		}

		return () => clearTimeout(timer)
	}, [ children, displayedContent, key ])

	return (
		<FadeItem
			initialPose="hide"
			pose={pose}
			className={className}
		>
			{displayedContent}
		</FadeItem>
	)
}

function jsxToString(jsx) {

	if (!jsx) {
		return ''
	}

	if (typeof jsx === 'string') {
		return jsx
	}

	if (typeof jsx === 'number') {
		return String(jsx)
	}

	if (typeof jsx === 'object') {
		const isArray = Array.isArray(jsx)
		if (isArray) {
			return jsx.map(item => jsxToString(item)).join(' ')
		}
		if (jsx.props && jsx.props.children) {
			return jsxToString(jsx.props.children)
		}
		return ''
	}

	console.error('weird thing?', typeof jsx, jsx)
	return ''
}


export default FadeText
