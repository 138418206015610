import React from 'react'
import List from './List'

const AllTeams = (props) => {
	const { user } = props

	return (
		<List
			{...props}
			type={{
				name: 'teams',
				niceName: 'All Teams',
				unit: 'team',
				fields: [ 'name' ],
				dbid: '/teams',
				pathname: '/seasons/',
				onAdd: (fieldData, props) => (
					{
						managers: [ user.uid ],
					}
				)
			}}
		/>
	)
}

export default AllTeams
