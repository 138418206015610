import React from 'react'
import { PoseGroup } from 'react-pose'
import GridCellPosition from './GridCellPosition'
import GridCell from './GridCell'
import { BENCHED } from './definitions'
import { DraggableItem, FixedItem } from './definitionsPoses'

const GridPositions = props => {

	const { players, available, data, roster, toggleLock, nullPositions, onDrop, locks, setAmDragging, editMode, qtr, showNetStats, showAltBoard } = props

	const list = players.map((player, index) => {
		const noRoster = !roster.length
		const positionName = noRoster ? BENCHED : data[player][qtr].position
		const isNullPosition = roster.length ? nullPositions[data[player][qtr].i] : false
		const netStatElement = showNetStats && (
			<NetStatValue
				value={data[player][qtr].netStat}
				position={positionName}
				isNullPosition={isNullPosition}
			/>
		)

		const i = data[player][qtr].i

		const isAvailable = available[player]

		// const key = i
		// const key = `edit-altboard-q${qtr}-${player}` //  showAltBoard ? `edit-altboard-q${qtr}-${player}` : i
		const key = showAltBoard ? `edit-altboard-q${qtr}-${player}` : i

		const onDragEnd = e => {
			// console.log("dragEnd for", player, "who is i", i);
			const obj = showAltBoard ? { player, qtr } : { i }
			return onDrop(e, obj)
		}

		return {
			player,
			position: data[player][qtr].position,
			positionName,
			isNullPosition,
			isAvailable,
			netStatElement,
			noRoster,
			i,
			key,
			onDragEnd,
		}
	})

	if (showAltBoard) {
		list.sort((a, b) => a.i - b.i)
	}

	// console.log('list', list.length, list, list.map(item => item.key).join(' '))

	const Item = editMode ? DraggableItem : FixedItem

	let contents = list.map((item, index) => (
		<Item
			key={item.key}
			className={editMode ? 'posed-element' : ''}
			draggable={false}
			onDragStart={e => setAmDragging(true)}
			onDragEnd={item.onDragEnd}
			isAvailable={item.isAvailable}
			positionName={item.positionName}
		>
			<GridCellPosition
				i={item.i}
				content={item.noRoster ? '' : (showAltBoard ? item.player : item.positionName)}
				positionName={item.positionName}
				qtr={qtr}
				player={item.player}
				isNullPosition={item.isNullPosition}
				isAvailable={item.isAvailable}
				isLocked={locks[item.i]}
				onClick={toggleLock}
				children={item.netStatElement}
			/>
		</Item>
	))

	if (editMode) {
		contents = (
			<PoseGroup>
				{contents}
			</PoseGroup>
		)
	}

	return (
		<div className="column column-positions">
			<GridCell type="header" content={'Q' + (qtr + 1)} />
			{contents}
		</div>
	)
}

const NetStatValue = props => {
	const { value, position } = props

	if (value === null || value === undefined)
		return null

	let str = Number.parseFloat(value).toFixed(1)
	if (value >= 0) {
		str = `+${str}`
	}

	return (
		<div
			className={`netStatValue netStatValue-${value >=0 ? 'positive' : 'negative'} cell-position-${position}`}
		>
			{str}
		</div>
	)
}

export default GridPositions
