//
// Definitions (Poses)
//
import React from 'react'
import posed from 'react-pose'
import { BENCHED } from './definitions'

export const GrowInBox = posed.div({
	visible: {
		opacity: 1,
		height: 'auto',
	},
	hidden: {
		opacity: 0,
		height: 0,
	}
})

export const FadeInBox = posed.div({
	visible: {
		opacity: 1,
	},
	hidden: {
		opacity: 0,
	}
})


export const SpinButton = posed.button({
	active: {
		rotate: -90,
	},
	inactive: {
		rotate: 0,
	},
})

export const DraggableItem = posed.div({
    // You can make a custom transition for the flip-powered
    // shuffling animation by overriding `flip`. You can even
    // add other properties to animate while the flip animation
    // is in progress. Uncomment the following code to try it out!

    draggable: true,
    dragBounds: { left: '-50%', right: '50%' },
    init: { scale: 1 },
    drag: { scale: 1.1, left:'-5%' },
})

export const FixedItem = ({ isAvailable, positionName, children }) => {
    return (
		<div className={`fixed-element ${isAvailable ? '' : 'fixed-element-unavailable'} ${positionName === BENCHED ? 'fixed-element--' : ''}`}>
            {children}
        </div>
    )
}

export const DraggableItemPlayer = posed.div({
	draggable: true,
	dragBounds: { left: '-50%', right: '50%' },
})

export const TrialsPlayer = props => (
	<div className="flat-trials-player posed-element">
		{props.children}
	</div>
)

export const PositionPreference = props => (
	<div className="flat-position-preference posed-element">
		{props.children}
	</div>
)

export const Div = props => (
	<div className="flat-div">
		{props.children}
	</div>
)
