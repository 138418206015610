import React from 'react'
import { Helmet } from 'react-helmet'

const Header = ({ team, title, season }) => {

	const parts = [ ]

	if (team && team.name) {
		parts.push(team.name)
	} else {
		parts.push('AutoRoster for Netball')
	}

	if (season) {
		parts.push(season.name)
	}

	if (title) {
		parts.push(title)
	}

	const str = parts.join(' | ')

	return (
		<Helmet>
			<title>{str}</title>
			<meta property="og:title" content={str} />
			<meta name="twitter:title" content={str} />
		</Helmet>
	)
}

export default Header
