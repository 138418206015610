import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { fetchDataSet } from './Data'
import Header from './Header'
import MultiPageTitle from './MultiPageTitle'
import LoadingBar from './LoadingBar'
import { FaCloudShowersHeavy } from 'react-icons/fa'
import { BENCHED, RAIN, numPeriods, fieldPositions } from './definitions'
import { formatDate, gameDateClass, generateSearchString } from './util'
import orange from './orange.svg'
import "./player.css"

const ROSTER_NOT_AVAILABLE = 'na'

const Player = ({ seasonid, section, team }) => {

	const [ loading, setLoading ] = useState(true)
	const [ data, setData ] = useState({ })

	useEffect(() => {

		const requests = [ 'games', 'rosters' ].map(key => (
			{
				name: key,
				section,
			})
		)

		requests.push({
			name: 'player',
			id: section.player,
			section,
		})

		const unsubscribe = fetchDataSet(requests, (items) => {
			setData(items)
			setLoading(false)
		})

		return unsubscribe
	}, [ section ])

	let content = null
	let title = 'Player'

	if (Object.keys(data).length) {
		const { player, games, rosters } = data
		title = player.name

		const sortedGameIds = sortGames(games)

		content = (
			<div>
				<div className="player-games">
					{
						sortedGameIds.map((gameId, index) =>
							<PlayerRound
								key={index}
								game={games[gameId]}
								gameId={gameId}
								roster={rosters[gameId] || { }}
								playerId={section.player}
								section={section}
							/>
						)
					}
				</div>
			</div>
		)
	}

	const altTitles = [
		{
			title: 'Players',
			pathname: '/players',
		},
		{
			title: 'Games',
			pathname: '/games',
		},
	]

	return (
		<section className="player">
			<Header team={team} title={title} />
			<MultiPageTitle
				title={title}
				section={section}
				altTitles={altTitles}
			/>
			<LoadingBar
				loading={loading}
			/>
			{content}
		</section>
	)
}

const PlayerRound = ({ gameId, game, roster, playerId, section }) => {

	const myPositions = findMyPositions(playerId, roster.roster, roster.nulled, roster.players)

	const isFutureGame = game.date && game.date > Date.now()

	return (
		<Link
			className={'gameline' + (isFutureGame ? ' gameline-future' : '')}
			to={{
				pathname: "/roster",
				search: generateSearchString({
					section,
					type: {
						unit: 'game',
					},
					item: {
						id: gameId,
					},
				})
			}}
		>
			<div>
				{game.round}
			</div>
			{
				myPositions.map((position, qtr) =>
					<div
						key={qtr}
						className={`cell cell-position cell-position-${position}`}
					>
						{position === RAIN ? <FaCloudShowersHeavy /> : position}
					</div>
				)
			}
			<div>
				{
					roster.orangeman === playerId ? <img className="orange-image" src={orange} alt="Oranges" /> : ''
				}

			</div>

			<div className="player-game">
				<div className={'player-game-date date-' + gameDateClass(game.date)}>
					{
						formatDate(game.date)
					}
				</div>
				<div className="player-game-opponent">
					{game.opponent}
				</div>
			</div>
		</Link>
	)
}

//
// Return an array of positions for this player per qtr,
// e.g. [ 'GS', 'GA', '-', 'WA' ]
//
function findMyPositions(playerId, roster, rosterNulled, available) {

	if (!roster || !roster.length)
		return new Array(numPeriods).fill(ROSTER_NOT_AVAILABLE)

	const nulled = rosterNulled || [ ]

	const numPositions = roster.length / numPeriods

	const arr = new Array(numPeriods).fill(BENCHED)

	if (!available || available.includes(playerId)) {
		for (let qtr = 0; qtr < numPeriods; qtr += 1) {
			const offset = qtr * numPositions
			for (let i = 0; i < numPositions; i += 1) {
				if (roster[offset + i] === playerId) {
					if (nulled.indexOf(offset + i) !== -1) {
						arr[qtr] = RAIN
					} else if (i < fieldPositions.length) {
						arr[qtr] = fieldPositions[i]
					}
					continue
				}
			}
		}
	}

	return arr
}

//
// Given an Object of games, return an Array of game IDs from
// oldest to most recent.
//
const sortGames = games =>
	Object.keys(games).sort((a, b) => {
		if (games[a].date !== undefined && games[b].date !== undefined) {
			return games[a].date - games[b].date
		}
		return games[a].round - games[b].round
	})

export default Player
