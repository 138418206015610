import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import LoadingBar from './LoadingBar'
import { FaRandom, FaRecycle, FaCalendarAlt, FaUserFriends, FaFlag, FaListAlt, FaEdit, FaChartPie } from 'react-icons/fa'
import { FiSave } from 'react-icons/fi'
import { TiTick } from 'react-icons/ti'
import { BiStats } from 'react-icons/bi'
import orange from './orange.svg'
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import "./help.css"

const TESTING = false

const Help = props => {

	if (TESTING) {
		return <TestingHelp />
	}

	const { location } = props
	const { state } = location

	const page = (state && state.page) || (location.hash && location.hash.substr(1))

	const pages = {
		'managers': {
			text: 'Coaches & Team Managers',
			order: 1,
			content: <HelpForManagers />,
		},
		'players': {
			text: 'Players & Parents',
			order: 2,
			content: <HelpForPlayers />,
		},
		'trials': {
			text: 'Managing Trials',
			order: 3,
			content: <HelpForTrials />,
		},
		'privacy': {
			text: 'Privacy Policy',
			order: 4,
			content: <HelpPrivacy />,
		},
		'terms': {
			text: 'Terms of Service',
			order: 5,
			content: <HelpTerms />,
		},
	}

	let content

	if (page && pages[page]) {
		content = (
			<div>
				<p>
					<Link
						to={{
							state: {
								page: null,
							},
							hash: '#',
						}}
					>
						&larr; Back
					</Link>
				</p>
				{pages[page].content}
			</div>
		)
	} else {

		content = (
			<>
				<div className="list-items">
					{
						Object.keys(pages).sort((a, b) => a.order - b.order).map(key =>
							<div
								key={key}
								className="list-item"
							>
								<Link
									to={{
										state: {
											page: key,
										},
										hash: `#${key}`,
									}}
									className="item"
								>
									{pages[key].text}
								</Link>
							</div>
						)
					}
				</div>

				<TestingHelp />

			</>
		)
	}

	const title = pages[page] ? pages[page].text : 'Help'

	return (
		<section className="help">
			<Header title={title} />
			<h2>
				{title}
			</h2>
			<LoadingBar />
			{content}
		</section>
	)

}

export default Help

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	panel: {
		display: 'block',
	},
}))

const HelpForPlayers = () => {
	const classes = useStyles()
	const [ expanded, setExpanded ] = useState(false)

	const handleChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
	}

	return (
		<div className={classes.root}>

			<ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
				>
					<Typography className={classes.heading}>View games</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panel}>
					<p>
						Once you've selected a Team and Season, you can view a list of all games
						by using the main menu to navigate to
						{' '}
						<span className="help-navitem">
							<FaCalendarAlt />
							Games
						</span>.
					</p>
					<p>
						Clicking any particular game will display its roster.
					</p>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel2bh-content"
					id="panel2bh-header"
				>
					<Typography className={classes.heading}>View a season summary</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panel}>
						<p>
							View a list of all your rostered positions this season by using the main menu to
							navigate to
							{' '}
							<span className="help-navitem">
								<FaUserFriends />
								Players
							</span>,
							then click your name.
						</p>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</div>
	)
}

const HelpForManagers = () => {
	const classes = useStyles()
	const [ expanded, setExpanded ] = useState(false)

	const handleChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
	}

	return (
		<div className={classes.root}>

			<HelpVideo src="Fskr4mhsk2c" />

			<ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
				<ExpansionPanelSummary

					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
				>
					<Typography className={classes.heading}>
						Create a team
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panel}>

					<HelpVideo src="MYbRJ-ujHsA" />

					<h3>
						Step 1: Sign in
					</h3>
					<p>
						Anyone can create a team, but first you must sign in. This ensures that only you
						can make changes to the team in the future.
					</p>
					<p>
						From the main menu, click
						{' '}
						<span className="help-navitem">
							Sign In
						</span>
						{' '}
						and select your preferred method of identification.
					</p>
					<h3>
						Step 2: Create a team
					</h3>
					<p>
						From the main menu, click
						{' '}
						<span className="help-navitem">
							<FaFlag />
							Teams
						</span>. If you're signed in, there will be a button near the bottom right of the page
						that looks like this:
						{' '}
						<span className="help-navitem">
							+ Add
						</span>.
					</p>
					<ol>
						<li>
							<p>
								Click + Add.
							</p>
						</li>
						<li>
							<p>
								Enter your team name. You can change this later.
							</p>
						</li>
						<li>
							<p>
								Click Add New Team.
							</p>
						</li>
					</ol>
					<h3>
						Step 3: Add a season
					</h3>
					<p>
						From the main menu, click
						{' '}
						<span className="help-navitem">
							<FaListAlt />
							Seasons
						</span>.
					</p>
					<ol>
						<li>
							<p>
								Click + Add.
							</p>
						</li>
						<li>
							<p>
								Enter your season's name. You can change this later.
							</p>
						</li>
						<li>
							<p>
								Click Add New Season.
							</p>
						</li>
					</ol>
					<h3>
						Step 4: Add players
					</h3>
					<p>
						From the main menu, click
						{' '}
						<span className="help-navitem">
							<FaUserFriends />
							Players
						</span>.
					</p>
					<ol>
						<li>
							<p>
								Click + Add.
							</p>
						</li>
						<li>
							<p>
								Enter a player's name. You can change this later.
							</p>
						</li>
						<li>
							<p>
								Click Add New Player.
							</p>
						</li>
						<li>
							<p>
								Continue adding players as needed.
							</p>
						</li>
					</ol>
					<h3>
						Step 5: Add games
					</h3>
					<p>
						From the main menu, click
						{' '}
						<span className="help-navitem">
							<FaCalendarAlt />
							Games
						</span>.
					</p>
					<ol>
						<li>
							<p>
								Click + Add.
							</p>
						</li>
						<li>
							<p>
								Enter game details. You can change these later.
							</p>
						</li>
						<li>
							<p>
								Click Add New Game.
							</p>
						</li>
						<li>
							<p>
								Continue adding games as needed.
							</p>
						</li>
					</ol>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel3bh-content"
					id="panel3bh-header"
				>
					<Typography className={classes.heading}>
						Generate a roster
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panel}>

					<HelpVideo src="oT4TFPiNILQ" />

					<p>
						Click any of your
						{' '}
						<span className="help-navitem">
							<FaCalendarAlt />
							Games
						</span>
						{' '}
						to view its current roster.
					</p>
					<p>
						If you're signed in and have permission to manage this team,
						you'll see an
						{' '}
						<span className="help-navitem">
							<FaEdit />
							Edit
						</span>
						{' '}
						button near the bottom right of the page. Click to enter Edit Mode.
					</p>
					<p>
						In Edit Mode, you can:
					</p>
					<ol>
						<li>
							<p>
								Toggle players' availability by clicking the
								{' '}
								<span className="help-navitem">
									<TiTick
										style={{color:'#65b965'}}
									/>
								</span>
								{' '}
								beside their names.
							</p>
						</li>
						<li>
							<p>
								Nominate a captain / orange provider by
								dragging a player onto the
								<span className="help-navitem">
									<img
										className="orange-image"
										src={orange}
										alt="Oranges"
									/>
									Oranges
								</span>
								{' '}
								box.
							</p>
						</li>
						<li>
							<p>
								Click
								{' '}
								<span className="help-navitem">
									<FaRandom />
									AutoRoster
								</span>
								{' '}
								to automatically generate a roster.
							</p>
						</li>
						<li>
							<p>
								Drag players and positions around as required.
							</p>
						</li>
						<li>
							<p>
								Click
								{' '}
								<span className="help-navitem">
									<FiSave />
									Save
								</span>.
							</p>
						</li>
					</ol>
					<p>
						By default, AutoRoster improves the current	roster. To start over, use
						{' '}
						<span className="help-navitem">
							<FaRecycle />
							Scramble
						</span>
						{' '}
						to randomly mix up positions first, then try AutoRoster again.
					</p>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel4bh-content"
					id="panel4bh-header"
				>
					<Typography className={classes.heading}>
						Use <BiStats /> NetStats to optimize team performance
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panel}>

					<p>
						If you enter quarter-by-quarter scores after games, AutoRoster will detect
						correlations between players' positions and team performance.
					</p>

					<p>
						It will then rate the strength of rosters you create, and can optimize
						for maximum team performance when generating new rosters.
					</p>

					<h2>How It Works</h2>

					<p>
						Let's say the Labradors lose a game like this:
					</p>

					<table className="help-scores-table">
						<thead>
							<tr>
								<th />
								<th>Labradors</th>
								<th>Opponent</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Q1</td>
								<td>4</td>
								<td>6</td>
							</tr>
							<tr>
								<td>Q2</td>
								<td>8</td>
								<td>10</td>
							</tr>
							<tr>
								<td>Q3</td>
								<td>12</td>
								<td>19</td>
							</tr>
							<tr>
								<td>Final</td>
								<td>16</td>
								<td>24</td>
							</tr>
						</tbody>
					</table>

					<p>
						If we calculate the goal difference for each quarter, we can see that this game
						was mostly lost in the third quarter, while the second quarter was the Labradors' best.
					</p>

					<table className="help-scores-table">
						<thead>
							<tr>
								<th />
								<th>Labradors</th>
								<th>Opponent</th>
								<th>Score in this qtr</th>
								<th>Goal difference</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Q1</td>
								<td>4</td>
								<td>6</td>
								<td>4 - 6</td>
								<td>-2</td>
							</tr>
							<tr>
								<td>Q2</td>
								<td>8</td>
								<td>10</td>
								<td>4 - 4</td>
								<td>0</td>
							</tr>
							<tr>
								<td>Q3</td>
								<td>13</td>
								<td>19</td>
								<td>5 - 9</td>
								<td>-4</td>
							</tr>
							<tr>
								<td>Final</td>
								<td>16</td>
								<td>24</td>
								<td>3 - 5</td>
								<td>-2</td>
							</tr>
						</tbody>
					</table>

					<p>
						If you enter quarter-by-quarter scores in your team's
						{' '}
						<span className="help-navitem">
							<FaCalendarAlt />
							Games
						</span>
						{' '}
						page using the
						{' '}
						<span className="help-navitem">
							<FaEdit />
							Edit
						</span>
						{' '}
						button, AutoRoster will track which players are in which position and how this correlates
						with team performance.
					</p>

					<h2>
						Benefits
					</h2>

					<p>
						Once you've entered some game scores, you can view NetStats on the
						{' '}
						<span className="help-navitem">
							<FaChartPie />
							Stats
						</span>
						{' '}
						page. They are also displayed on roster pages.
					</p>

					<p>
						NetStats are only visible to your team's logged-in coaches/managers, not to players or the general public.
					</p>

					<p>
						Higher scores are better. A GD with +0.50 means the team typically overperforms
						by half a goal per quarter when this player is in that position.
					</p>

					<p>
						Scores aren't affected by whether the team wins or loses more often. They're also
						scaled down for players who haven't yet played many quarters in that position.
					</p>

					<p>
						You can view each player's total contribution to team performance for the season with the
						<b><BiStats /> Total season output</b> toggle on the Stats page.
					</p>

					<h2>
						Limitations
					</h2>

					<p>
						NetStats are no substitute for a coach's keen eye. They work best as a prompt,
						encouraging coaches to think about why a player in a particular position might
						be contributing to higher or lower team performance.
					</p>

					<p>
						NetStats are less reliable when the algorithm lacks data to build solid
						comparisons, such as for players who tend to stick to one position all game.
						This can particularly be the case for a player's "benched" NetStat, which may
						be based on only one or two quarters even after several games. The more games
						and positional variety the algorithm can observe, the more accurate it will
						tend to be, as it averages out external effects and variations.
					</p>

					<p>
						To hide NetStats on roster pages, toggle <b>Show NetStats</b> in the Settings panel.
					</p>

				</ExpansionPanelDetails>
			</ExpansionPanel>
		</div>
	)
}

const HelpForTrials = () => {
	const classes = useStyles()

	return (
		<div className={classes.root}>

			<p>
				There are two main tabs: <b>Players</b> and <b>Games</b>.
				You begin by entering or importing Players and setting their
				must-play positions, then switch to Games to generate schedules.
			</p>

			<ol>
				<li>
					<h4>
						Enter Players
					</h4>
					<p>
						Enter players manually, or else import them from a spreadsheet
						or list.
					</p>
				</li>

				<li>
					<h4>
						Set Positions (optional)
					</h4>
					<p>
						If you want particular players to play certain positions, select those
						on the Players screen.
					</p>
				</li>

				<li>
					<h4>
						Set Courts & Minimum Games
					</h4>
					<p>
						Click the <b>Games</b> tab to switch into the Games screen.
					</p>
					<p>
						You can go ahead and click <b>Go</b> to create a schedule,
						or tweak a few options first:
					</p>
					<ul>
						<li>
							<p>
								<b>Number of Courts</b> is how many courts you have
								available for simultaneous games. When you have multiple
								courts, AutoRoster will try to minimize the number of
								rounds required, while also giving players a break between
								games.
							</p>
						</li>
						<li>
							<p>
								<b>Minimum Number of Games</b> is when you haven't nominated
								positions for everyone but you still want them to play a certain
								number of games. In this case, they will be assigned random
								positions.
							</p>
						</li>
						<li>
							<p>
								<b>No Empty Slots</b>: Usually you won't have the exact
								right number of players to make up full games without a few
								people playing extras. With this option enabled, AutoRoster will
								assign players to fill those spots.
							</p>
						</li>
						<li>
							<p>
								<b>No Duplicate Positions</b>: When players are randomly
								assigned positions (to fill empty spots, or to meet your minimum
								number of games), they might be given the same position twice,
								in order to make an efficient roster. But you might prefer
								that they always play somewhere different.
							</p>
						</li>
					</ul>
				</li>

				<li>
					<h4>Go!</h4>
					<p>
						Click <b>Go</b> and enjoy your roster.
					</p>
				</li>

				<li>
					<h4>
						Export
					</h4>

					<p>
						If you like, use the <b>Export</b> button to download your roster in spreadsheet format.
					</p>
				</li>

			</ol>
		</div>
	)
}


const HelpVideo = props => {
	const { src } = props

	return (
		<div className="video-yt">
			<iframe
				src={`https://www.youtube.com/embed/${src}`}
				title="AutoRoster video"
				frameBorder="0"
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			>
			</iframe>
		</div>
	)
}

const TestingHelp = () => {

	return (
		<div className="help-testing">
			<h2>
				About AutoRoster
			</h2>
			<p>
				Hello! I'm Max. I have two daughters playing netball and
				wound up coaching a kids' team. To make rostering a bit easier,
				I built this site.
			</p>
			<p>
				When I <a
					href="https://twitter.com/SquiggleAFL/status/1230278255137058816"
					className="linkish"
					target="_blank"
					rel="noopener noreferrer"
				>
					posted it on Twitter
				</a>,
				I found many people in the same boat, so I'm making it
				available for free.
			</p>
			<p>
				You can use this site to make quick one-off rosters, or to manage a team
				throughout a season, like in the video below. It can also generate multi-game
				rosters for trials.
			</p>
			<p>
				Please enjoy and let me know how you go via <a href="https://twitter.com/SquiggleAFL">Twitter</a> or <a href="https://maxbarry.com/max/mailme.html">email</a>.
			</p>
			<p>
				&mdash; <a href="https://maxbarry.com" className="linkish">Max Barry</a>
			</p>

			<HelpVideo src="Fskr4mhsk2c" />
		</div>
	)

}

const HelpPrivacy = () => {
	return (
		<div className="help-privacy">
			<p>
				<em>
				Short version: This is a free website with no ads that exists just because I thought it would be nifty. It doesn't
				do anything you wouldn't expect. However, in
				order to make it work, it runs services by Google (hosting, database, analytics), Facebook (login), and LogRocket
				(session tracking), who collect the usual amount of stuff on all visitors.
				</em>
			</p>
			<p>
				This following document sets forth the Privacy Policy for The Website. The Website is bound by the Privacy Act 1988 (Cth), which sets out a number of principles concerning the privacy of individuals.
			</p>

			<h3>
				Definitions
			</h3>

			<p>
				<b>Services</b> means roster generation and similar functionality designed to aid in the management of netball games.
				<br />
				<b>The Website</b> means the website AutoRoster.
				<br />
				<b>We / Us</b> etc means Max Barry and any subsidiaries, affiliates, employees, officers, agents or assigns.
			</p>

			<h3>
				Collection of your personal information
			</h3>
			<p>
				There are many aspects of the Website which can be viewed without providing personal information, however, for access to future AutoRoster customer support features you are required to submit personally identifiable information. This may include but not limited to a unique username and password, or provide sensitive information in the recovery of your lost password.
			</p>

			<h3>
				Use of your personal information
			</h3>
			<p>
			For each visitor to reach the site, we expressively collect the following non-personally identifiable information, including but not limited to browser type, version and language, operating system, pages viewed while browsing the Site, page access times and referring website address. This collected information is used solely internally for the purpose of gauging visitor traffic, trends and delivering personalized content to you while you are at this Site.
			</p>
			<p>
			From time to time, we may use customer information for new, unanticipated uses not previously disclosed in our privacy notice. If our information practices change at some time in the future we will use for these new purposes only, data collected from the time of the policy change forward will adhere to our updated practices.
			</p>

			<h3>
				Sharing of your personal information
			</h3>
			<p>
				In order to provide basic site functionality, your information may be shared with third parties who provide site services such as analytics, hosting, and debugging services, notably including Google, Facebook and LogRocket. We take reasonable steps to ensure that these organisations are bound by confidentiality and privacy obligations in relation to the protection of your personal information.
			</p>

			<h3>
				Changes to this Privacy Policy
			</h3>
			<p>
				We reserve the right to make amendments to this Privacy Policy at any time. If you have objections to the Privacy Policy, you should not access or use the Site.
			</p>

			<h3>
				Accessing Your Personal Information
			</h3>
			<p>
				You have a right to access your personal information, subject to exceptions allowed by law. If you would like to do so, please let us know. You may be required to put your request in writing for security reasons. We reserve the right to charge a fee for searching for, and providing access to, your information on a per request basis.
			</p>

			<h3>
				CCPA Privacy Rights (Do Not Sell My Personal Information)
			</h3>
			<p>
				Under the CCPA, among other rights, California consumers have the right to:
			</p>
			<ul>
				<li>
					<p>
						Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
					</p>
				</li>
				<li>
					<p>
Request that a business delete any personal data about the consumer that a business has collected.
					</p>
				</li>
				<li>
					<p>

Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
					</p>
				</li>
			</ul>
			<p>
				If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
			</p>

			<h3>
				GDPR Data Protection Rights
			</h3>
			<p>
				We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
			</p>
			<ul>
				<li>
					<p>
						The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
					</p>
				</li>
				<li>
					<p>
						The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
					</p>
				</li>
				<li>
					<p>
						The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
					</p>
				</li>
				<li>
					<p>
						The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
					</p>
				</li>
				<li>
					<p>
						The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
					</p>
				</li>
				<li>
					<p>
						The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
					</p>
				</li>
			</ul>
			<p>
				If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
			</p>

			<h3>
				Children's Information
			</h3>

			<p>
				Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
			</p>
			<p>
AutoRoster does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
			</p>

			<h3>
				Contacting us
			</h3>
			<p>
				If you have any questions about this Privacy Policy and would like further information, please use the following email address: <a href="mailto:max_barry@maxbarry.com">max_barry@maxbarry.com</a>.
			</p>
		</div>
	)
}

const HelpTerms = () => {

	const toPrivacyPolicy = {
		state: {
			page: 'privacy',
		},
		hash: '#',
	}

	return (
		<div className="help-terms">
			<p>
				By using Our Website and the Services and information offered on Our Website, you are agreeing to these terms and conditions.
			</p>

			<h3>
				Definitions
			</h3>

			<p>
				<b>Services</b> means roster generation and similar functionality designed to aid in the management of netball games.
				<br />
				<b>The Website</b> means the website AutoRoster.
				<br />
				<b>We / Us</b> etc means Max Barry and any subsidiaries, affiliates, employees, officers, agents or assigns.
			</p>

			<h3>
				Accuracy of content
			</h3>
			<p>
				We have taken proper care and precautions to ensure that the information we provide on this Website is accurate.  However, we cannot guarantee, nor do we accept any legal liability arising from or connected to, the accuracy, reliability, currency or completeness of anything contained on this Website or on any linked site.
			</p>
			<p>
				The information contained on this Website should not take the place of professional advice.
			</p>

			<h3>
				Use
			</h3>
			<p>
				The Website is made available for your use on your acceptance and compliance with these terms and conditions.  By using this Website, you are agreeing to these terms and conditions.
			</p>
			<p>
				You agree that you will use this website in accordance with all applicable local, state, national and international laws, rules and regulations.
			</p>
			<p>
				You agree that you will not use, nor will you allow or authorise any third party to use, the Website for any purpose that is unlawful, defamatory, harassing, abusive, fraudulent or obscene way or in any other inappropriate way or in a way which conflicts with the Website or the Services.
			</p>
			<p>
				If you contribute to our forum (if any) or make any public comments on this Website which are, in our opinion, unlawful, defamatory, harassing, abusive, fraudulent or obscene or in any other way inappropriate or which conflict with the Website or the Services offered, then we may at our discretion, refuse to publish such comments and/or remove them from the Website.
			</p>
			<p>
				We reserve the right to refuse or terminate service to anyone at any time without notice or reason.
			</p>

			<h3>
				Passwords and logins
			</h3>
			<p>
				You are responsible for maintaining the confidentiality of your passwords and login details and for all activities carried out under your password and login.
			</p>

			<h3>
				Indemnification for loss or damage
			</h3>
			<p>
				You agree to indemnify Us and hold Us harmless from and against any and all liabilities or expenses arising from or in any way related to your use of this Website or the Services or information offered on this Website, including any liability or expense arising from all claims, losses, damages (actual and consequential), suits, judgments, litigation costs and solicitors fees of every kind and nature incurred by you or any third parties through you.
			</p>

			<h3>
				Intellectual property and copyrights
			</h3>
			<p>
				We hold the copyright to the content of this Website, including all uploaded files, layout design, data, graphics, articles, file content, codes, news, tutorials, videos, reviews, forum posts and databases contained on the Website or in connection with the Services. You must not use or replicate our copyright material other than as permitted by law.  Specifically, you must not use or replicate our copyright material for commercial purposes unless expressly agreed to by Us, in which case we may require you to sign a Licence Agreement.
			</p>
			<p>
				If you wish to use content, images or other of our intellectual property, you should submit your request to us at the following email address: <a href="mailto:max_barry@maxbarry.com">max_barry@maxbarry.com</a>.
			</p>

			<h3>
				Links to external websites
			</h3>
			<p>
				This Website may contain links that direct you outside of this Website.  These links are provided for your convenience and are not an express or implied indication that we endorse or approve of the linked Website, it’s contents or any associated website, product or service.  We accept no liability for loss or damage arising out of or in connection to your use of these sites.
			</p>
			<p>
				You may link to our articles or home page.  However, you should not provide a link which suggests any association, approval or endorsement on our part in respect to your website, unless we have expressly agreed in writing.  We may withdraw our consent to you linking to our site at any time by notice to you.
			</p>

			<h3>
				Limitation of Liability
			</h3>
			<p>
				We take no responsibility for the accuracy of any of the content or statements contained on this Website or in relation to our Services.  Statements made are by way of general comment only and you should satisfy yourself as to their accuracy.  Further, all of our Services are provided without a warranty with the exception of any warranties provided by law.  We are not liable for any damages whatsoever, incurred as a result of or relating to the use of the Website or our Services.
			</p>

			<h3>
				Information Collection
			</h3>
			<p>
				Use of information you have provided us with, or that we have collected and retained relating to your use of the Website and/or our Services, is governed by our Privacy Policy.  By using this Website and the Services associated with this Website, you are agreeing to the Privacy Policy. <Link to={toPrivacyPolicy}>View our Privacy Policy</Link> and read more about why we collect personal information from you and how we use that information.
			</p>

			<h3>
				Confidentiality
			</h3>
			<p>
				All personal information you give us will be dealt with in a confidential manner in accordance with our <Link to={toPrivacyPolicy}>Privacy Policy</Link>.  However, due to circumstances outside of our control, we cannot guarantee that all aspects of your use of this Website will be confidential due to the potential ability of third parties to intercept and access such information.
			</p>

			<h3>
				Governing Law
			</h3>
			<p>
				These terms and conditions are governed by and construed in accordance with the laws of Victoria, Australia.  Any disputes concerning this website are to be resolved by the courts having jurisdiction in Victoria, Australia.
			</p>
			<p>
				We retain the right to bring proceedings against you for breach of these Terms and Conditions, in your country of residence or any other appropriate country or jurisdiction.
			</p>
		</div>
	)
}

