import React, { useState, useEffect, useCallback } from 'react'
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './ErrorFallback'
import RouteRoster from './RouteRoster'
import SiteHeader from './SiteHeader'
import LogRocket from 'logrocket'
import Login from './Login'
import Home from './Home'
import Seasons from './Seasons'
import Players from './Players'
import Games from './Games'
import MyTeams from './MyTeams'
import AllTeams from './AllTeams'
import Stats from './Stats'
import Player from './Player'
import Help from './Help'
import Footer from './Footer'
import LoadingBar from './LoadingBar'
import Trials from './Trials'
import firebase from './firebase'
import queryString from 'query-string'
import { fetchData } from './Data'
import { shortcutToSection, shallowCompare } from './util'
import { LOGROCKET_APP_ID } from './logrocketCredentials'
import packageInfo from '../package.json'
import 'focus-outline-manager'
import "typeface-heebo"

//
// Upgrades:
//
// Pinned react-router-dom to v5.2.0 because of this:
// https://bobbyhadz.com/blog/react-export-withrouter-was-not-found-in-react-router-dom#:~:text=The%20error%20%22export%20'withRouter',%2Ddom%405.2.0%20.
//

const isDev = process.env.NODE_ENV === 'development'

if (isDev) {
	const PROFILE = false
	if (PROFILE) {
		const whyDidYouRender = require('@welldone-software/why-did-you-render')
		whyDidYouRender(React, {
			trackAllPureComponents: true,
		})
	}
} else {
	//
	// Production Mode
	//
	// Don't run LogRocket in dev mode because it shims the code
	// and makes it harder to debug.
	//

	//
	// We only get 1,000 free sessions per month of LogRocket, so
	// let's sample of traffic so we don't run out within a
	// few days.
	//
	const SAMPLE	= 0.10

	if (Math.random() < SAMPLE) {

		LogRocket.init(LOGROCKET_APP_ID, {
			release: packageInfo?.version,
		})

	}
}

const RouteHome = props => (
	<Home {...props} />
)

const RouteTeamRoster = props => {
	if (!props.section.game) {
		return <RouteGames {...props} />
	}
	return <RouteRoster {...props} />
}

const RouteSingleRoster = props => (
	<RouteRoster isSingleRoster={true} {...props} />
)

const RouteTrials = props => (
	<Trials {...props} />
)

const RouteGames = props => {
	if (!props.section.season) {
		return <RouteSeasons {...props} />
	}

	//
	// If user arrived here via a shortcut, send them to a roster rather than games list.
	//
	if (props.section.game && props.section.showRoster && props.location.pathname === '/') {
		return <RouteTeamRoster {...props} />
	}

	return (
		<Games
			{...props}
		/>
	)
}

const RouteSeasons = props => {
	if (!props.section.team) {
		return <RouteHome {...props} />
	}

	return (
		<Seasons
			{...props}
		/>
	)
}

const RoutePlayers = props => {
	if (!props.section.season) {
		return <RouteSeasons {...props} />
	}

	return (
		<Players
			{...props}
		/>
	)
}

const RoutePlayer = props => {
	if (!props.section.season) {
		return <RouteSeasons {...props} />
	}

	if (!props.section.player) {
		return <RoutePlayers {...props} />
	}

	return (
		<Player
			{...props}
		/>
	)
}

const RouteStats = props => {
	if (!props.section.season) {
		return <RouteSeasons {...props} />
	}

	return (
		<Stats
			{...props}
		/>
	)
}

const RouteMyTeams = props => (
	<MyTeams
		{...props}
	/>
)

const RouteAllTeams = props => (
	<AllTeams
		{...props}
	/>
)

function AppRouter() {

	const [ team, setTeam ] = useState()
	const [ season, setSeason ] = useState()
	const [ game, setGame ] = useState()

	//
	// When the user visits via a shortcut, e.g. autoroster.io/?SjBVeWPEYy,
	// we take a few moments to fetch their data. In the meantime, we want
	// to show a loading screen, rather than something that gets replaced a
	// second or two later.
	//
	const [ loading, setLoading ] = useState(false)

	const [ section, setSection ] = useState({ })

	const [ user, setUser ] = useState()

	//
 	// LocalStorage will hold only the ID and name of the recently selected
 	// team/season. On App load, we use the ID to look up actual data
 	// for each of those, which we hold in memory -- this has additional
 	// data like permissions, which we don't want to save in localStorage.
 	//
	useEffect(() => {

		//
		// We only want to save 'team' and 'season' in localStorage,
		// not 'game'
		//
		if (section && section.team) {
			localStorage.setItem('section', JSON.stringify({
				team: section.team,
				season: section.season,
			}))
		}

		const userDataSegments = {
			team: setTeam,
			season: setSeason,
			game: setGame,
		}

		const listeners = [ ]

		Object.keys(userDataSegments).forEach(segment => {
			if (section[segment]) {
				const unsubscribe = fetchData({
					name: segment,
					id: section[segment],
					section,
				}, data => {
					userDataSegments[segment](data)
				})
				listeners.push(unsubscribe)
			}
		})

		return () => {
			// console.error('ok I am releasing my listeners now for some reason')
			listeners.forEach(unsubscribeFunction => unsubscribeFunction())
		}

	}, [ section ])

	const props = {
		team,
		season,
		game,
		user,
		section,
	}

	useEffect(() => {
		//
		// Track Firebase auth changes
		//
		if (firebase.auth().onAuthStateChanged) {
			firebase.auth().onAuthStateChanged(user => {

				setUser(user)

				if (user) {
					LogRocket.identify(LOGROCKET_APP_ID, {
						name: user.displayName,
						email: user.email,
					})
				}

			})
		}

	}, [ ])

	return (
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<Router>
				<RouteHooksWithRouter
					section={section}
					setSection={setSection}
					setLoading={setLoading}
				/>
				<SiteHeaderWithRouter
					{...props}
				/>
				{ loading && <LoadingBar loading={90} /> }
				<main className={loading ? 'loading' : ''}>
					<Switch>
						<Route
							path="/"
							exact
							render={routeProps => (
								<RouteGames {...routeProps} {...props} />
							)}
						/>
						<Route
							path="/home"
							render={routeProps => (
								<RouteHome {...routeProps} {...props} />
							)}
						/>
						<Route
							path="/single-roster"
							render={routeProps => (
								<RouteSingleRoster {...routeProps} {...props} />
							)}
						/>
						<Route
							path="/trials"
							render={routeProps => (
								<RouteTrials {...routeProps} {...props} />
							)}
						/>
						<Route
							path="/roster"
							render={routeProps => (
								<RouteTeamRoster {...routeProps} {...props} />
							)}
						/>
						<Route
							path="/seasons"
							render={routeProps => (
								<RouteSeasons {...routeProps} {...props} />
							)}
						/>
						<Route
							path="/games"
							render={routeProps => (
								<RouteGames {...routeProps} {...props} />
							)}
						/>
						<Route
							path="/players"
							render={routeProps => (
								<RoutePlayers {...routeProps} {...props} />
							)}
						/>
						<Route
							path="/player"
							render={routeProps => (
								<RoutePlayer {...routeProps} {...props} />
							)}
						/>
						<Route
							path="/teams"
							render={routeProps => (
								<RouteMyTeams {...routeProps} {...props} />
							)}
						/>
						<Route
							path="/stats"
							render={routeProps => (
								<RouteStats {...routeProps} {...props} />
							)}
						/>
						<Route
							path="/help"
							component={Help}
						/>

						<Route
							path="/all-teams"
							render={routeProps => (
								<RouteAllTeams {...routeProps} {...props} />
							)}
						/>

						<Route
							path="/login"
							render={() => (
								<Login
									user={user}
								/>
							)}
						/>
						<Route
							path="/logout"
							render={() => (
								<Login
									user={user}
									signout={true}
								/>
							)}
						/>
						<Route
							render={routeProps => (
								<RouteGames {...routeProps} {...props} />
							)}
						/>
					</Switch>
				</main>
				<Footer />
			</Router>
		</ErrorBoundary>
	)
}

const RouteHooks = props => {
	const { section, setSection, setLoading, location } = props

	//
	// I need to be careful not to update 'section' unless the new
	// value is actually different, because it causes a cascade of
	// work further down the app.
	//
	const updateSection = useCallback(newValue => {
		if (newValue) {
			if (!shallowCompare(section, newValue)) {
				// console.log("Section value is changing", newValue)
				setSection(newValue)
			}
		}
	}, [ section, setSection ])

	useEffect(() => {

		if (location.search) {
			const parsed = queryString.parse(location.search);

			const keys = Object.keys(parsed)

			//
			// Have we been sent a shortcut?
			//
			// e.g. https://autoroster.io/?xFpWaw3b
			//
			if (location.pathname === '/' && keys.length === 1 && parsed[keys[0]] === null) {
				setLoading(true)
				shortcutToSection(keys[0], newSection => {
					// console.log("Invoking setSection from shortcut")
					updateSection(newSection)
					setLoading(false)
				})
			} else {
				// console.log("Invoking setSection from URL", parsed)
				updateSection(parsed)
			}
		} else {
			const localStorageValue = localStorage.getItem('section')
			if (localStorageValue) {
				// console.log("From local Storage")
				updateSection(JSON.parse(localStorageValue))
			}
		}

	}, [ location, updateSection, setLoading ])

	//
	// When the URL ends in #error, report any JS errors to the screen
	//
	useEffect(() => {
		function reportError(event) {
			if (event && event.message) {
				window.alert("Error! " + event.message)
			}
		}

		if (location.hash && location.hash === '#error') {
			window.addEventListener('error', reportError, true);
			window.alert("Listening for errors!")
		}
	}, [ location.hash ])

	return null
}

const RouteHooksWithRouter = withRouter(RouteHooks)
const SiteHeaderWithRouter = withRouter(SiteHeader)

export default AppRouter
