import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'

const ProgressBar = withStyles({
    root: {
        backgroundColor: '#b6e1b6',
    },
    barColorPrimary: {
        backgroundColor: '#65b965',
    },
})(LinearProgress)

export default ProgressBar
